import * as React from "react";
import { IconProps } from "./types";

export const ArrowNarrowDownRightIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M6 6L18 18M18 18V10M18 18H10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ArrowNarrowDownRightIcon as React.FunctionComponent).displayName =
  "ArrowNarrowDownRightIcon";

export default ArrowNarrowDownRightIcon;
