import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
} from "@/kit/ui/form";
import { Switch } from "@/kit/ui/switch";

import { Schema } from "./schema/submission";

const Submission = () => {
  const form = useFormContext<Schema>();
  return (
    <div className="space-y-6">
      <FormField
        control={form.control}
        name="requiresApproval"
        render={({ field }) => (
          <FormItem checkbox>
            <FormControl>
              <Switch {...field} />
            </FormControl>
            <div>
              <FormLabel>
                <FormattedMessage defaultMessage="Requires Approval" />
              </FormLabel>
              <FormDescription>
                <FormattedMessage defaultMessage="Requires someone to approve the submission before it is seen on the leaderboard" />
              </FormDescription>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="grantHostSubmissionAccess"
        render={({ field }) => (
          <FormItem checkbox>
            <FormControl>
              <Switch {...field} />
            </FormControl>
            <div>
              <FormLabel>
                <FormattedMessage defaultMessage="Grant host submission access" />
              </FormLabel>
              <FormDescription>
                <FormattedMessage defaultMessage="Allow the host to access submissions" />
              </FormDescription>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="hasLeaderboard"
        render={({ field }) => (
          <FormItem checkbox>
            <FormControl>
              <Switch {...field} />
            </FormControl>
            <div>
              <FormLabel>
                <FormattedMessage defaultMessage="Has leaderboard" />
              </FormLabel>
              <FormDescription>
                <FormattedMessage defaultMessage="Display the leaderboard and scores to users" />
              </FormDescription>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="noCode"
        render={({ field }) => (
          <FormItem checkbox>
            <FormControl>
              <Switch {...field} />
            </FormControl>
            <div>
              <FormLabel>
                <FormattedMessage defaultMessage="No code" />
              </FormLabel>
              <FormDescription>
                <FormattedMessage defaultMessage="Disable code submissions and accept documents as submissions" />
              </FormDescription>
              <FormMessage />
            </div>
          </FormItem>
        )}
      />
    </div>
  );
};

export { Submission };
