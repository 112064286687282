import { graphql } from "relay-runtime";
import { EntitiesAvatarFragment$key } from "./__generated__/EntitiesAvatarFragment.graphql";
import { useFragment } from "react-relay";
import EntityProfilePic from "./EntityProfilePic";
import { Link } from "react-router-dom";
import { FormattedMessage } from "react-intl";

const EntitiesAvatarFragment = graphql`
  fragment EntitiesAvatarFragment on VotersConnection {
    edges {
      node {
        username
        ...EntityProfilePicFragment @arguments(thumbnail: true)
      }
    }
  }
`;

const DEFAULT_ENTITY_COUNT = 10;

interface Props {
  entityCount: number;
  entities: EntitiesAvatarFragment$key;
}

export function EntitiesAvatar({
  entityCount,
  entities: entitiesFragment,
}: Props) {
  const { edges } = useFragment(EntitiesAvatarFragment, entitiesFragment);

  const hidenEntities = entityCount - edges.length;

  return (
    <ul className="flex items-center flex-row text-base" role="listbox">
      {edges.map(({ node }, index) => (
        <Link to={`/${node.username}`}>
          <li
            role="listitem"
            key={index}
            className="-mr-2 h-8 w-8 md:h-10 md:w-10 rounded-full border-2 border-white bg-gradient-to-br from-gray-300 to-gray-100"
          >
            <EntityProfilePic entity={node} />
          </li>
        </Link>
      ))}
      {hidenEntities > DEFAULT_ENTITY_COUNT && (
        <li
          role="listitem"
          className="-mr-2 h-8 w-8 md:h-10 md:w-10 flex items-center justify-center rounded-full border-2 border-white bg-gray-100"
        >
          <p className="text-xs leading-4 font-medium text-black">
            <FormattedMessage
              defaultMessage="+{count}"
              values={{ count: hidenEntities }}
            />
          </p>
        </li>
      )}
    </ul>
  );
}
