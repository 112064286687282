import * as React from "react";
import * as SelectPrimitive from "@radix-ui/react-select";
import { CheckIcon, ChevronDownIcon, ChevronUpIcon } from "@/icons";
import { cn } from "@/utils/tailwind";

type SelectContextValue = {
  triggerRef?: React.MutableRefObject<HTMLButtonElement | null>;
  hasError?: boolean;
};

const SelectContext = React.createContext<SelectContextValue>({});

interface SelectProps
  extends Omit<SelectPrimitive.SelectProps, "onValueChange"> {
  onChange?: (value: string) => void;
  onBlur?: () => void;
  "aria-invalid"?: boolean;
}

const Select = React.forwardRef<HTMLInputElement, SelectProps>(
  (
    {
      onChange,
      onBlur,
      onOpenChange: defaultOnOpenChange,
      "aria-invalid": hasError,
      ...props
    },
    forwardedRef,
  ) => {
    const triggerRef = React.useRef<HTMLButtonElement>(null);
    React.useImperativeHandle(
      forwardedRef,
      () =>
        ({
          focus() {
            triggerRef?.current?.focus();
          },
        }) as HTMLInputElement,
    );
    const onOpenChange = (open: boolean) => {
      if (defaultOnOpenChange) {
        defaultOnOpenChange(open);
      }
      if (!open && onBlur) {
        onBlur();
      }
    };
    return (
      <SelectContext.Provider value={{ triggerRef, hasError }}>
        <SelectPrimitive.Root
          onValueChange={onChange}
          onOpenChange={onOpenChange}
          {...props}
        />
      </SelectContext.Provider>
    );
  },
);

const SelectGroup = SelectPrimitive.Group;

const SelectValue = SelectPrimitive.Value;

const SelectTrigger = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Trigger>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Trigger>
>(({ className, children, ...props }, forwardedRef) => {
  const { triggerRef, hasError } = React.useContext(SelectContext);
  const ref = React.useRef<HTMLButtonElement>(
    null,
  ) as React.MutableRefObject<HTMLButtonElement | null>;
  React.useImperativeHandle(
    forwardedRef,
    () => ref.current as HTMLButtonElement,
  );
  return (
    <SelectPrimitive.Trigger
      ref={(elem) => {
        ref.current = elem;
        if (triggerRef) {
          triggerRef.current = elem;
        }
      }}
      className={cn(
        "flex h-9 w-full items-center justify-between whitespace-nowrap rounded-lg border border-grey-300 px-3.5 py-2 data-[placeholder]:text-grey-500 focus:outline-none focus:ring-2 focus:ring-brand-500 disabled:cursor-not-allowed disabled:opacity-50 [&>span]:line-clamp-1",
        hasError && "border-error-500 focus:ring-1 focus:ring-error-500",
        className,
      )}
      {...props}
    >
      {children}
      <SelectPrimitive.Icon asChild>
        <ChevronDownIcon className="h-4 w-4 opacity-50" />
      </SelectPrimitive.Icon>
    </SelectPrimitive.Trigger>
  );
});
(SelectTrigger as React.FunctionComponent).displayName =
  SelectPrimitive.Trigger.displayName;

const SelectScrollUpButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollUpButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollUpButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollUpButton
    ref={ref}
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronUpIcon className="h-4 w-4" />
  </SelectPrimitive.ScrollUpButton>
));
(SelectScrollUpButton as React.FunctionComponent).displayName =
  SelectPrimitive.ScrollUpButton.displayName;

const SelectScrollDownButton = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.ScrollDownButton>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.ScrollDownButton>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.ScrollDownButton
    ref={ref}
    className={cn(
      "flex cursor-default items-center justify-center py-1",
      className,
    )}
    {...props}
  >
    <ChevronDownIcon className="h-4 w-4" />
  </SelectPrimitive.ScrollDownButton>
));
(SelectScrollDownButton as React.FunctionComponent).displayName =
  SelectPrimitive.ScrollDownButton.displayName;

const SelectContent = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Content>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Content>
>(({ className, children, position = "popper", ...props }, ref) => (
  <SelectPrimitive.Portal>
    <SelectPrimitive.Content
      ref={ref}
      className={cn(
        "relative z-50 max-h-96 min-w-[8rem] overflow-hidden rounded-md border bg-popover text-popover-foreground shadow-md data-[state=open]:animate-in data-[state=closed]:animate-out data-[state=closed]:fade-out-0 data-[state=open]:fade-in-0 data-[state=closed]:zoom-out-95 data-[state=open]:zoom-in-95 data-[side=bottom]:slide-in-from-top-2 data-[side=left]:slide-in-from-right-2 data-[side=right]:slide-in-from-left-2 data-[side=top]:slide-in-from-bottom-2",
        position === "popper" &&
          "data-[side=bottom]:translate-y-1 data-[side=left]:-translate-x-1 data-[side=right]:translate-x-1 data-[side=top]:-translate-y-1",
        className,
      )}
      position={position}
      {...props}
    >
      <SelectScrollUpButton />
      <SelectPrimitive.Viewport
        className={cn(
          "p-1",
          position === "popper" &&
            "h-[var(--radix-select-trigger-height)] w-full min-w-[var(--radix-select-trigger-width)]",
        )}
      >
        {children}
      </SelectPrimitive.Viewport>
      <SelectScrollDownButton />
    </SelectPrimitive.Content>
  </SelectPrimitive.Portal>
));
(SelectContent as React.FunctionComponent).displayName =
  SelectPrimitive.Content.displayName;

const SelectLabel = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Label>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Label>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Label
    ref={ref}
    className={cn("px-3.5 py-2 font-semibold", className)}
    {...props}
  />
));
(SelectLabel as React.FunctionComponent).displayName =
  SelectPrimitive.Label.displayName;

const SelectItem = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Item>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Item>
>(({ className, children, ...props }, ref) => (
  <SelectPrimitive.Item
    ref={ref}
    className={cn(
      "relative flex w-full cursor-default select-none items-center rounded-sm py-2 pl-3.5 pr-8 outline-none focus:bg-accent focus:text-accent-foreground data-[disabled]:bg-grey-50 data-[disabled]:text-grey-400 data-[disabled]:border-grey-200 data-[disabled]:cursor-not-allowed data-[disabled]:pointer-events-none",
      className,
    )}
    {...props}
  >
    <span className="absolute right-2 flex h-3.5 w-3.5 items-center justify-center">
      <SelectPrimitive.ItemIndicator>
        <CheckIcon className="h-4 w-4" />
      </SelectPrimitive.ItemIndicator>
    </span>
    <SelectPrimitive.ItemText>{children}</SelectPrimitive.ItemText>
  </SelectPrimitive.Item>
));
(SelectItem as React.FunctionComponent).displayName =
  SelectPrimitive.Item.displayName;

const SelectSeparator = React.forwardRef<
  React.ElementRef<typeof SelectPrimitive.Separator>,
  React.ComponentPropsWithoutRef<typeof SelectPrimitive.Separator>
>(({ className, ...props }, ref) => (
  <SelectPrimitive.Separator
    ref={ref}
    className={cn("-mx-1 my-1 h-px bg-muted", className)}
    {...props}
  />
));
(SelectSeparator as React.FunctionComponent).displayName =
  SelectPrimitive.Separator.displayName;

export {
  Select,
  SelectGroup,
  SelectValue,
  SelectTrigger,
  SelectContent,
  SelectLabel,
  SelectItem,
  SelectSeparator,
  SelectScrollUpButton,
  SelectScrollDownButton,
};
