import * as React from "react";
import { IconProps } from "./types";

export const Atom02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17.1153 15.3582C16.8446 15.6642 16.5605 15.9665 16.2635 16.2635C11.9677 20.5593 6.58579 22.1422 4.24264 19.7991C2.63624 18.1926 2.87514 15.158 4.56841 12.0242M6.88961 8.72526C7.17132 8.40495 7.46766 8.08875 7.77818 7.77824C12.0739 3.48247 17.4558 1.89956 19.799 4.2427C21.4065 5.85021 21.1662 8.88795 19.4697 12.024M16.2635 7.77824C20.5592 12.074 22.1421 17.4559 19.799 19.7991C17.4558 22.1422 12.0739 20.5593 7.77818 16.2635C3.48241 11.9678 1.8995 6.58585 4.24264 4.2427C6.58579 1.89956 11.9677 3.48247 16.2635 7.77824ZM13 12C13 12.5523 12.5523 13 12 13C11.4477 13 11 12.5523 11 12C11 11.4477 11.4477 11 12 11C12.5523 11 13 11.4477 13 12Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Atom02Icon as React.FunctionComponent).displayName = "Atom02Icon";

export default Atom02Icon;
