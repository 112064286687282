import { unified } from "unified";
import remarkParse from "remark-parse";
import remarkGfm from "remark-gfm";
import { Heading, Text } from "mdast";
import { formatSlug } from "./format";

export interface TOCItem {
  depth: number;
  text: string;
  id: string;
}

export const extractMarkdownTitles = (markdownText: string): TOCItem[] =>
  shiftTitles(
    unified()
      .use(remarkParse)
      .use(remarkGfm)
      .parse(markdownText)
      .children.filter((node): node is Heading => node.type === "heading")
      .map((node) => ({
        depth: node.depth,
        text: node.children
          .filter((child): child is Text => child.type === "text")
          .map((child) => child.value)
          .join(""),
        id: formatSlug(
          node.children
            .filter((child): child is Text => child.type === "text")
            .map((child) => child.value)
            .join(""),
        ),
      })),
  );

function shiftTitles(items: TOCItem[]): TOCItem[] {
  if (items.length <= 0) return items;

  const shift = Math.min(...items.map((item) => item.depth));
  if (shift <= 0) return items;

  for (const item of items) {
    item.depth -= shift;
  }
  return items;
}
