import * as React from "react";
import { Primitive } from "@radix-ui/react-primitive";

import { DESKTOP_MEDIA_QUERY } from "@/common/constants";
import { useMediaQuery } from "@/utils/hooks";
import { cn } from "@/utils/tailwind";

import {
  DropdownMenu,
  DropdownMenuTrigger,
  DropdownMenuContent,
} from "@/kit/ui/dropdown-menu";
import { Drawer, DrawerTrigger, DrawerContent } from "@/kit/ui/drawer";
import { ResponsiveMenuContext } from "@/kit/helpers/responsive-menu";
import { useOnChangeEffect } from "@/utils/hooks";

export interface ResponsiveMenuProps {
  children?: React.ReactNode;
  open?: boolean;
  onOpenChange?: (open: boolean) => void;
}

const ResponsiveMenu = ({
  children,
  open: openDefault = false,
  onOpenChange,
}: ResponsiveMenuProps) => {
  const [open, setOpen] = React.useState(openDefault);
  useOnChangeEffect(openDefault, (openDefault) => {
    setOpen(openDefault);
  });
  React.useEffect(() => {
    onOpenChange?.(open);
  }, [open, onOpenChange]);
  const isDropdown = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const Comp = isDropdown ? DropdownMenu : Drawer;
  return (
    <ResponsiveMenuContext.Provider value={{ isDropdown, setOpen }}>
      <Comp open={open} onOpenChange={setOpen}>
        {children}
      </Comp>
    </ResponsiveMenuContext.Provider>
  );
};
(ResponsiveMenu as React.FunctionComponent).displayName = "ResponsiveMenu";

const ResponsiveMenuTrigger = React.forwardRef<
  HTMLButtonElement,
  React.ComponentPropsWithoutRef<typeof Primitive.button>
>(({ ...props }, ref) => {
  const { isDropdown } = React.useContext(ResponsiveMenuContext);
  const Comp = isDropdown ? DropdownMenuTrigger : DrawerTrigger;
  return <Comp {...props} ref={ref} />;
});
(ResponsiveMenuTrigger as React.FunctionComponent).displayName =
  "ResponsiveMenuTrigger";

const ResponsiveMenuDrawer = React.forwardRef<
  React.ElementRef<typeof DrawerContent>,
  React.ComponentPropsWithoutRef<typeof DrawerContent>
>(({ ...props }, ref) => {
  const { isDropdown } = React.useContext(ResponsiveMenuContext);
  if (isDropdown) {
    return null;
  }
  return <DrawerContent {...props} ref={ref} />;
});
(ResponsiveMenuDrawer as React.FunctionComponent).displayName =
  "ResponsiveMenuDrawer";

const ResponsiveMenuDropdown = React.forwardRef<
  React.ElementRef<typeof DropdownMenuContent>,
  React.ComponentPropsWithoutRef<typeof DropdownMenuContent>
>(({ ...props }, ref) => {
  const { isDropdown } = React.useContext(ResponsiveMenuContext);
  if (!isDropdown) {
    return null;
  }
  return <DropdownMenuContent {...props} ref={ref} />;
});
(ResponsiveMenuDropdown as React.FunctionComponent).displayName =
  "ResponsiveMenuDropdown";

interface ResponsiveMenuContentProps {
  className?: string;
  children?: React.ReactNode;
  matchTriggerWidth?: boolean;
}

const ResponsiveMenuContent = React.forwardRef<
  HTMLDivElement,
  ResponsiveMenuContentProps
>(({ matchTriggerWidth, className, ...props }, ref) => {
  const { isDropdown } = React.useContext(ResponsiveMenuContext);
  if (isDropdown) {
    return (
      <DropdownMenuContent
        matchTriggerWidth={matchTriggerWidth}
        className={cn("space-y-1", className)}
        {...props}
        ref={ref}
      />
    );
  } else {
    return (
      <DrawerContent
        className={cn("space-y-1", className)}
        {...props}
        ref={ref}
      />
    );
  }
});
(ResponsiveMenuContent as React.FunctionComponent).displayName =
  "ResponsiveMenuContent";

const ResponsiveMenuItem = React.forwardRef<
  HTMLButtonElement,
  React.ButtonHTMLAttributes<HTMLButtonElement>
>(({ className, onClick, ...props }, ref) => {
  const { isDropdown, setOpen } = React.useContext(ResponsiveMenuContext);
  const responsiveClassName = isDropdown
    ? "p-2 rounded-sm gap-2"
    : "gap-4 py-3 px-4";
  return (
    <button
      className={cn(
        "flex items-center w-full text-grey-900 hover:bg-grey-100 focus:ring-2 focus:ring-brand-500 [&_svg]:size-5 [&_svg]:shrink-0 [&_svg]:text-grey-500",
        responsiveClassName,
        className,
      )}
      onClick={(e) => {
        if (onClick) {
          onClick(e);
        }
        if (!e.defaultPrevented) {
          setOpen(false);
        }
      }}
      {...props}
      ref={ref}
    />
  );
});
(ResponsiveMenuItem as React.FunctionComponent).displayName =
  "ResponsiveMenuItem";

export {
  ResponsiveMenu,
  ResponsiveMenuTrigger,
  ResponsiveMenuDropdown,
  ResponsiveMenuDrawer,
  ResponsiveMenuContent,
  ResponsiveMenuItem,
};
