import React, {
  ReactElement,
  type HTMLAttributeAnchorTarget,
  isValidElement,
  cloneElement,
} from "react";
import { NavLink } from "react-router-dom";
import { cn } from "../utils/tailwind";

export type Variant = "primary" | "secondary" | "link";

interface Props {
  children?: React.ReactNode;
  to: string;
  vertical?: boolean;
  exact?: boolean;
  target?: HTMLAttributeAnchorTarget;
  icon?: ReactElement<{ className?: string }>;
  variant?: Variant;
}

export default function NavItem({
  children,
  to,
  target,
  vertical = false,
  exact = false,
  variant = "primary",
  icon,
}: Props) {
  const isExternal = target === "_blank";
  return (
    <NavLink
      to={to}
      end={exact}
      target={target}
      rel={isExternal ? "noopener noreferrer" : undefined}
      className={({ isActive }) =>
        cn([
          "relative flex items-center gap-1 cursor-pointer px-3 py-2 whitespace-nowrap rounded-lg",
          variant === "primary" && "hover:bg-black/5",
          variant === "secondary" && "hover:bg-black/5",
          variant === "link" &&
            "hover:underline text-gray-700 font-semibold leading-tight",
          vertical && "font-semibold mx-2",
          (!isActive || variant !== "primary" || vertical) &&
            "[&>.active-indicator]:hidden",
          isActive &&
            variant === "secondary" &&
            !vertical &&
            "text-indigo bg-black/5",
          isActive && vertical && "bg-black/5",
        ])
      }
    >
      {children}
      {isValidElement(icon) &&
        cloneElement(icon, {
          className: cn(icon.props.className, "text-indigo w-3 h-auto"),
        })}
      <div className="active-indicator bg-indigo h-1 w-6 absolute bottom-0 left-[50%] -ml-3 rounded-full"></div>
    </NavLink>
  );
}
