import * as React from "react";
import { IconProps } from "./types";

export const Brush01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.99997 11.2223L12.7778 15M7.97485 20.9749C6.60801 22.3418 4 22 2 22C3.0251 20 1.65827 17.392 3.0251 16.0252C4.39194 14.6583 6.60801 14.6583 7.97485 16.0252C9.34168 17.392 9.34168 19.6081 7.97485 20.9749ZM11.9216 15.9247L21.0587 6.05659C21.8635 5.18743 21.8375 3.83764 20.9999 3.00005C20.1624 2.16246 18.8126 2.13651 17.9434 2.94129L8.07534 12.0784C7.5654 12.5506 7.31043 12.7866 7.16173 13.0384C6.80514 13.6422 6.79079 14.3886 7.12391 15.0056C7.26283 15.2629 7.50853 15.5086 7.99995 16C8.49136 16.4915 8.73707 16.7372 8.99438 16.8761C9.6114 17.2092 10.3578 17.1949 10.9616 16.8383C11.2134 16.6896 11.4494 16.4346 11.9216 15.9247Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Brush01Icon as React.FunctionComponent).displayName = "Brush01Icon";

export default Brush01Icon;
