import * as React from "react";
import { IconProps } from "./types";

export const Repeat04Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 20.5C16.6944 20.5 20.5 16.6944 20.5 12C20.5 9.17447 19.1213 6.67094 17 5.1254M13 22.4L11 20.4L13 18.4M12 3.50001C7.30558 3.50001 3.5 7.30559 3.5 12C3.5 14.8255 4.87867 17.3291 7 18.8746M11 5.60001L13 3.60001L11 1.60001"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Repeat04Icon as React.FunctionComponent).displayName = "Repeat04Icon";

export default Repeat04Icon;
