import * as React from "react";
import { cn } from "@/utils/tailwind";

const AuthHeader = ({ className, ...props }: React.ComponentProps<"div">) => (
  <div className={cn("flex flex-col items-center", className)} {...props} />
);

const AuthHeaderIcon = ({
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div className={cn("w-12 h-12", className)} {...props} />
);

const AuthHeaderIconBadge = ({
  className,
  ...props
}: React.ComponentProps<"div">) => (
  <div
    className={cn(
      "[&_svg]:size-7 w-12 h-12 text-grey-700 flex items-center justify-center border border-grey-200 shadow-sm rounded-lg",
      className,
    )}
    {...props}
  />
);

const AuthHeaderTitle = ({
  className,
  ...props
}: React.ComponentProps<"h1">) => (
  <h1
    className={cn(
      "text-center text-grey-900 text-dsm font-semibold mt-4",
      className,
    )}
    {...props}
  />
);

const AuthHeaderDescription = ({
  className,
  ...props
}: React.ComponentProps<"h2">) => (
  <h2
    className={cn("text-center text-grey-600 text-sm mt-2", className)}
    {...props}
  />
);

const AuthContent = ({ className, ...props }: React.ComponentProps<"div">) => (
  <div className={cn("flex flex-col mt-8", className)} {...props} />
);

export {
  AuthHeader,
  AuthHeaderIcon,
  AuthHeaderIconBadge,
  AuthHeaderTitle,
  AuthHeaderDescription,
  AuthContent,
};
