import * as React from "react";
import { IconProps } from "./types";

export const Shield01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.302 21.6149C11.5234 21.744 11.6341 21.8086 11.7903 21.8421C11.9116 21.8681 12.0884 21.8681 12.2097 21.8421C12.3659 21.8086 12.4766 21.744 12.698 21.6149C14.646 20.4784 20 16.9084 20 12V7.21759C20 6.41808 20 6.01833 19.8692 5.6747C19.7537 5.37113 19.566 5.10027 19.3223 4.88552C19.0465 4.64243 18.6722 4.50207 17.9236 4.22134L12.5618 2.21067C12.3539 2.13271 12.25 2.09373 12.143 2.07827C12.0482 2.06457 11.9518 2.06457 11.857 2.07827C11.75 2.09373 11.6461 2.13271 11.4382 2.21067L6.0764 4.22134C5.3278 4.50207 4.9535 4.64243 4.67766 4.88552C4.43398 5.10027 4.24627 5.37113 4.13076 5.6747C4 6.01833 4 6.41808 4 7.21759V12C4 16.9084 9.35396 20.4784 11.302 21.6149Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Shield01Icon as React.FunctionComponent).displayName = "Shield01Icon";

export default Shield01Icon;
