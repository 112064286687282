/**
 * @generated SignedSource<<215fefb58e2dbf54b10d76ba0245b3c3>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type BlogArticleEditFormAuthorQuery$variables = {
  id: string;
};
export type BlogArticleEditFormAuthorQuery$data = {
  readonly node: {
    readonly id?: string;
    readonly " $fragmentSpreads": FragmentRefs<"EntityAvatarFragment">;
  };
};
export type BlogArticleEditFormAuthorQuery = {
  response: BlogArticleEditFormAuthorQuery$data;
  variables: BlogArticleEditFormAuthorQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "BlogArticleEditFormAuthorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EntityAvatarFragment"
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "BlogArticleEditFormAuthorQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "username",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "displayName",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "imageThumbnail",
                "storageKey": null
              }
            ],
            "type": "Entity",
            "abstractKey": "__isEntity"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "a0b8ef9182024c5c3c6b2b6ec64336fb",
    "id": null,
    "metadata": {},
    "name": "BlogArticleEditFormAuthorQuery",
    "operationKind": "query",
    "text": "query BlogArticleEditFormAuthorQuery(\n  $id: ID!\n) {\n  node(id: $id) {\n    __typename\n    ... on Entity {\n      __isEntity: __typename\n      id\n      ...EntityAvatarFragment\n    }\n    id\n  }\n}\n\nfragment EntityAvatarFragment on Entity {\n  __isEntity: __typename\n  username\n  ...EntityProfilePicFragment_49kWkb\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n"
  }
};
})();

(node as any).hash = "43748ac4aede9451d1c8345fe8ccdd8d";

export default node;
