import { useIntl } from "react-intl";
import { useBlogArticlePageQuery } from "./loaders/BlogArticlePage";
import ErrorPage from "./ErrorPage";
import BlogArticle from "../components/BlogArticle";

export default function BlogArticlePage() {
  const intl = useIntl();
  const {
    query: { blogArticleBySlug, viewer },
    variables: { order },
  } = useBlogArticlePageQuery();

  if (!blogArticleBySlug) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found",
        })}
      />
    );
  }

  return (
    <BlogArticle
      article={blogArticleBySlug}
      viewer={viewer}
      initialCommentOrder={order}
    />
  );
}
