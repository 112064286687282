import * as React from "react";
import { IconProps } from "./types";

export const BellRinging04Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M15.646 18.0149C16.0748 19.6153 15.125 21.2603 13.5246 21.6891C11.9242 22.118 10.2792 21.1682 9.8504 19.5678M2.05688 11.6134C1.66738 10.2152 2.07242 8.70361 3.10883 7.6875M12.1464 5.74097C12.4506 5.19367 12.5508 4.53217 12.3759 3.87957C12.0186 2.54591 10.6477 1.75445 9.31404 2.11181C7.98038 2.46916 7.18892 3.84 7.54628 5.17367C7.72114 5.82627 8.13867 6.34904 8.67577 6.67091M20.3191 6.72012C19.9573 5.31451 18.8507 4.20792 17.4451 3.84614M17.4907 9.4454C17.1337 8.11327 16.1808 6.99931 14.8415 6.34858C13.5023 5.69784 11.8863 5.56365 10.3493 5.9755C8.81218 6.38736 7.47986 7.31154 6.64538 8.54473C5.8109 9.77792 5.54262 11.2191 5.89956 12.5512C6.49014 14.7553 6.37546 16.5137 6.00251 17.8498C5.57744 19.3727 5.36491 20.1341 5.42233 20.287C5.48802 20.4619 5.53554 20.5099 5.70977 20.5774C5.86206 20.6364 6.50183 20.465 7.78137 20.1222L19.6464 16.9429C20.926 16.6001 21.5658 16.4286 21.6681 16.3014C21.7853 16.1558 21.8024 16.0905 21.7718 15.9061C21.7451 15.745 21.1804 15.1919 20.0508 14.0856C19.0597 13.1149 18.0812 11.6495 17.4907 9.4454Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(BellRinging04Icon as React.FunctionComponent).displayName =
  "BellRinging04Icon";

export default BellRinging04Icon;
