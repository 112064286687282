import * as React from "react";

export type ResponsiveMenuContextValue = {
  isDropdown: boolean;
  setOpen: (open: boolean) => void;
};

const ResponsiveMenuContext = React.createContext<ResponsiveMenuContextValue>({
  isDropdown: false,
  setOpen: () => {},
});

export { ResponsiveMenuContext };
