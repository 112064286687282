import { useEffect, useState } from "react";
import { Schema, visibilityOptions } from "./schema/general";
import {
  FieldValues,
  useFormContext,
  UseFormReturn,
  FieldPath,
} from "react-hook-form";
import { FormattedMessage } from "react-intl";
import {
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  FormDescription,
  OptionalLabel,
} from "@/kit/ui/form";
import {
  Select,
  SelectContent,
  SelectItem,
  SelectTrigger,
  SelectValue,
} from "@/kit/ui/select";
import { FileInput, FileInputButton } from "@/kit/ui/file-input";
import { Textarea } from "@/kit/ui/textarea";
import { Input, InputDecorator } from "@/kit/ui/input";
import { useLocation } from "@/utils/location";
import { useImagePreview } from "@/utils/hooks";
import { cn } from "@/utils/tailwind";
import defaultThumbnail from "@/assets/background_16_9_white.svg";

const useAutoSlug = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>(
  { watch, setValue, getFieldState }: UseFormReturn<TFieldValues>,
  {
    src: srcName,
    dst: dstName,
    enabled = true,
  }: {
    src: TName;
    dst: TName;
    enabled?: boolean;
  },
) => {
  const src = watch(srcName);
  const [active, setActive] = useState(enabled);
  useEffect(() => {
    if (!active) return;
    if (getFieldState(dstName).isDirty) {
      setActive(false);
      return;
    }
    const slug = src
      .toLowerCase()
      .replace(/[^a-z0-9-]+/g, "-")
      .replace(/^-+/, "")
      .replace(/-+$/, "")
      .substring(0, 35);
    setValue(dstName, slug, { shouldDirty: false });
  }, [active, setActive, src, setValue, dstName, getFieldState]);
};

const ImagePreview = ({
  image,
  className,
}: {
  image?: File;
  className?: string;
}) => {
  const dataURL = useImagePreview(image);

  return dataURL ? (
    <img className={cn("rounded-lg object-cover", className)} src={dataURL} />
  ) : (
    <div
      className={cn("rounded-lg bg-old_grey", className)}
      style={{
        backgroundImage: `url(${defaultThumbnail})`,
        backgroundSize: "1000px",
      }}
    />
  );
};

interface Props {
  routePrefix: string;
  autoSlug?: boolean;
}

const General = ({ routePrefix, autoSlug = false }: Props) => {
  const location = useLocation();
  const form = useFormContext<Schema>();
  useAutoSlug(form, { src: "title", dst: "slug", enabled: autoSlug });
  return (
    <div className="space-y-6">
      <FormField
        control={form.control}
        name="title"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage defaultMessage="Title" />
            </FormLabel>
            <FormControl>
              <Input {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="shortDescription"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage defaultMessage="Description" />
            </FormLabel>
            <FormControl>
              <Textarea autoResize {...field} />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="slug"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage defaultMessage="URL" />
            </FormLabel>
            <FormControl>
              <Input
                startDecorator={
                  <InputDecorator
                    separated
                    children={`${location.host}/${routePrefix}/`}
                  />
                }
                {...field}
              />
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="visibility"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage defaultMessage="Visibility" />
            </FormLabel>
            <FormControl>
              <Select {...field}>
                <SelectTrigger>
                  <SelectValue placeholder="Visibility" />
                </SelectTrigger>
                <SelectContent>
                  {visibilityOptions.map(({ value, message }) => (
                    <SelectItem value={value} key={value}>
                      <FormattedMessage {...message} />
                    </SelectItem>
                  ))}
                </SelectContent>
              </Select>
            </FormControl>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="banner"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage defaultMessage="Banner" />
              <OptionalLabel />
            </FormLabel>
            <ImagePreview
              className={`w-full aspect-[19/4]`}
              image={field.value}
            />
            <FormControl>
              <FileInput accept="image/png,image/jpeg" {...field}>
                <FileInputButton />
              </FileInput>
            </FormControl>
            <FormDescription>
              <FormattedMessage defaultMessage=".png, .jpg file up to 20MB" />
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
      <FormField
        control={form.control}
        name="thumbnail"
        render={({ field }) => (
          <FormItem>
            <FormLabel>
              <FormattedMessage defaultMessage="Thumbnail" />
              <OptionalLabel />
            </FormLabel>
            <ImagePreview
              className={`w-64 aspect-square`}
              image={field.value}
            />
            <FormControl>
              <FileInput accept="image/png,image/jpeg" {...field}>
                <FileInputButton />
              </FileInput>
            </FormControl>
            <FormDescription>
              <FormattedMessage defaultMessage=".png, .jpg file up to 20MB" />
            </FormDescription>
            <FormMessage />
          </FormItem>
        )}
      />
    </div>
  );
};

export { General };
