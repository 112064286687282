import { useFormContext } from "react-hook-form";
import { FormattedMessage } from "react-intl";
import { CompetitionTagInput } from "@/components/CompetitionTagInput";
import { CompetitionTagInputFragment$key } from "@/components/__generated__/CompetitionTagInputFragment.graphql";
import { CompetitionTagInputForCompetitionEditPageFragment$key } from "@/components/__generated__/CompetitionTagInputForCompetitionEditPageFragment.graphql";
import { FormItem, FormLabel } from "@/kit/ui/form";

import { Schema } from "./schema/tags";

interface TagsProps {
  tags: CompetitionTagInputFragment$key;
  competitionTags?: CompetitionTagInputForCompetitionEditPageFragment$key;
}

const Tags = ({ tags, competitionTags }: TagsProps) => {
  const form = useFormContext<Schema>();
  return (
    <FormItem>
      <FormLabel>
        <FormattedMessage defaultMessage="Tags" />
      </FormLabel>
      <CompetitionTagInput
        name="tagIds"
        control={form.control}
        tags={tags}
        defaultTags={competitionTags}
      />
    </FormItem>
  );
};

export { Tags };
