/**
 * @generated SignedSource<<b7b2cb499e7d5736c5c7249f296147a6>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Oauth2Provider = "GITHUB" | "GOOGLE";
export type Oauth2LoginButtonsMutation$variables = {
  provider: Oauth2Provider;
};
export type Oauth2LoginButtonsMutation$data = {
  readonly createOauth2AuthRequest: any;
};
export type Oauth2LoginButtonsMutation = {
  response: Oauth2LoginButtonsMutation$data;
  variables: Oauth2LoginButtonsMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "provider"
  }
],
v1 = [
  {
    "alias": null,
    "args": [
      {
        "kind": "Variable",
        "name": "provider",
        "variableName": "provider"
      }
    ],
    "kind": "ScalarField",
    "name": "createOauth2AuthRequest",
    "storageKey": null
  }
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Oauth2LoginButtonsMutation",
    "selections": (v1/*: any*/),
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Oauth2LoginButtonsMutation",
    "selections": (v1/*: any*/)
  },
  "params": {
    "cacheID": "331e71473adcede35b48c4273b715eed",
    "id": null,
    "metadata": {},
    "name": "Oauth2LoginButtonsMutation",
    "operationKind": "mutation",
    "text": "mutation Oauth2LoginButtonsMutation(\n  $provider: Oauth2Provider!\n) {\n  createOauth2AuthRequest(provider: $provider)\n}\n"
  }
};
})();

(node as any).hash = "829ec31596f4928eb7eaf848de1be135";

export default node;
