import * as React from "react";
import { IconProps } from "./types";

export const ArrowDownLeftIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17 7L7 17M7 17H17M7 17V7"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(ArrowDownLeftIcon as React.FunctionComponent).displayName =
  "ArrowDownLeftIcon";

export default ArrowDownLeftIcon;
