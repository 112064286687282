import * as React from "react";
import { IconProps } from "./types";

export const Cryptocurrency01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17.8779 20.0902C16.1694 21.3315 14.1118 22 12 22C9.88824 22 7.83066 21.3315 6.12218 20.0902M16.3837 3.01206C18.2818 3.93781 19.8381 5.44068 20.8295 7.30528C21.8209 9.16989 22.1966 11.3005 21.9027 13.3917M2.09745 13.3916C1.80355 11.3004 2.17923 9.16979 3.17066 7.30519C4.16208 5.44059 5.71836 3.93771 7.61642 3.01196M17.5 12C17.5 15.0376 15.0376 17.5 12 17.5C8.96247 17.5 6.50004 15.0376 6.50004 12C6.50004 8.96244 8.96247 6.5 12 6.5C15.0376 6.5 17.5 8.96244 17.5 12Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Cryptocurrency01Icon as React.FunctionComponent).displayName =
  "Cryptocurrency01Icon";

export default Cryptocurrency01Icon;
