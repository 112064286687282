import * as React from "react";
import { IconProps } from "./types";

export const ShieldOffIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M8.80868 3.19674L11.4382 2.21067C11.6461 2.13271 11.75 2.09373 11.857 2.07827C11.9518 2.06457 12.0482 2.06457 12.143 2.07827C12.25 2.09373 12.3539 2.13271 12.5618 2.21067L17.9236 4.22134C18.6722 4.50207 19.0465 4.64243 19.3223 4.88552C19.566 5.10027 19.7537 5.37113 19.8692 5.6747C20 6.01833 20 6.41808 20 7.21759V12C20 12.7203 19.8847 13.4118 19.6818 14.071M17.6219 17.6257C15.9362 19.586 13.7879 20.9789 12.6996 21.6139C12.4772 21.7437 12.366 21.8086 12.2098 21.8421C12.0885 21.8681 11.9116 21.8681 11.7904 21.8421C11.6341 21.8086 11.5234 21.744 11.302 21.6149C9.35396 20.4784 4 16.9084 4 12V5.7763C4 5.30935 4.28966 4.89137 4.72688 4.72741M3 2.99999L21 21"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(ShieldOffIcon as React.FunctionComponent).displayName = "ShieldOffIcon";

export default ShieldOffIcon;
