import * as React from "react";
import { IconProps } from "./types";

export const CodeSnippet01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M16 18L22 12L16 6M8 6L2 12L8 18"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(CodeSnippet01Icon as React.FunctionComponent).displayName =
  "CodeSnippet01Icon";

export default CodeSnippet01Icon;
