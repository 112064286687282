import * as React from "react";
import { IconProps } from "./types";

export const BarChart05Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M3 17V21M15 8V21M9 13V21M21 3V21"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(BarChart05Icon as React.FunctionComponent).displayName = "BarChart05Icon";

export default BarChart05Icon;
