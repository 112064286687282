import { graphql, useFragment, useMutation } from "react-relay";
import { toast } from "sonner";
import { FormattedMessage } from "react-intl";

import githubLogo from "../assets/github-logo.svg";
import googleLogo from "../assets/google-logo.svg";

import { Button } from "@/kit/ui/button";
import { Divider } from "@/kit/ui/divider";

import {
  Oauth2LoginButtonsFragment$key,
  Oauth2Provider,
} from "./__generated__/Oauth2LoginButtonsFragment.graphql";
import { Oauth2LoginButtonsMutation as Oauth2LoginButtonsMutationType } from "./__generated__/Oauth2LoginButtonsMutation.graphql";

const Oauth2LoginButtonsFragment = graphql`
  fragment Oauth2LoginButtonsFragment on Query {
    oauth2Providers
  }
`;

const Oauth2LoginButtonsMutation = graphql`
  mutation Oauth2LoginButtonsMutation($provider: Oauth2Provider!) {
    createOauth2AuthRequest(provider: $provider)
  }
`;

interface Oauth2ButtonConfig {
  name: string;
  logoSrc: string;
}

const OAUTH2_BUTTONS: Record<Oauth2Provider, Oauth2ButtonConfig> = {
  GITHUB: {
    name: "GitHub",
    logoSrc: githubLogo,
  },
  GOOGLE: {
    name: "Google",
    logoSrc: googleLogo,
  },
};

function Oauth2Button({ provider }: { provider: Oauth2Provider }) {
  const [commit, isInFlight] = useMutation<Oauth2LoginButtonsMutationType>(
    Oauth2LoginButtonsMutation,
  );
  const { name, logoSrc } = OAUTH2_BUTTONS[provider];
  return (
    <Button
      className="w-full"
      size="lg"
      variant="secondary"
      disabled={isInFlight}
      onClick={() => {
        commit({
          variables: { provider },
          onCompleted: ({ createOauth2AuthRequest }) => {
            window.location.assign(createOauth2AuthRequest);
          },
          onError: (error) => {
            toast.error(error.message);
          },
        });
      }}
    >
      <img src={logoSrc} alt={name} className="w-6" />
      <FormattedMessage
        defaultMessage="Continue with {name}"
        values={{ name }}
      />
    </Button>
  );
}

interface Props {
  query: Oauth2LoginButtonsFragment$key;
}

export default function Oauth2LoginButtons({ query: queryFragment }: Props) {
  const { oauth2Providers } = useFragment(
    Oauth2LoginButtonsFragment,
    queryFragment,
  );
  if (oauth2Providers.length === 0) {
    return null;
  }
  return (
    <>
      <div className="flex flex-col space-y-2">
        {oauth2Providers.map((provider) => (
          <Oauth2Button key={provider} provider={provider} />
        ))}
      </div>
      <Divider>
        <FormattedMessage defaultMessage="or" />
      </Divider>
    </>
  );
}
