/**
 * @generated SignedSource<<25c93995464e3358f23500c111cedcd9>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoteKind = "DOWNVOTE" | "UPVOTE";
export type VoteDisplayPublishMutation$variables = {
  id: string;
  kind: VoteKind;
};
export type VoteDisplayPublishMutation$data = {
  readonly publishVote: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"VoteDisplayFragment">;
    };
  };
};
export type VoteDisplayPublishMutation = {
  response: VoteDisplayPublishMutation$data;
  variables: VoteDisplayPublishMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "kind"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "kind",
    "variableName": "kind"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "VoteDisplayPublishMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VotableEdge",
        "kind": "LinkedField",
        "name": "publishVote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "VoteDisplayFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "VoteDisplayPublishMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "VotableEdge",
        "kind": "LinkedField",
        "name": "publishVote",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": null,
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v3/*: any*/),
              (v2/*: any*/),
              {
                "kind": "TypeDiscriminator",
                "abstractKey": "__isVotable"
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "votes",
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "voterCount",
                "storageKey": null
              },
              {
                "alias": null,
                "args": [
                  {
                    "kind": "Literal",
                    "name": "first",
                    "value": 10
                  }
                ],
                "concreteType": "VotersConnection",
                "kind": "LinkedField",
                "name": "voters",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "EntityEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": null,
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v3/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "username",
                            "storageKey": null
                          },
                          {
                            "kind": "TypeDiscriminator",
                            "abstractKey": "__isEntity"
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "displayName",
                            "storageKey": null
                          },
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "imageThumbnail",
                            "storageKey": null
                          },
                          (v2/*: any*/)
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": "voters(first:10)"
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "EntityVote",
                "kind": "LinkedField",
                "name": "voted",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "score",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": "viewerCanVote",
                "args": [
                  {
                    "kind": "Literal",
                    "name": "action",
                    "value": "PUBLISH_VOTE"
                  }
                ],
                "kind": "ScalarField",
                "name": "viewerCan",
                "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "67cfd8ddb7305a5a1f198a73fbab14fd",
    "id": null,
    "metadata": {},
    "name": "VoteDisplayPublishMutation",
    "operationKind": "mutation",
    "text": "mutation VoteDisplayPublishMutation(\n  $id: ID!\n  $kind: VoteKind!\n) {\n  publishVote(id: $id, kind: $kind) {\n    node {\n      __typename\n      id\n      ...VoteDisplayFragment\n    }\n  }\n}\n\nfragment EntitiesAvatarFragment on VotersConnection {\n  edges {\n    node {\n      __typename\n      username\n      ...EntityProfilePicFragment_49kWkb\n      id\n    }\n  }\n}\n\nfragment EntityProfilePicFragment_49kWkb on Entity {\n  __isEntity: __typename\n  displayName\n  imageThumbnail\n}\n\nfragment VoteDisplayFragment on Votable {\n  __isVotable: __typename\n  id\n  votes\n  voterCount\n  voters(first: 10) {\n    ...EntitiesAvatarFragment\n  }\n  voted @ifAllowed {\n    score\n  }\n  viewerCanVote: viewerCan(action: PUBLISH_VOTE)\n}\n"
  }
};
})();

(node as any).hash = "88c55b127936ce8afd0acf9190dfac43";

export default node;
