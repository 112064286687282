import CreatableSelect from "react-select/creatable";
import { ConnectionHandler, graphql } from "relay-runtime";
import { useFragment, useMutation, usePaginationFragment } from "react-relay";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import { useEffect, useState } from "react";
import {
  CompetitionTagInputMutation as CompetitionTagInputMutationType,
  CompetitionTagInputMutation$data,
} from "./__generated__/CompetitionTagInputMutation.graphql";
import {
  CompetitionTagInputFragment$data,
  CompetitionTagInputFragment$key,
} from "./__generated__/CompetitionTagInputFragment.graphql";
import { CompetitionTagInputForCompetitionEditPageFragment$key } from "./__generated__/CompetitionTagInputForCompetitionEditPageFragment.graphql";
import { useAuth } from "../utils/auth";
import { useIntl } from "react-intl";
import { ErrorMessage } from "./FormGroup";
import { logger } from "../common/logger";

const CompetitionTagInputMutation = graphql`
  mutation CompetitionTagInputMutation(
    $input: CreateTagInput!
    $entityId: ID!
    $connections: [ID!]!
  ) {
    createTag(input: $input, entityId: $entityId)
      @appendEdge(connections: $connections) {
      node {
        id
        name
      }
    }
  }
`;

const CompetitionTagInputFragment = graphql`
  fragment CompetitionTagInputFragment on Query
  @refetchable(queryName: "CompetitionTagInputFragmentPaginationQuery")
  @argumentDefinitions(
    cursor: { type: "String" }
    count: { type: "Int", defaultValue: 10 }
  ) {
    tags(first: $count, after: $cursor)
      @connection(key: "CompetitionTagInputFragment_tags") {
      edges {
        node {
          id
          name
        }
      }
    }
  }
`;

const CompetitionTagInputForCompetitionEditPageFragment = graphql`
  fragment CompetitionTagInputForCompetitionEditPageFragment on CompetitionTagConnection {
    edges {
      node {
        id
        name
      }
    }
  }
`;

export interface Option {
  readonly value: string;
  readonly label: string;
}

type NodeTagType = CompetitionTagInputFragment$data["tags"]["edges"][number];

function transformTagsNodesToOptions(
  input: CompetitionTagInputFragment$data["tags"],
): Option[] {
  return input.edges.map((item: NodeTagType) => ({
    value: item.node.id,
    label: item.node.name,
  }));
}

export interface CompetitionTagInputProps<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {
  tags: CompetitionTagInputFragment$key;
  defaultTags?: CompetitionTagInputForCompetitionEditPageFragment$key;
}

export function CompetitionTagInput<TFieldValues extends FieldValues>({
  name,
  control,
  tags: tagsFragment,
  defaultTags: defaultTagsFragment,
}: Readonly<CompetitionTagInputProps<TFieldValues>>) {
  const { field } = useController({ name, control });
  const [currentTags, setCurrentTags] = useState<Option[]>([]);
  const [error, setError] = useState<string | null>();

  const { userId } = useAuth();
  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionTagInputMutationType>(CompetitionTagInputMutation);
  const intl = useIntl();

  const { data } = usePaginationFragment(
    CompetitionTagInputFragment,
    tagsFragment,
  );
  const defaultTags = useFragment(
    CompetitionTagInputForCompetitionEditPageFragment,
    defaultTagsFragment,
  );

  useEffect(() => {
    setCurrentTags(defaultTags ? transformTagsNodesToOptions(defaultTags) : []);
    field.onChange(
      defaultTags ? defaultTags.edges.map((tag) => tag.node.id) : [],
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultTags]);

  const handleCreateTag = (input: string) => {
    if (!userId) {
      return;
    }
    const cleanedInput = input.replace(/\W/g, " ");
    const words = cleanedInput.split(/\s+/).filter(Boolean);
    if (cleanedInput.replace(/\s/g, "").length > 25) {
      setError(
        intl.formatMessage({
          defaultMessage: "Input must be at most two words and 25 characters.",
        }),
      );
      return;
    }
    commitMutation({
      variables: {
        input: {
          name: words
            .map(
              (word) =>
                word.charAt(0).toUpperCase() + word.slice(1).toLowerCase(),
            )
            .join(" "),
        },
        entityId: userId,
        connections: [
          ConnectionHandler.getConnectionID(
            "root",
            "CompetitionTagInputFragment_tags",
          ),
        ],
      },
      onCompleted: (response: CompetitionTagInputMutation$data, _) => {
        field.onChange([...field.value, response.createTag.node.id]);
        setCurrentTags((prev) => [
          ...prev,
          {
            label: response.createTag.node.name,
            value: response.createTag.node.id,
          },
        ]);
      },
      onError: (error: Error) => {
        logger.error(error);
        setError(
          intl.formatMessage({
            defaultMessage: "Unable to create a tag. Please try again.",
          }),
        );
      },
    });
  };

  return (
    <>
      <CreatableSelect
        options={transformTagsNodesToOptions(data.tags)}
        value={currentTags}
        isLoading={isMutationInFlight}
        isDisabled={isMutationInFlight || field.disabled}
        onCreateOption={handleCreateTag}
        onChange={(newValue, _) => {
          setCurrentTags(newValue.map((item) => item));
          field.onChange(newValue.map((item: Option) => item.value));
        }}
        ref={field.ref}
        onBlur={field.onBlur}
        isMulti
      />
      {error && <ErrorMessage error={error} />}
    </>
  );
}
