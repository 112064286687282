import { useMemo } from "react";
import { useForm } from "react-hook-form";
import { graphql, useMutation } from "react-relay";
import { useLoginPageQuery } from "./loaders/LoginPage";
import { useSearchParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { LoginPageUserMutation as LoginPageUserMutationType } from "./__generated__/LoginPageUserMutation.graphql";

import { useOnAuthCallback } from "@/utils/auth";
import { Button } from "@/kit/ui/button";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/kit/ui/form";
import { Input } from "@/kit/ui/input";
import { PasswordToggleIcon } from "@/kit/ui/password-toggle";
import { User01Icon, Lock01Icon } from "@/icons";
import { Link } from "@/kit/ui/link";

import LogoIcon from "@/components/LogoIcon";
import {
  AuthHeader,
  AuthHeaderTitle,
  AuthHeaderIcon,
  AuthHeaderDescription,
  AuthContent,
} from "@/components/AuthLayout";
import Oauth2LoginButtons from "@/components/Oauth2LoginButtons";

import { relayErrorMessage } from "@/utils/relay";
import { toast } from "@/utils/toast";
import { logger } from "@/common/logger";
import { MetaLayout } from "@/common/MetaLayout";

const LoginPageUserMutation = graphql`
  mutation LoginPageUserMutation($input: LoginUserInput!) {
    loginUser(input: $input) {
      node {
        id
      }
    }
  }
`;

export default function Login() {
  const { query } = useLoginPageQuery();
  const onAuthenticated = useOnAuthCallback();
  const intl = useIntl();
  const [searchParams] = useSearchParams();

  const [commitMutation, isMutationInFlight] =
    useMutation<LoginPageUserMutationType>(LoginPageUserMutation);

  const formSchema = useMemo(
    () =>
      v.object({
        usernameOrEmail: v.pipe(
          v.string(),
          v.nonEmpty(
            intl.formatMessage({
              defaultMessage: "Username or email is required",
            }),
          ),
        ),
        password: v.pipe(
          v.string(),
          v.nonEmpty(
            intl.formatMessage({
              defaultMessage: "Password is required",
            }),
          ),
        ),
      }),
    [intl],
  );
  const form = useForm<v.InferOutput<typeof formSchema>>({
    defaultValues: {
      usernameOrEmail: "",
      password: "",
    },
    resolver: valibotResolver(formSchema),
  });

  const onSubmit = (data: v.InferOutput<typeof formSchema>) => {
    commitMutation({
      variables: {
        input: data,
      },
      onError(error) {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  };

  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage:
          "Sign Up on Aqora.io - Join the Global Quantum Community",
      })}
    >
      <AuthHeader>
        <AuthHeaderIcon>
          <LogoIcon />
        </AuthHeaderIcon>
        <AuthHeaderTitle>
          <FormattedMessage defaultMessage="Welcome back" />
        </AuthHeaderTitle>
        <AuthHeaderDescription>
          <FormattedMessage defaultMessage="Log in to your account" />
        </AuthHeaderDescription>
      </AuthHeader>
      <AuthContent>
        <Form form={form} onSubmit={onSubmit}>
          <div className="space-y-8">
            <div className="space-y-6">
              <Oauth2LoginButtons query={query} />
              <div className="space-y-5">
                <FormField
                  control={form.control}
                  name="usernameOrEmail"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <FormattedMessage defaultMessage="Username or email" />
                      </FormLabel>
                      <FormControl>
                        <Input
                          autoFocus
                          startDecorator={<User01Icon />}
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <FormField
                  control={form.control}
                  name="password"
                  render={({ field }) => (
                    <FormItem>
                      <FormLabel>
                        <FormattedMessage defaultMessage="Password" />
                      </FormLabel>
                      <FormControl>
                        <Input
                          startDecorator={<Lock01Icon />}
                          endDecorator={<PasswordToggleIcon />}
                          type="password"
                          {...field}
                        />
                      </FormControl>
                      <FormMessage />
                    </FormItem>
                  )}
                />
                <p className="text-right">
                  <Link tabIndex={-1} to="/send-password-reset">
                    <FormattedMessage defaultMessage="Forgot Password?" />
                  </Link>
                </p>
              </div>
            </div>
            <Button
              className="w-full"
              type="submit"
              disabled={isMutationInFlight}
            >
              <FormattedMessage defaultMessage="Log in" />
            </Button>
            <p className="text-center">
              <FormattedMessage defaultMessage="New to Aqora?" />{" "}
              <Link
                to={{ pathname: "/signup", search: searchParams.toString() }}
              >
                <FormattedMessage defaultMessage="Sign up" />
              </Link>
            </p>
          </div>
        </Form>
      </AuthContent>
    </MetaLayout>
  );
}
