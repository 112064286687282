import { useEffect, useState } from "react";
import { graphql, useMutation } from "react-relay";
import { FormattedMessage } from "react-intl";
import { OAuth2AuthorizePageMutation as OAuth2AuthorizePageMutationType } from "./__generated__/OAuth2AuthorizePageMutation.graphql";
import { useNavigate, useSearchParams } from "react-router-dom";
import { useLocation } from "@/utils/location";
import { useAuth } from "@/utils/auth";
import { logger } from "@/common/logger";
import Loading from "@/components/Loading";
import { CheckCircleIcon } from "@/icons";
import {
  AuthHeader,
  AuthHeaderIcon,
  AuthHeaderIconBadge,
  AuthHeaderTitle,
  AuthHeaderDescription,
} from "@/components/AuthLayout";
import ErrorPage from "./ErrorPage";

const OAuth2AuthorizePageMutation = graphql`
  mutation OAuth2AuthorizePageMutation($input: Oauth2AuthorizeInput!) {
    oauth2Authorize(input: $input) {
      unauthorized
      clientError
      redirectUri
    }
  }
`;

export default function OAuth2AuthorizePage() {
  const location = useLocation();
  const navigate = useNavigate();
  const [searchParams, _setSearchParams] = useSearchParams();
  const { userId } = useAuth();
  const [commitMutation, _isMutationInFlight] =
    useMutation<OAuth2AuthorizePageMutationType>(OAuth2AuthorizePageMutation);
  const [hasError, setHasError] = useState<boolean>(false);
  const [loading, setLoading] = useState<boolean>(true);
  const [closingMs, setClosingMs] = useState<number>(0);
  useEffect(() => {
    if (userId == null) {
      return navigate({
        pathname: "/login",
        search: searchParams.toString(),
      });
    }
    const clientId = searchParams.get("client_id");
    if (clientId == null) {
      return setHasError(true);
    }
    const state = searchParams.get("state");
    const redirectUri = searchParams.get("redirect_uri");
    commitMutation({
      variables: {
        input: {
          clientId,
          state,
          redirectUri,
        },
      },
      onError: (error) => {
        logger.error(error);
        setHasError(true);
      },
      onCompleted: ({
        oauth2Authorize: { unauthorized, clientError, redirectUri },
      }) => {
        if (unauthorized) {
          return navigate({
            pathname: "/login",
            search: searchParams.toString(),
          });
        }
        if (clientError) {
          return setHasError(true);
        }
        if (redirectUri == null) {
          return setHasError(true);
        }
        let parsedUri: URL;
        try {
          parsedUri = new URL(redirectUri);
        } catch (err) {
          return setHasError(true);
        }
        if (parsedUri.host == "aqora.io") {
          setLoading(false);
          const closingTime = Date.now() + 5000;
          setClosingMs(closingTime - Date.now());
          setInterval(() => {
            const newClosingMs = closingTime - Date.now();
            setClosingMs(newClosingMs);
            if (newClosingMs <= 0) {
              window.close();
            }
          }, 100);
        } else {
          location.replace(redirectUri);
        }
      },
    });
  }, [searchParams, userId, commitMutation, setHasError, navigate, location]);

  if (hasError) {
    return <ErrorPage status={400} />;
  }

  return (
    <div className="container mx-auto min-h-full flex justify-center items-center px-4 py-4">
      <>
        <AuthHeader>
          <AuthHeaderIcon>
            <AuthHeaderIconBadge>
              {loading ? (
                <div className="w-8">
                  <Loading />
                </div>
              ) : (
                <CheckCircleIcon className="text-success-500" />
              )}
            </AuthHeaderIconBadge>
          </AuthHeaderIcon>
          {!loading && (
            <>
              <AuthHeaderTitle>
                <FormattedMessage defaultMessage="Success!" />
              </AuthHeaderTitle>
              <AuthHeaderDescription>
                <p>
                  <FormattedMessage defaultMessage="You can leave this window and go back to your terminal" />
                </p>
                {closingMs > -10000000 && (
                  <p>
                    <FormattedMessage
                      defaultMessage="(closing in {closingSecs} seconds)"
                      values={{ closingSecs: Math.ceil(closingMs / 1000) }}
                    />
                  </p>
                )}
              </AuthHeaderDescription>
            </>
          )}
        </AuthHeader>
      </>
    </div>
  );
}
