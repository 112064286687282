import * as React from "react";

import { cn } from "@/utils/tailwind";

export interface TextareaProps extends React.ComponentProps<"textarea"> {
  autoResize?: boolean;
}

const Textarea = React.forwardRef<HTMLTextAreaElement, TextareaProps>(
  (
    { className, onChange: defaultOnChange, autoResize = false, ...props },
    forwardedRef,
  ) => {
    const ref = React.useRef<HTMLTextAreaElement>(null);
    React.useImperativeHandle(
      forwardedRef,
      () => ref.current as HTMLTextAreaElement,
    );

    const resizeTextArea = React.useCallback(() => {
      if (!ref.current) {
        return;
      }
      ref.current.style.height = "auto";
      ref.current.style.height = ref.current.scrollHeight + "px";
    }, []);

    React.useEffect(() => {
      if (autoResize) {
        resizeTextArea();
        window.addEventListener("resize", resizeTextArea);
        return () => window.removeEventListener("resize", resizeTextArea);
      }
    }, [resizeTextArea, autoResize]);

    const onChange = (e: React.ChangeEvent<HTMLTextAreaElement>) => {
      if (defaultOnChange) {
        defaultOnChange(e);
      }
      if (autoResize) {
        resizeTextArea();
      }
    };

    const isError =
      props["aria-invalid"] === true || props["aria-invalid"] === "true";

    return (
      <textarea
        onChange={onChange}
        className={cn(
          "flex min-h-[60px] w-full rounded-lg border border-grey-300 bg-transparent px-3 py-2 shadow-sm placeholder:text-grey-500 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-brand-500 disabled:cursor-not-allowed disabled:text-grey-400 disabled:bg-grey-50 disbaled:border-grey-200",
          autoResize && "resize-none overflow-hidden pb-3",
          isError && "border-error-500 focus-visible:ring-error-500",
          className,
        )}
        ref={ref}
        {...props}
      />
    );
  },
);
(Textarea as React.FunctionComponent).displayName = "Textarea";

export { Textarea };
