/**
 * @generated SignedSource<<4c4b012540760537a1bf9b4024a2a170>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type SignupPageQuery$variables = Record<PropertyKey, never>;
export type SignupPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Oauth2LoginButtonsFragment">;
};
export type SignupPageQuery = {
  response: SignupPageQuery$data;
  variables: SignupPageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "SignupPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Oauth2LoginButtonsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "SignupPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oauth2Providers",
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "4032afabc97b42aff840f0edc4d91e02",
    "id": null,
    "metadata": {},
    "name": "SignupPageQuery",
    "operationKind": "query",
    "text": "query SignupPageQuery {\n  ...Oauth2LoginButtonsFragment\n}\n\nfragment Oauth2LoginButtonsFragment on Query {\n  oauth2Providers\n}\n"
  }
};

(node as any).hash = "11a9d7031a5f9a6c352dc9a4e21c8994";

export default node;
