import * as React from "react";
import { FormattedMessage } from "react-intl";
import {
  Upload01Icon,
  Trash03Icon,
  ChevronDownIcon,
  ChevronUpIcon,
} from "@/icons";

import { cn } from "@/utils/tailwind";

import { Button, ButtonProps } from "@/kit/ui/button";
import {
  ResponsiveMenu,
  ResponsiveMenuContent,
  ResponsiveMenuTrigger,
  ResponsiveMenuItem,
} from "@/kit/ui/responsive-menu";
import { useOnChangeEffect } from "@/utils/hooks";

type FileInputContextValue = {
  value?: File;
  setValue: (value?: File, interact?: boolean) => void;
  ref: React.RefObject<HTMLInputElement>;
};

const FileInputContext = React.createContext<FileInputContextValue>({
  setValue: () => {},
  ref: { current: null },
});

export interface FileInputProps
  extends Omit<
    React.ComponentProps<"input">,
    "type" | "value" | "onChange" | "onBlur"
  > {
  value?: File;
  onChange?: (file?: File) => void;
  onBlur?: () => void;
  children?: React.ReactNode;
}

const FileInput = React.forwardRef<HTMLInputElement, FileInputProps>(
  (
    { className, onChange, onBlur, value: defaultValue, children, ...props },
    forwardedRef,
  ) => {
    const ref = React.useRef<HTMLInputElement>(null);
    React.useImperativeHandle(
      forwardedRef,
      () => ref.current as HTMLInputElement,
    );
    const [value, setInternalValue] = React.useState(defaultValue);
    useOnChangeEffect(defaultValue, (defaultValue) => {
      setInternalValue(defaultValue);
    });
    React.useEffect(() => {
      const dataTransfer = new DataTransfer();
      if (value) {
        dataTransfer.items.add(value);
      }
      if (ref.current) {
        ref.current.files = dataTransfer.files;
      }
    }, [value]);
    const setValue = React.useCallback(
      (value?: File, interact: boolean = false) => {
        if (interact && onChange) {
          onChange(value);
        }
        setInternalValue(value);
      },
      [setInternalValue, onChange],
    );
    return (
      <FileInputContext.Provider value={{ value, setValue, ref }}>
        <input
          type={"file"}
          className={cn("hidden", className)}
          ref={ref}
          onChange={(event) => {
            const files = event.target.files;
            if (files && files.length > 0) {
              setValue(files[0], true);
            }
            if (onBlur) {
              onBlur();
            }
          }}
          {...props}
        />
        {children}
      </FileInputContext.Provider>
    );
  },
);
(FileInput as React.FunctionComponent).displayName = "FileInput";

const FileInputButton = React.forwardRef<HTMLButtonElement, ButtonProps>(
  ({ className, variant = "secondary", children, onClick, ...props }, ref) => {
    const {
      value,
      setValue,
      ref: inputRef,
    } = React.useContext(FileInputContext);
    const [open, setOpen] = React.useState(false);
    if (value) {
      return (
        <ResponsiveMenu open={open} onOpenChange={setOpen}>
          <ResponsiveMenuTrigger asChild>
            <Button
              variant={variant}
              className={cn("flex", className)}
              onClick={(e) => {
                if (onClick) {
                  onClick(e);
                }
              }}
              {...props}
              ref={ref}
            >
              {children || (
                <>
                  {open ? <ChevronUpIcon /> : <ChevronDownIcon />}
                  <FormattedMessage defaultMessage="Edit" />
                </>
              )}
            </Button>
          </ResponsiveMenuTrigger>
          <ResponsiveMenuContent matchTriggerWidth>
            <ResponsiveMenuItem
              onClick={() => {
                inputRef.current?.click();
              }}
            >
              <Upload01Icon />
              <FormattedMessage defaultMessage="Upload" />
            </ResponsiveMenuItem>
            <ResponsiveMenuItem
              onClick={() => {
                setValue(undefined, true);
              }}
            >
              <Trash03Icon />
              <FormattedMessage defaultMessage="Remove" />
            </ResponsiveMenuItem>
          </ResponsiveMenuContent>
        </ResponsiveMenu>
      );
    } else {
      return (
        <Button
          variant={variant}
          className={cn("flex", className)}
          onClick={(e) => {
            if (onClick) {
              onClick(e);
            }
            if (!e.defaultPrevented && inputRef.current) {
              inputRef.current.click();
              setOpen(false);
            }
          }}
          {...props}
          ref={ref}
        >
          {children || (
            <>
              <Upload01Icon />
              <FormattedMessage defaultMessage="Upload" />
            </>
          )}
        </Button>
      );
    }
  },
);
(FileInputButton as React.FunctionComponent).displayName = "FileInputButton";

export { FileInput, FileInputButton };
