import * as React from "react";
import { IconProps } from "./types";

export const ChevronSelectorVerticalIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7 15L12 20L17 15M7 9L12 4L17 9"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ChevronSelectorVerticalIcon as React.FunctionComponent).displayName =
  "ChevronSelectorVerticalIcon";

export default ChevronSelectorVerticalIcon;
