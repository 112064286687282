import { graphql } from "relay-runtime";
import {
  BlogArticleHelmetFragment$data,
  BlogArticleHelmetFragment$key,
} from "./__generated__/BlogArticleHelmetFragment.graphql";
import { useFragment } from "react-relay";
import TopicHelmet from "./TopicHelmet";
import { defineMessage, useIntl } from "react-intl";
import { JsonObject } from "./SafeScript";
import { LocationContextValue, useLocation } from "../utils/location";

const BlogArticleHelmetFragment = graphql`
  fragment BlogArticleHelmetFragment on BlogArticle {
    title
    shortDescription
    image
    imageAlt
    seoTitle
    seoDescription
    topicHelmet: topic {
      ...TopicHelmetFragment
    }
    authors {
      edges {
        node {
          id
          kind
          username
        }
      }
    }
  }
`;

interface Props {
  article: BlogArticleHelmetFragment$key;
}

export function BlogArticleHelmet({ article: articleFragment }: Props) {
  const intl = useIntl();
  const location = useLocation();
  const blogArticle = useFragment(BlogArticleHelmetFragment, articleFragment);

  return (
    <TopicHelmet
      topic={blogArticle.topicHelmet}
      metaTitle={blogArticle.seoTitle}
      metaDescription={
        blogArticle.seoDescription ?? blogArticle.shortDescription
      }
      metaImageAlt={intl.formatMessage(imageAlt, {
        title:
          blogArticle.imageAlt ?? blogArticle.seoTitle ?? blogArticle.title,
      })}
      metaImageUrl={blogArticle.image}
      extraRichContent={articleAuthors(location, blogArticle)}
    />
  );
}

const imageAlt = defineMessage({
  defaultMessage: "Article image for {title}",
});

function articleAuthors(
  location: LocationContextValue,
  article: BlogArticleHelmetFragment$data,
): JsonObject {
  return {
    author: article.authors.edges.map(({ node: { username, kind } }) => {
      return {
        "@type": kind === "USER" ? "Person" : "Organization",
        name: username,
        url: new URL(`/${username}`, location.origin).toString(),
      };
    }),
  };
}
