import { useState, useMemo, useEffect } from "react";
import { graphql, useMutation } from "react-relay";
import { useForm, UseFormReturn } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import { SendPasswordResetPageMutation as SendPasswordResetPageMutationType } from "./__generated__/SendPasswordResetPageMutation.graphql";
import Helmet from "../components/Helmet";

import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { TimeAgo } from "@/components/TimeAgo";
import LogoIcon from "@/components/LogoIcon";
import {
  AuthHeader,
  AuthHeaderTitle,
  AuthHeaderIcon,
  AuthHeaderDescription,
  AuthHeaderIconBadge,
  AuthContent,
} from "@/components/AuthLayout";

import { relayErrorMessage } from "@/utils/relay";
import { toast } from "@/utils/toast";
import { logger } from "@/common/logger";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/kit/ui/form";
import { Input } from "@/kit/ui/input";
import { Button } from "@/kit/ui/button";
import { Link } from "@/kit/ui/link";
import { Mail01Icon, ArrowLeftIcon } from "@/icons";

const SendPasswordResetPageMutation = graphql`
  mutation SendPasswordResetPageMutation($input: CreatePasswordResetInput!) {
    createPasswordReset(input: $input) {
      canResendAt
    }
  }
`;

const ResendPasswordResetForm = ({
  usernameOrEmail,
  canResendAt,
  onResend,
  isSending,
}: {
  usernameOrEmail: string;
  onResend: () => void;
  canResendAt: Date;
  isSending: boolean;
}) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 500);
    return () => clearInterval(interval);
  });
  const resendTime = new Date(canResendAt);
  const canResend = currentTime >= resendTime;
  return (
    <>
      <AuthHeader>
        <AuthHeaderIcon>
          <AuthHeaderIconBadge>
            <Mail01Icon />
          </AuthHeaderIconBadge>
        </AuthHeaderIcon>
        <AuthHeaderTitle>
          <FormattedMessage defaultMessage="Check your email" />
        </AuthHeaderTitle>
        <AuthHeaderDescription>
          <FormattedMessage
            defaultMessage="If there exists an account, we've sent a password reset link to {usernameOrEmail}"
            values={{ usernameOrEmail: <b>{usernameOrEmail}</b> }}
          />
        </AuthHeaderDescription>
      </AuthHeader>
      <AuthContent>
        <div className="space-y-8">
          {canResend ? (
            <Button
              className="w-full"
              onClick={onResend}
              variant="secondary"
              disabled={isSending}
            >
              <FormattedMessage defaultMessage="Resend Email" />
            </Button>
          ) : (
            <Button
              suppressHydrationWarning
              className="w-full"
              variant="secondary"
              disabled
            >
              <FormattedMessage
                defaultMessage="Can resend email {time}"
                values={{
                  time: <TimeAgo createdAt={resendTime} />,
                }}
              />
            </Button>
          )}
          <Link to="/login" hue="grey" className="w-full" type="submit">
            <ArrowLeftIcon />
            <FormattedMessage defaultMessage="Back to login" />
          </Link>
        </div>
      </AuthContent>
    </>
  );
};

const SendPasswordResetForm = ({
  form,
  onSubmit,
  isSending,
}: {
  form: UseFormReturn<{ usernameOrEmail: string }>;
  onSubmit: (form: { usernameOrEmail: string }) => void;
  isSending: boolean;
}) => {
  return (
    <>
      <AuthHeader>
        <AuthHeaderIcon>
          <LogoIcon />
        </AuthHeaderIcon>
        <AuthHeaderTitle>
          <FormattedMessage defaultMessage="Forgot password?" />
        </AuthHeaderTitle>
        <AuthHeaderDescription>
          <FormattedMessage defaultMessage="No worries, we’ll send you reset instructions." />
        </AuthHeaderDescription>
      </AuthHeader>
      <AuthContent>
        <Form form={form} onSubmit={onSubmit}>
          <div className="space-y-8">
            <FormField
              control={form.control}
              name="usernameOrEmail"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormattedMessage defaultMessage="Email or username" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      autoFocus
                      startDecorator={<Mail01Icon />}
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button className="w-full" type="submit" disabled={isSending}>
              <FormattedMessage defaultMessage="Send password reset" />
            </Button>
            <Link to="/login" hue="grey" className="w-full" type="submit">
              <ArrowLeftIcon />
              <FormattedMessage defaultMessage="Back to login" />
            </Link>
          </div>
        </Form>
      </AuthContent>
    </>
  );
};

export default function SendPasswordResetPage() {
  const intl = useIntl();
  const [canResendAt, setCanResendAt] = useState<Date | null>(null);
  const [commitMutation, isMutationInFlight] =
    useMutation<SendPasswordResetPageMutationType>(
      SendPasswordResetPageMutation,
    );
  const formSchema = useMemo(
    () =>
      v.object({
        usernameOrEmail: v.pipe(
          v.string(),
          v.nonEmpty(
            intl.formatMessage({
              defaultMessage: "Username or email is required",
            }),
          ),
        ),
      }),
    [intl],
  );
  const form = useForm<v.InferOutput<typeof formSchema>>({
    defaultValues: {
      usernameOrEmail: "",
    },
    resolver: valibotResolver(formSchema),
  });
  const onSubmit = (data: v.InferOutput<typeof formSchema>) => {
    commitMutation({
      variables: {
        input: data,
      },
      onError(error) {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted({ createPasswordReset: { canResendAt } }) {
        setCanResendAt(new Date(canResendAt));
      },
    });
  };
  const onResend = () => {
    commitMutation({
      variables: {
        input: form.getValues(),
      },
      onError(error) {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted({ createPasswordReset: { canResendAt } }) {
        setCanResendAt(new Date(canResendAt));
        toast.success(
          intl.formatMessage({ defaultMessage: "Password reset email sent" }),
        );
      },
    });
  };
  return (
    <>
      <Helmet meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      {canResendAt ? (
        <ResendPasswordResetForm
          usernameOrEmail={form.watch("usernameOrEmail")}
          canResendAt={canResendAt}
          onResend={onResend}
          isSending={isMutationInFlight}
        />
      ) : (
        <SendPasswordResetForm
          form={form}
          onSubmit={onSubmit}
          isSending={isMutationInFlight}
        />
      )}
    </>
  );
}
