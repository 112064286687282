import * as React from "react";
import { IconProps } from "./types";

export const Bell04Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M14.3909 18.0149C14.8198 19.6153 13.87 21.2603 12.2696 21.6891C10.6692 22.118 9.02421 21.1682 8.59539 19.5678M10.8915 5.74097C11.1957 5.19367 11.2959 4.53217 11.121 3.87957C10.7637 2.54591 9.39283 1.75445 8.05917 2.11181C6.7255 2.46916 5.93404 3.84 6.2914 5.17367C6.46626 5.82627 6.88379 6.34904 7.4209 6.67091M16.2359 9.44541C15.8789 8.11328 14.926 6.99931 13.5867 6.34858C12.2474 5.69785 10.6315 5.56365 9.09444 5.97551C7.55737 6.38737 6.22504 7.31155 5.39056 8.54473C4.55608 9.77792 4.2878 11.2191 4.64475 12.5512C5.23532 14.7553 5.12064 16.5137 4.74769 17.8498C4.32262 19.3727 4.11009 20.1341 4.16751 20.287C4.2332 20.4619 4.28072 20.5099 4.45495 20.5774C4.60724 20.6364 5.24701 20.465 6.52656 20.1222L18.3916 16.9429C19.6712 16.6001 20.3109 16.4287 20.4133 16.3014C20.5305 16.1558 20.5476 16.0905 20.517 15.9061C20.4903 15.745 19.9255 15.1919 18.796 14.0856C17.8049 13.1149 16.8264 11.6495 16.2359 9.44541Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Bell04Icon as React.FunctionComponent).displayName = "Bell04Icon";

export default Bell04Icon;
