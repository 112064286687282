import { useRef, useCallback } from "react";
import { IntlShape } from "react-intl";
import * as v from "valibot";

import { BANNED_USERNAMES } from "./banned-values";

export const MIN_PASSWORD_LENGTH = 6;
export const MAX_VARCHAR_LENGTH = 255;

export const isNotBannedUsername = (allowed: string[]) => (value: string) =>
  allowed.includes(value) || !BANNED_USERNAMES.includes(value);

export const isUrl = (value: string) => {
  if (!value) return true;
  try {
    new URL(value);
  } catch (e) {
    return false;
  }
  return true;
};

export const maxFileSize = (size: number) => {
  return (files: FileList | undefined) => {
    if (files && files.length > 0) {
      return files[0].size < size;
    }
    return true;
  };
};

export const imgDimensionsWithin = <Input extends File | Blob>({
  minWidth,
  minHeight,
  maxWidth,
  maxHeight,
}: {
  minWidth?: number;
  minHeight?: number;
  maxWidth?: number;
  maxHeight?: number;
}) => {
  return (file: Input): Promise<boolean> => {
    return new Promise((resolve, reject) => {
      const fr = new FileReader();
      fr.onload = () => {
        const img = new Image();
        img.onload = () => {
          if (
            (minWidth && img.width < minWidth) ||
            (minHeight && img.height < minHeight) ||
            (maxWidth && img.width > maxWidth) ||
            (maxHeight && img.height > maxHeight)
          ) {
            resolve(false);
          } else {
            resolve(true);
          }
        };
        img.onerror = (err) => {
          reject(err);
        };
        img.src = fr.result as string;
      };
      fr.onerror = (err) => {
        reject(err);
      };
      fr.readAsDataURL(file);
    });
  };
};

interface FileUploadOptions {
  name?: string;
  maxMb?: number;
  mimeTypes?: `${string}/${string}`[];
}

export const fileUpload = (
  intl: IntlShape,
  { name = "File", maxMb = 20, mimeTypes = [] }: FileUploadOptions = {},
) =>
  v.pipe(
    v.file(),
    v.maxSize(
      1024 * 1024 * maxMb,
      intl.formatMessage(
        {
          defaultMessage: "{name} must be smaller than {maxMb} MB.",
        },
        { name, maxMb },
      ),
    ),
    mimeTypes.length > 0
      ? v.mimeType(
          mimeTypes,
          intl.formatMessage(
            {
              defaultMessage: "{name} must be {mimeTypes}",
            },
            {
              name,
              mimeTypes: intl.formatList(
                mimeTypes.map(
                  (mimeType) =>
                    mimeType.split("/")[1].split(";")[0].split("+")[0],
                ),
                { type: "disjunction" },
              ),
            },
          ),
        )
      : v.check((_) => true),
  );

export const imageUpload = (
  intl: IntlShape,
  {
    name = "Image",
    mimeTypes = ["image/png", "image/jpeg"],
    ...options
  }: FileUploadOptions = {},
) => fileUpload(intl, { name, mimeTypes, ...options });

export interface AsyncCheckCallback<T> {
  (data: T, ctx: { signal: AbortSignal }): boolean | Promise<boolean>; // Callback function signature
}

export interface AsyncCheckOptions {
  debounce?: number;
}

export interface AsyncCheck<T> {
  (data: T): Promise<boolean>;
}

interface AsyncCheckCtx<T> {
  input: T | null;
  output: Promise<boolean> | boolean | null;
  timeout: ReturnType<typeof setTimeout> | null;
  abortContoller: AbortController | null;
}

const abortAsyncCheck = (ctx: AsyncCheckCtx<unknown>) => {
  if (ctx.timeout) {
    clearTimeout(ctx.timeout);
    ctx.timeout = null;
  }
  if (ctx.abortContoller) {
    ctx.abortContoller.abort();
    ctx.abortContoller = null;
  }
};

const runAsyncCheck = async <T>(
  ctx: AsyncCheckCtx<T>,
  callback: AsyncCheckCallback<T>,
  options: AsyncCheckOptions,
  data: T,
): Promise<boolean> => {
  await new Promise<void>((resolve) => {
    ctx.timeout = setTimeout(() => {
      resolve();
    }, options.debounce);
  });
  ctx.abortContoller = new AbortController();
  const signal = ctx.abortContoller.signal;
  const result = callback(data, {
    signal,
  });
  if (signal.aborted) {
    return new Promise(() => {});
  } else {
    return result;
  }
};

export function useAsyncCheck<T>(
  callback: AsyncCheckCallback<T>,
  options: AsyncCheckOptions = {},
): AsyncCheck<T> {
  const ctx = useRef<AsyncCheckCtx<T>>({
    input: null,
    output: null,
    timeout: null,
    abortContoller: null,
  });
  return useCallback(
    async (data: T): Promise<boolean> => {
      if (
        ctx.current.input === data &&
        ctx.current.output !== null &&
        ctx.current.timeout !== null &&
        ctx.current.abortContoller !== null &&
        !ctx.current.abortContoller.signal.aborted
      ) {
        return ctx.current.output;
      }
      abortAsyncCheck(ctx.current);
      ctx.current.input = data;
      ctx.current.output = runAsyncCheck(ctx.current, callback, options, data);
      return ctx.current.output;
    },
    [callback, options],
  );
}
