import * as React from "react";
import { IconProps } from "./types";

export const Modem02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M6.20472 10.5579C5.72578 8.76961 6.08709 6.80315 7.28865 5.28375M17.7952 10.5578C18.2742 8.76957 17.9129 6.80311 16.7113 5.2837M2.34183 11.5996C1.50387 8.4749 2.20543 5.02327 4.44651 2.44589M21.6547 11.5997C22.4926 8.47494 21.7911 5.02331 19.55 2.44594M12 15V9.00001M5.49998 18.5H9.49998M5.19998 22H18.8C19.9201 22 20.4801 22 20.908 21.782C21.2843 21.5903 21.5902 21.2843 21.782 20.908C22 20.4802 22 19.9201 22 18.8V18.2C22 17.0799 22 16.5199 21.782 16.092C21.5902 15.7157 21.2843 15.4097 20.908 15.218C20.4801 15 19.9201 15 18.8 15H5.19998C4.07987 15 3.51982 15 3.092 15.218C2.71567 15.4097 2.40971 15.7157 2.21796 16.092C1.99998 16.5199 1.99998 17.0799 1.99998 18.2V18.8C1.99998 19.9201 1.99998 20.4802 2.21796 20.908C2.40971 21.2843 2.71567 21.5903 3.092 21.782C3.51982 22 4.07987 22 5.19998 22Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Modem02Icon as React.FunctionComponent).displayName = "Modem02Icon";

export default Modem02Icon;
