import React from "react";
import {
  FieldValues,
  UseControllerProps,
  useController,
} from "react-hook-form";
import * as Radix from "@radix-ui/react-switch";

interface Props<TFieldValues extends FieldValues>
  extends UseControllerProps<TFieldValues> {}

function SwitchComponent<TFieldValues extends FieldValues>(
  { name, control, defaultValue }: Props<TFieldValues>,
  ref: React.ForwardedRef<HTMLButtonElement>,
) {
  const { field } = useController<TFieldValues>({
    name,
    control,
    defaultValue,
  });

  return (
    <Radix.Root
      className="w-12 h-7 bg-old_grey rounded-full relative data-[state=checked]:bg-indigo outline-none cursor-default shadow-inner hover:cursor-pointer"
      checked={field.value}
      onCheckedChange={field.onChange}
      onBlur={field.onBlur}
      disabled={field.disabled}
      ref={ref}
    >
      <Radix.Thumb className="block w-7 h-7 shadow-md bg-white rounded-full transition-transform duration-100 will-change-transform data-[state=checked]:translate-x-6" />
    </Radix.Root>
  );
}

const Switch = React.forwardRef(SwitchComponent) as <
  TFieldValues extends FieldValues,
>(
  props: Props<TFieldValues> & React.RefAttributes<HTMLButtonElement>,
) => React.ReactElement | null;

export default Switch;
