import * as React from "react";
import { IconProps } from "./types";

export const Cursor04Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M4.40701 3.41379C3.94876 3.27901 3.71963 3.21162 3.56363 3.26977C3.42768 3.32045 3.32045 3.42768 3.26977 3.56363C3.21162 3.71963 3.27901 3.94876 3.41379 4.40701L7.61969 18.7071C7.74493 19.1329 7.80756 19.3458 7.93395 19.4447C8.04424 19.5309 8.18564 19.567 8.32377 19.5441C8.48206 19.5178 8.639 19.3609 8.95286 19.047L11.9999 15.9999L16.4343 20.4343C16.6323 20.6323 16.7313 20.7313 16.8454 20.7684C16.9459 20.801 17.054 20.801 17.1545 20.7684C17.2686 20.7313 17.3676 20.6323 17.5656 20.4343L20.4343 17.5656C20.6323 17.3676 20.7313 17.2686 20.7684 17.1545C20.801 17.054 20.801 16.9459 20.7684 16.8454C20.7313 16.7313 20.6323 16.6323 20.4343 16.4343L15.9999 11.9999L19.047 8.95286C19.3609 8.63899 19.5178 8.48206 19.5441 8.32377C19.567 8.18564 19.5309 8.04424 19.4447 7.93395C19.3458 7.80756 19.1329 7.74493 18.7071 7.61969L4.40701 3.41379Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Cursor04Icon as React.FunctionComponent).displayName = "Cursor04Icon";

export default Cursor04Icon;
