import { graphql, useMutation } from "react-relay";
import { FormattedMessage, useIntl } from "react-intl";
import ErrorPage from "./ErrorPage";
import { useBlogArticleEditPageQuery } from "./loaders/BlogArticleEditPage";
import BlogArticleEditForm from "../components/BlogArticleEditForm";
import { UploadableMap } from "relay-runtime";
import { logger } from "../common/logger";
import { useNavigate } from "react-router-dom";
import {
  BlogArticleEditPageMutation$variables,
  BlogArticleEditPageMutation,
} from "./__generated__/BlogArticleEditPageMutation.graphql";
import BlogArticleDeleteButton from "../components/BlogArticleDeleteButton";
import { getBlogArticlePath } from "../utils/routing";

const Mutation = graphql`
  mutation BlogArticleEditPageMutation(
    $id: ID!
    $input: UpdateBlogArticleInput!
  ) {
    updateBlogArticle(id: $id, input: $input) {
      node {
        id
        slugLower
        title
        ...BlogArticleFragment
        ...BlogArticleCardFragment
      }
    }
  }
`;

export default function BlogArticleEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const { query, article } = useBlogArticleEditPageQuery();
  const [commitMutation, isMutationInFlight] =
    useMutation<BlogArticleEditPageMutation>(Mutation);

  if (!article) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Article not found /:",
        })}
      />
    );
  }

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage="Edit Article" />
      </h1>

      <BlogArticleEditForm
        userAutoComplete={query}
        isDisabled={isMutationInFlight}
        defaultValues={{
          title: article.title,
          content: article.content,
          shortDescription: article.shortDescription,
          image: article.image,
          authorsIds: article.authors.edges.map((author) => author.node.id),
          seoTitle: article.seoTitle,
          seoDescription: article.seoDescription,
          imageAlt: article.imageAlt,
        }}
        onSubmit={({ data, dirtyFields, image, setFormError }) => {
          const variables: BlogArticleEditPageMutation$variables = {
            id: article.id,
            input: {
              title: dirtyFields.title ? data.title : undefined,
              content: dirtyFields.content ? data.content : undefined,
              shortDescription: dirtyFields.shortDescription
                ? data.shortDescription
                : undefined,
              image: image.variable,
              authorsIds: dirtyFields.authorsIds
                ? data.authorsIds || null
                : undefined,
              seoTitle: dirtyFields.seoTitle
                ? data.seoTitle || null
                : undefined,
              seoDescription: dirtyFields.seoDescription
                ? data.seoDescription || null
                : undefined,
              imageAlt: dirtyFields.imageAlt
                ? data.imageAlt || null
                : undefined,
            },
          };
          const uploadables: UploadableMap = {};
          if (image.uploadable) {
            uploadables["variables.input.image"] = image.uploadable;
          }
          commitMutation({
            variables,
            uploadables,
            onError(error) {
              logger.error(error);
              setFormError(
                intl.formatMessage({
                  defaultMessage: "Could not update article.",
                }),
              );
            },

            onCompleted: ({ updateBlogArticle: { node } }, _) => {
              navigate(getBlogArticlePath(node));
            },
          });
        }}
      />

      <BlogArticleDeleteButton article={article} />
    </div>
  );
}
