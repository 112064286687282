import * as React from "react";
import { IconProps } from "./types";

export const StrikethroughSquareIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M6 12H18M11 12H13C14.3807 12 15.5 13.1193 15.5 14.5C15.5 15.8807 14.3807 17 13 17H10.8333C9.83827 17 8.98868 16.3771 8.65321 15.5M15.3468 8.5C15.0113 7.62288 14.1617 7 13.1667 7H11C9.97484 7 9.0938 7.61705 8.70802 8.5M7.8 21H16.2C17.8802 21 18.7202 21 19.362 20.673C19.9265 20.3854 20.3854 19.9265 20.673 19.362C21 18.7202 21 17.8802 21 16.2V7.8C21 6.11984 21 5.27976 20.673 4.63803C20.3854 4.07354 19.9265 3.6146 19.362 3.32698C18.7202 3 17.8802 3 16.2 3H7.8C6.11984 3 5.27976 3 4.63803 3.32698C4.07354 3.6146 3.6146 4.07354 3.32698 4.63803C3 5.27976 3 6.11984 3 7.8V16.2C3 17.8802 3 18.7202 3.32698 19.362C3.6146 19.9265 4.07354 20.3854 4.63803 20.673C5.27976 21 6.11984 21 7.8 21Z"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(StrikethroughSquareIcon as React.FunctionComponent).displayName =
  "StrikethroughSquareIcon";

export default StrikethroughSquareIcon;
