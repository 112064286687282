import { useEventEditPageQuery } from "./loaders/EventEditPage";
import { EventEditPageMutation as EventEditPageMutationType } from "./__generated__/EventEditPageMutation.graphql";
import { useMemo } from "react";
import { graphql, useMutation } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { isEmpty } from "lodash";
import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";
import ErrorPage from "@/pages/ErrorPage";
import { Link } from "@/kit/ui/link";
import {
  extractUploadables,
  preprocessEdit,
  relayErrorMessage,
} from "@/utils/relay";
import { useIntl, FormattedMessage } from "react-intl";
import {
  GeneralSection,
  generalSchemaFactory,
} from "@/components/ActivityEdit";
import EventDeleteButton from "@/components/EventDeleteButton";
import EventInviteCode from "@/components/EventInviteCode";
import { toast } from "@/utils/toast";
import { logger } from "@/common/logger";
import { Form } from "@/kit/ui/form";
import { Button } from "@/kit/ui/button";
import { useFetchFile } from "@/utils/hooks";

const EventEditPageMutation = graphql`
  mutation EventEditPageMutation($id: ID!, $input: UpdateEventInput!) {
    updateEvent(id: $id, input: $input) {
      node {
        id
        slug
        title
        shortDescription
        banner
        thumbnail
        visibility
        viewerCanUpdateAgenda: viewerCan(action: UPDATE_AGENDA)
      }
    }
  }
`;

export default function EventEditPage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    query: { eventBySlug: event },
  } = useEventEditPageQuery();

  const formSchema = useMemo(() => generalSchemaFactory(intl), [intl]);

  const files = {
    banner: useFetchFile(event?.banner),
    thumbnail: useFetchFile(event?.thumbnail),
  };
  const form = useForm<v.InferOutput<typeof formSchema>>({
    values: event
      ? {
          ...event,
          banner: files.banner.file,
          thumbnail: files.thumbnail.file,
        }
      : undefined,
    resolver: valibotResolver(formSchema),
  });

  const [commitMutation, isMutationInFlight] =
    useMutation<EventEditPageMutationType>(EventEditPageMutation);

  if (!event) {
    return (
      <ErrorPage
        status={404}
        message={intl.formatMessage({
          defaultMessage: "Event not found",
        })}
      />
    );
  }

  const onSubmit = (data: v.InferOutput<typeof formSchema>) => {
    const {
      variables: { input },
      uploadables,
    } = extractUploadables({
      input: preprocessEdit(data, form.formState.defaultValues),
    });
    if (isEmpty(input)) {
      navigate(`/events/${event.slug}`);
    }
    commitMutation({
      variables: { id: event.id, input },
      uploadables,
      onError: (error) => {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted: (results) => {
        navigate(`/events/${results.updateEvent.node.slug}`);
      },
    });
  };

  return (
    <div className="pb-8 space-y-8">
      <Form form={form} onSubmit={onSubmit}>
        <div className="space-y-8">
          <div className="flex justify-between">
            <div>
              <h1 className="text-xl font-semibold">
                <FormattedMessage defaultMessage="Edit Event" />
              </h1>
              <p className="text-sm text-grey-500">
                <FormattedMessage defaultMessage="Manage event options" />
              </p>
            </div>
            <div>
              <Button type="submit" disabled={isMutationInFlight}>
                <FormattedMessage defaultMessage="Save" />
              </Button>
            </div>
          </div>
          <div className="space-y-8">
            {event.viewerCanUpdateAgenda && (
              <Link to={`/events/${event.slug}/agenda/edit`}>
                <FormattedMessage defaultMessage="Edit the agenda" />
              </Link>
            )}
            <EventInviteCode event={event} />
            <GeneralSection routePrefix="Events" />
          </div>
        </div>
      </Form>
      <EventDeleteButton id={event.id} />
    </div>
  );
}
