import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { preload, usePreloaded } from "../../utils/relay";
import { BlogArticlePageQuery as BlogArticlePageQueryType } from "./__generated__/BlogArticlePageQuery.graphql";
import {
  DEFAULT_VOTABLE_ORDER,
  getVotableOrderParam,
} from "../../utils/votableOrder";

export const ArticlePageQuery = graphql`
  query BlogArticlePageQuery($slug: String!, $order: VotableOrder!) {
    viewer @ifAllowed {
      ...TopicCommentsFormViewerFragment
    }
    blogArticleBySlug(slug: $slug) {
      id
      title
      slugLower
      ...BlogArticleFragment @arguments(order: $order)
    }
  }
`;

export const blogArticlePageQueryLoader = (environment: Environment) => {
  return ({ params: { slug }, request }: LoaderArgs) => {
    const order = getVotableOrderParam(request) || DEFAULT_VOTABLE_ORDER;
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<BlogArticlePageQueryType>(environment, ArticlePageQuery, {
      slug,
      order,
    });
  };
};

export const useBlogArticlePageQuery = () =>
  usePreloaded<BlogArticlePageQueryType>();
