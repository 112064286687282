import * as React from "react";
import { IconProps } from "./types";

export const Cryptocurrency02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17.8779 20.0902C16.1694 21.3315 14.1118 22 12 22C9.88824 22 7.83066 21.3315 6.12218 20.0902M16.3837 3.01206C18.2818 3.93781 19.8381 5.44068 20.8295 7.30528C21.8209 9.16989 22.1966 11.3005 21.9027 13.3917M2.09745 13.3916C1.80355 11.3004 2.17923 9.16979 3.17066 7.30519C4.16208 5.44059 5.71836 3.93771 7.61642 3.01196M11.4344 6.56569L6.56572 11.4343C6.36771 11.6323 6.26871 11.7313 6.23161 11.8455C6.19899 11.9459 6.19899 12.0541 6.23161 12.1545C6.26871 12.2687 6.36771 12.3677 6.56572 12.5657L11.4344 17.4343C11.6324 17.6323 11.7314 17.7313 11.8455 17.7684C11.9459 17.8011 12.0541 17.8011 12.1545 17.7684C12.2687 17.7313 12.3677 17.6323 12.5657 17.4343L17.4344 12.5657C17.6324 12.3677 17.7314 12.2687 17.7685 12.1545C17.8011 12.0541 17.8011 11.9459 17.7685 11.8455C17.7314 11.7313 17.6324 11.6323 17.4344 11.4343L12.5657 6.56569C12.3677 6.36768 12.2687 6.26867 12.1545 6.23158C12.0541 6.19895 11.9459 6.19895 11.8455 6.23158C11.7314 6.26867 11.6324 6.36768 11.4344 6.56569Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Cryptocurrency02Icon as React.FunctionComponent).displayName =
  "Cryptocurrency02Icon";

export default Cryptocurrency02Icon;
