/**
 * @generated SignedSource<<8c63271a2d03667ab954eaaa9403887c>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type ActivityVisibility = "AUTHENTICATED" | "MEMBERS" | "UNAUTHENTICATED";
export type UpdateCompetitionInput = {
  banner?: any | null | undefined;
  description?: string | null | undefined;
  grantHostSubmissionAccess?: boolean | null | undefined;
  hasLeaderboard?: boolean | null | undefined;
  noCode?: boolean | null | undefined;
  requiresApproval?: boolean | null | undefined;
  rules?: string | null | undefined;
  shortDescription?: string | null | undefined;
  slug?: string | null | undefined;
  submissionPreamble?: string | null | undefined;
  tagIds?: ReadonlyArray<string | null | undefined> | null | undefined;
  thumbnail?: any | null | undefined;
  title?: string | null | undefined;
  visibility?: ActivityVisibility | null | undefined;
};
export type CompetitionEditPageMutation$variables = {
  id: string;
  input: UpdateCompetitionInput;
};
export type CompetitionEditPageMutation$data = {
  readonly updateCompetition: {
    readonly node: {
      readonly banner: any | null | undefined;
      readonly grantHostSubmissionAccess: boolean;
      readonly hasLeaderboard: boolean;
      readonly id: string;
      readonly noCode: boolean;
      readonly requiresApproval: boolean;
      readonly shortDescription: string;
      readonly slug: string;
      readonly tags: {
        readonly " $fragmentSpreads": FragmentRefs<"CompetitionTagInputForCompetitionEditPageFragment">;
      };
      readonly thumbnail: any | null | undefined;
      readonly title: string;
      readonly visibility: ActivityVisibility;
    };
  };
};
export type CompetitionEditPageMutation = {
  response: CompetitionEditPageMutation$data;
  variables: CompetitionEditPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "input"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  },
  {
    "kind": "Variable",
    "name": "input",
    "variableName": "input"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "slug",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "banner",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "visibility",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "requiresApproval",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "grantHostSubmissionAccess",
  "storageKey": null
},
v11 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "hasLeaderboard",
  "storageKey": null
},
v12 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "noCode",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "CompetitionEditPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompetitionEdge",
        "kind": "LinkedField",
        "name": "updateCompetition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Competition",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionTagConnection",
                "kind": "LinkedField",
                "name": "tags",
                "plural": false,
                "selections": [
                  {
                    "args": null,
                    "kind": "FragmentSpread",
                    "name": "CompetitionTagInputForCompetitionEditPageFragment"
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "CompetitionEditPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": "CompetitionEdge",
        "kind": "LinkedField",
        "name": "updateCompetition",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "Competition",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v2/*: any*/),
              (v3/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/),
              (v9/*: any*/),
              (v10/*: any*/),
              (v11/*: any*/),
              (v12/*: any*/),
              {
                "alias": null,
                "args": null,
                "concreteType": "CompetitionTagConnection",
                "kind": "LinkedField",
                "name": "tags",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": "TagEdge",
                    "kind": "LinkedField",
                    "name": "edges",
                    "plural": true,
                    "selections": [
                      {
                        "alias": null,
                        "args": null,
                        "concreteType": "Tag",
                        "kind": "LinkedField",
                        "name": "node",
                        "plural": false,
                        "selections": [
                          (v2/*: any*/),
                          {
                            "alias": null,
                            "args": null,
                            "kind": "ScalarField",
                            "name": "name",
                            "storageKey": null
                          }
                        ],
                        "storageKey": null
                      }
                    ],
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "597037bfb5267e5eed7f54939d9e6ee2",
    "id": null,
    "metadata": {},
    "name": "CompetitionEditPageMutation",
    "operationKind": "mutation",
    "text": "mutation CompetitionEditPageMutation(\n  $id: ID!\n  $input: UpdateCompetitionInput!\n) {\n  updateCompetition(id: $id, input: $input) {\n    node {\n      id\n      slug\n      title\n      shortDescription\n      banner\n      visibility\n      thumbnail\n      requiresApproval\n      grantHostSubmissionAccess\n      hasLeaderboard\n      noCode\n      tags {\n        ...CompetitionTagInputForCompetitionEditPageFragment\n      }\n    }\n  }\n}\n\nfragment CompetitionTagInputForCompetitionEditPageFragment on CompetitionTagConnection {\n  edges {\n    node {\n      id\n      name\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "b31d3560b1d0a900dc9e3e4fc5b7acac";

export default node;
