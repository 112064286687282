import {
  UseMutationConfig,
  PreloadedQuery,
  loadQuery,
  usePreloadedQuery,
  fetchQuery,
  Environment,
} from "react-relay";
import {
  MutationParameters,
  Disposable,
  OperationType,
  GraphQLTaggedNode,
  Observable,
  UploadableMap,
  Uploadable,
} from "relay-runtime";
import { isPlainObject } from "lodash";
import { DeepIsEqual, deepIsEqual } from "./form";
import { useLoaderData } from "react-router-dom";
import { DeepPartial } from "react-hook-form";

export function commitMutationPromise<TMutation extends MutationParameters>(
  func: (config: UseMutationConfig<TMutation>) => Disposable,
): (config: UseMutationConfig<TMutation>) => Promise<TMutation["response"]> {
  return (config) => {
    return new Promise((resolve, reject) => {
      const { onCompleted, onError, ...rest } = config;
      func({
        ...rest,
        onCompleted: (response, errors) => {
          if (onCompleted) {
            onCompleted(response, errors);
          }
          resolve(response);
        },
        onError: (error) => {
          if (onError) {
            onError(error);
          }
          reject(error);
        },
      });
    });
  };
}

export interface PreloadedData<TQuery extends OperationType> {
  graphql: GraphQLTaggedNode;
  variables: TQuery["variables"];
  query: PreloadedQuery<TQuery>;
}

export const preload = <TQuery extends OperationType>(
  environment: Environment,
  graphql: GraphQLTaggedNode,
  variables: TQuery["variables"] = {},
): PreloadedData<TQuery> => {
  return {
    graphql,
    variables,
    query: loadQuery<TQuery>(environment, graphql, variables),
  };
};

export const reload = <TQuery extends OperationType>(
  environment: Environment,
  preloaded: PreloadedData<TQuery>,
): PreloadedData<TQuery> => {
  const { graphql, variables, query: _query, ...rest } = preloaded;
  return {
    graphql,
    variables,
    query: loadQuery<TQuery>(environment, graphql, variables),
    ...rest,
  };
};

export const usePreloaded = <TQuery extends OperationType>() => {
  const { variables, query, graphql, ...rest } =
    useLoaderData() as PreloadedData<TQuery>;
  return {
    variables,
    query: usePreloadedQuery<TQuery>(graphql, query),
    ...rest,
  };
};

export interface LoadedData<TQuery extends OperationType> {
  graphql: GraphQLTaggedNode;
  variables: TQuery["variables"];
  response: TQuery["response"];
}

export const load = async <TQuery extends OperationType>(
  environment: Environment,
  graphql: GraphQLTaggedNode,
  variables: TQuery["variables"] = {},
): Promise<LoadedData<TQuery>> => {
  const response = await fetchQuery<TQuery>(
    environment,
    graphql,
    variables,
  ).toPromise();
  return {
    graphql,
    variables,
    response,
  };
};

export const useLoaded = <TQuery extends OperationType>() => {
  const data = useLoaderData() as LoadedData<TQuery>;
  return data;
};

export const toAbortablePromise = <T>(
  observable: Observable<T>,
  signal: AbortSignal,
): Promise<T> => {
  return new Promise((resolve, reject) => {
    signal.addEventListener("abort", () => {
      reject(new DOMException("Aborted", "AbortError"));
    });
    observable.subscribe({
      next: (data) => {
        resolve(data);
      },
      error: (error: Error) => {
        reject(error);
      },
    });
  });
};

export interface RelayPayloadError {
  message: string;
  path: string[];
  extensions?: Record<string, unknown>;
}

export interface RelayNetworkErrorSource {
  errors: RelayPayloadError[];
}

export interface RelayNetworkError extends Error {
  source: RelayNetworkErrorSource;
  operation: OperationType;
  variables: Record<string, unknown>;
}

export function isRelayNetworkError(
  error: unknown,
): error is RelayNetworkError {
  return (
    typeof error === "object" &&
    error !== null &&
    "name" in error &&
    error.name === "RelayNetwork"
  );
}

export function collectRelayPayloadErrorCodes<K extends string = string>(
  error: RelayNetworkError,
): { [K0 in K]?: RelayPayloadError } {
  return Object.fromEntries(
    error.source.errors.map((error) => [error.extensions?.["code"], error]),
  );
}

export function relayErrorMessage(error: Error, combine = true) {
  if (isRelayNetworkError(error)) {
    if (error.source.errors.length > 0) {
      if (combine) {
        return error.source.errors.map((error) => error.message).join(". ");
      } else {
        return error.source.errors[0].message;
      }
    }
  }
  return error.message;
}

type ReplaceUploadableWithNull<T extends { [key: string]: unknown }> = {
  [Key in keyof T]: T[Key] extends Uploadable | null
    ? null
    : T[Key] extends Uploadable | null | undefined
      ? null | undefined
      : T[Key] extends { [key: string]: unknown }
        ? ReplaceUploadableWithNull<T[Key]>
        : T[Key];
};

export const extractUploadables = <T extends { [key: string]: unknown }>(
  data: T,
  prefix: string = "variables",
): { variables: ReplaceUploadableWithNull<T>; uploadables: UploadableMap } => {
  const variables = {} as ReplaceUploadableWithNull<T>;
  const uploadables: UploadableMap = {};
  for (const key in data) {
    if (data[key] instanceof File || data[key] instanceof Blob) {
      uploadables[`${prefix}.${key}`] = data[key] as Uploadable;
      variables[key] = null as ReplaceUploadableWithNull<T>[Extract<
        keyof T,
        string
      >];
    } else if (isPlainObject(data[key])) {
      const { variables: nestedVariables, uploadables: nestedUploadables } =
        extractUploadables(
          data[key] as { [key: string]: unknown },
          `${prefix}.${key}`,
        );
      variables[key] = nestedVariables as ReplaceUploadableWithNull<T>[Extract<
        keyof T,
        string
      >];
      Object.assign(uploadables, nestedUploadables);
    } else {
      variables[key] = data[key] as ReplaceUploadableWithNull<T>[Extract<
        keyof T,
        string
      >];
    }
  }
  return { variables, uploadables };
};

type ExtendUndefinedWithNull<T extends { [key: string]: unknown }> = {
  [Key in keyof T]: undefined extends T[Key]
    ? null | T[Key]
    : T[Key] extends { [key: string]: unknown }
      ? ExtendUndefinedWithNull<T[Key]>
      : T[Key];
};

export const replaceDirtyUndefinedWithNull = <
  T extends { [key: string]: unknown },
>(
  data: T,
  equalFields: DeepIsEqual<T>,
): ExtendUndefinedWithNull<T> => {
  const output = {} as ExtendUndefinedWithNull<T>;
  for (const key in data) {
    if (data[key] === undefined && equalFields[key] === false) {
      output[key] = null as ExtendUndefinedWithNull<T>[Extract<
        keyof T,
        string
      >];
    } else if (isPlainObject(data[key])) {
      const inner = data[key] as { [key: string]: unknown };
      const dirtyInner = equalFields[key] as unknown as DeepIsEqual<
        typeof inner
      >;
      output[key] = replaceDirtyUndefinedWithNull(
        inner,
        dirtyInner,
      ) as ExtendUndefinedWithNull<T>[Extract<keyof T, string>];
    } else {
      output[key] = data[key] as ExtendUndefinedWithNull<T>[Extract<
        keyof T,
        string
      >];
    }
  }
  return output;
};

export const filterEqualKeys = <T extends { [key: string]: unknown }>(
  data: T,
  equalFields: DeepIsEqual<T>,
): DeepPartial<T> => {
  const output = {} as DeepPartial<T>;
  for (const key in data) {
    if (isPlainObject(data[key])) {
      const inner = data[key] as { [key: string]: unknown };
      const dirtyInner = equalFields[key] as unknown as DeepIsEqual<
        typeof inner
      >;
      output[key] = replaceDirtyUndefinedWithNull(
        inner,
        dirtyInner,
      ) as DeepPartial<T>[Extract<keyof T, string>];
    } else if (!equalFields[key]) {
      output[key] = data[key] as DeepPartial<T>[Extract<keyof T, string>];
    }
  }
  return output;
};

export const preprocessEdit = <T extends { [key: string]: unknown }>(
  data: T,
  defaultValues: DeepPartial<T> = {} as DeepPartial<T>,
): ExtendUndefinedWithNull<DeepPartial<T>> => {
  const dirtyFields = deepIsEqual(data, defaultValues);
  return replaceDirtyUndefinedWithNull(
    filterEqualKeys(data, dirtyFields),
    dirtyFields as DeepIsEqual<DeepPartial<T>>,
  );
};
