import * as React from "react";
import { IconProps } from "./types";

export const RefreshCcw04Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17 18.8747C19.1213 17.3292 20.5 14.8256 20.5 12.0001C20.5 7.30568 16.6944 3.5001 12 3.5001H11.5M12 20.5001C7.30558 20.5001 3.5 16.6945 3.5 12.0001C3.5 9.17456 4.87867 6.67103 7 5.1255M11 22.4001L13 20.4001L11 18.4001M13 5.6001L11 3.6001L13 1.6001"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(RefreshCcw04Icon as React.FunctionComponent).displayName = "RefreshCcw04Icon";

export default RefreshCcw04Icon;
