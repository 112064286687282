import * as React from "react";
import { IconProps } from "./types";

export const CodepenIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 9L4.06386 14.1585C3.37601 14.6056 3.03209 14.8291 2.91297 15.1126C2.80888 15.3604 2.80888 15.6396 2.91297 15.8874M12 9L19.9361 14.1585C20.624 14.6056 20.9679 14.8291 21.087 15.1126C21.1911 15.3604 21.1911 15.6396 21.087 15.8874M12 9V2.5M12 15L4.06386 9.84151C3.37601 9.39441 3.03209 9.17086 2.91297 8.88736C2.80888 8.63961 2.80888 8.36039 2.91297 8.11264M12 15L19.9361 9.84151C20.624 9.39441 20.9679 9.17086 21.087 8.88736C21.1911 8.63961 21.1911 8.36039 21.087 8.11264M12 15V21.5M21.272 15.9732L12.872 21.4332C12.5564 21.6384 12.3985 21.7409 12.2285 21.7809C12.0782 21.8161 11.9218 21.8161 11.7715 21.7809C11.6015 21.7409 11.4436 21.6384 11.128 21.4332L2.72802 15.9732C2.46201 15.8003 2.32901 15.7139 2.23265 15.5985C2.14735 15.4965 2.08327 15.3784 2.04417 15.2512C2 15.1076 2 14.949 2 14.6317V9.3683C2 9.05104 2 8.89241 2.04417 8.74877C2.08327 8.62161 2.14735 8.50354 2.23265 8.40146C2.32901 8.28614 2.46201 8.19969 2.72802 8.02679L11.128 2.56679C11.4436 2.36164 11.6015 2.25906 11.7715 2.21915C11.9218 2.18387 12.0782 2.18387 12.2285 2.21915C12.3985 2.25906 12.5564 2.36164 12.872 2.56679L21.272 8.02679C21.538 8.19969 21.671 8.28614 21.7674 8.40146C21.8527 8.50354 21.9167 8.62161 21.9558 8.74877C22 8.89241 22 9.05104 22 9.3683V14.6317C22 14.949 22 15.1076 21.9558 15.2512C21.9167 15.3784 21.8527 15.4965 21.7674 15.5985C21.671 15.7139 21.538 15.8003 21.272 15.9732Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(CodepenIcon as React.FunctionComponent).displayName = "CodepenIcon";

export default CodepenIcon;
