import * as React from "react";
import { cva } from "class-variance-authority";
import { toast } from "sonner";

import { cn } from "@/utils/tailwind";
import { ToastProps, ToastLevel } from "@/utils/toast";
import {
  XCloseIcon,
  CheckCircleIcon,
  XCircleIcon,
  AlertTriangleIcon,
  InfoCircleIcon,
  IconProps,
} from "@/icons";

const progressBarClasses = cva(
  "absolute bottom-0 left-0 h-1 rounded-b-lg animate-width transition-all transition-100",
  {
    variants: {
      level: {
        info: "bg-info-600",
        success: "bg-success-600",
        error: "bg-error-600",
        warning: "bg-warning-600",
      },
      paused: {
        true: "opacity-0",
        false: "opacity-1",
      },
    },
  },
);

interface ToastProgressBarProps {
  duration: number;
  level: ToastLevel;
}

const ToastProgressBar = ({ duration, level }: ToastProgressBarProps) => {
  const [element, setElement] = React.useState<HTMLDivElement | null>(null);
  const [startTime, setStartTime] = React.useState<number | null>(null);
  const [progress, setProgress] = React.useState(0);
  React.useEffect(() => {
    let toasterRef: HTMLElement | null = null;
    let parent = element?.parentElement;
    while (parent) {
      if (!toasterRef && "sonnerToaster" in parent.dataset) {
        toasterRef = parent;
        break;
      }
      parent = parent.parentElement;
    }
    if (!toasterRef) {
      return;
    }
    setStartTime(Date.now());
    const unsetStart = () => setStartTime(null);
    toasterRef.addEventListener("mouseenter", unsetStart);
    toasterRef.addEventListener("pointerdown", unsetStart);
    return () => {
      if (!toasterRef) {
        return;
      }
      toasterRef.removeEventListener("mouseenter", unsetStart);
      toasterRef.removeEventListener("pointerdown", unsetStart);
    };
  }, [setStartTime, element]);
  React.useEffect(() => {
    if (!startTime) {
      return;
    }
    const interval = setInterval(() => {
      const elapsedTime = Date.now() - startTime;
      const progress = (elapsedTime / duration) * 100;
      setProgress(Math.min(progress, 100));
    }, 100);
    return () => clearInterval(interval);
  }, [startTime, duration]);
  return (
    <div
      ref={setElement}
      className={cn(progressBarClasses({ level, paused: !startTime }))}
      style={{ width: `${progress}%` }}
    ></div>
  );
};

const icons: Record<ToastLevel, (props: IconProps) => React.ReactNode> = {
  info: InfoCircleIcon,
  success: CheckCircleIcon,
  error: XCircleIcon,
  warning: AlertTriangleIcon,
};

const containerClasses = cva(
  "w-full rounded-lg py-3 px-4 flex flex-row items-center space-x-8",
  {
    variants: {
      level: {
        info: "bg-info-100",
        success: "bg-success-100",
        error: "bg-error-100",
        warning: "bg-warning-100",
      },
    },
  },
);

const iconClasses = cva("", {
  variants: {
    level: {
      info: "text-info-600",
      success: "text-success-600",
      error: "text-error-600",
      warning: "text-warning-600",
    },
  },
});

const Toast = ({ id, message, level, dismissible, duration }: ToastProps) => {
  const Icon = icons[level];
  return (
    <div className="relative w-[356px] max-w-full">
      <div className={cn(containerClasses({ level }))}>
        <div className="flex flex-row w-full items-center space-x-3 text-grey-900">
          <Icon className={cn(iconClasses({ level }))} />
          <div className="w-full text-md">{message}</div>
        </div>
        {dismissible && (
          <div className="p-2">
            <XCloseIcon
              className="size-5 hover:cursor-pointer"
              onClick={() => toast.dismiss(id)}
            />
          </div>
        )}
      </div>
      <ToastProgressBar duration={duration} level={level} />
    </div>
  );
};
(Toast as React.FunctionComponent).displayName = "Toast";

export { Toast };
