import * as React from "react";
import { IconProps } from "./types";

export const Save03Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M7 3H14.6745C15.1637 3 15.4083 3 15.6385 3.05526C15.8425 3.10425 16.0376 3.18506 16.2166 3.29472C16.4184 3.4184 16.5914 3.59135 16.9373 3.93726L20.0627 7.06274C20.4086 7.40865 20.5816 7.5816 20.7053 7.78343C20.8149 7.96237 20.8957 8.15746 20.9447 8.36154C21 8.59171 21 8.8363 21 9.32548V17M12.5 10H8.6C8.03995 10 7.75992 10 7.54601 9.89101C7.35785 9.79513 7.20487 9.64215 7.10899 9.45399C7 9.24008 7 8.96005 7 8.4V6.5M13.5 21V16.6C13.5 16.0399 13.5 15.7599 13.391 15.546C13.2951 15.3578 13.1422 15.2049 12.954 15.109C12.7401 15 12.4601 15 11.9 15H8.6C8.03995 15 7.75992 15 7.54601 15.109C7.35785 15.2049 7.20487 15.3578 7.10899 15.546C7 15.7599 7 16.0399 7 16.6V21M17.5 10.1627V17.8C17.5 18.9201 17.5 19.4802 17.282 19.908C17.0903 20.2843 16.7843 20.5903 16.408 20.782C15.9802 21 15.4201 21 14.3 21H6.2C5.0799 21 4.51984 21 4.09202 20.782C3.71569 20.5903 3.40973 20.2843 3.21799 19.908C3 19.4802 3 18.9201 3 17.8V9.7C3 8.57989 3 8.01984 3.21799 7.59202C3.40973 7.21569 3.71569 6.90973 4.09202 6.71799C4.51984 6.5 5.0799 6.5 6.2 6.5H13.8373C14.0818 6.5 14.2041 6.5 14.3192 6.52763C14.4213 6.55213 14.5188 6.59253 14.6083 6.64736C14.7092 6.7092 14.7957 6.79568 14.9686 6.96863L17.0314 9.03137C17.2043 9.20432 17.2908 9.2908 17.3526 9.39172C17.4075 9.48119 17.4479 9.57873 17.4724 9.68077C17.5 9.79586 17.5 9.91815 17.5 10.1627Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Save03Icon as React.FunctionComponent).displayName = "Save03Icon";

export default Save03Icon;
