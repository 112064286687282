import * as React from "react";
import { IconProps } from "./types";

export const VolumeMinIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M18.2451 8C19.036 9.13384 19.4998 10.5128 19.4998 12C19.4998 13.4873 19.036 14.8662 18.2451 16M12.1343 5.36569L8.96863 8.53137C8.79568 8.70433 8.7092 8.7908 8.60828 8.85264C8.51881 8.90747 8.42127 8.94788 8.31923 8.97237C8.20414 9 8.08185 9 7.83726 9H6.1C5.53995 9 5.25992 9 5.04601 9.109C4.85785 9.20487 4.70487 9.35785 4.60899 9.54601C4.5 9.75992 4.5 10.04 4.5 10.6V13.4C4.5 13.9601 4.5 14.2401 4.60899 14.454C4.70487 14.6422 4.85785 14.7951 5.04601 14.891C5.25992 15 5.53995 15 6.1 15H7.83726C8.08185 15 8.20414 15 8.31923 15.0276C8.42127 15.0521 8.51881 15.0925 8.60828 15.1474C8.7092 15.2092 8.79568 15.2957 8.96863 15.4686L12.1343 18.6343C12.5627 19.0627 12.7769 19.2769 12.9608 19.2914C13.1203 19.3039 13.2763 19.2393 13.3802 19.1176C13.5 18.9774 13.5 18.6744 13.5 18.0686V5.93137C13.5 5.32556 13.5 5.02265 13.3802 4.88239C13.2763 4.76068 13.1203 4.69609 12.9608 4.70865C12.7769 4.72312 12.5627 4.93731 12.1343 5.36569Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(VolumeMinIcon as React.FunctionComponent).displayName = "VolumeMinIcon";

export default VolumeMinIcon;
