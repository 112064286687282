import * as React from "react";
import { IconProps } from "./types";

export const CurrencyRupeeIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M6 3H18M6 8H18M14.5 21L6 13H9C15.667 13 15.667 3 9 3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(CurrencyRupeeIcon as React.FunctionComponent).displayName =
  "CurrencyRupeeIcon";

export default CurrencyRupeeIcon;
