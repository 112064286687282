import * as React from "react";
import { IconProps } from "./types";

export const SkewIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M19.7141 6.9999L18.2856 16.9999M6.14286 7L4.35714 17M18 5L8 5M16 19L6 19M5.6 7H6.4C6.96005 7 7.24008 7 7.45399 6.89101C7.64215 6.79513 7.79513 6.64215 7.89101 6.45399C8 6.24008 8 5.96005 8 5.4V4.6C8 4.03995 8 3.75992 7.89101 3.54601C7.79513 3.35785 7.64215 3.20487 7.45399 3.10899C7.24008 3 6.96005 3 6.4 3H5.6C5.03995 3 4.75992 3 4.54601 3.10899C4.35785 3.20487 4.20487 3.35785 4.10899 3.54601C4 3.75992 4 4.03995 4 4.6V5.4C4 5.96005 4 6.24008 4.10899 6.45399C4.20487 6.64215 4.35785 6.79513 4.54601 6.89101C4.75992 7 5.03995 7 5.6 7ZM3.6 21H4.4C4.96005 21 5.24008 21 5.45399 20.891C5.64215 20.7951 5.79513 20.6422 5.89101 20.454C6 20.2401 6 19.9601 6 19.4V18.6C6 18.0399 6 17.7599 5.89101 17.546C5.79513 17.3578 5.64215 17.2049 5.45399 17.109C5.24008 17 4.96005 17 4.4 17H3.6C3.03995 17 2.75992 17 2.54601 17.109C2.35785 17.2049 2.20487 17.3578 2.10899 17.546C2 17.7599 2 18.0399 2 18.6V19.4C2 19.9601 2 20.2401 2.10899 20.454C2.20487 20.6422 2.35785 20.7951 2.54601 20.891C2.75992 21 3.03995 21 3.6 21ZM19.6 7H20.4C20.9601 7 21.2401 7 21.454 6.89101C21.6422 6.79513 21.7951 6.64215 21.891 6.45399C22 6.24008 22 5.96005 22 5.4V4.6C22 4.03995 22 3.75992 21.891 3.54601C21.7951 3.35785 21.6422 3.20487 21.454 3.10899C21.2401 3 20.9601 3 20.4 3H19.6C19.0399 3 18.7599 3 18.546 3.10899C18.3578 3.20487 18.2049 3.35785 18.109 3.54601C18 3.75992 18 4.03995 18 4.6V5.4C18 5.96005 18 6.24008 18.109 6.45399C18.2049 6.64215 18.3578 6.79513 18.546 6.89101C18.7599 7 19.0399 7 19.6 7ZM17.6 21H18.4C18.9601 21 19.2401 21 19.454 20.891C19.6422 20.7951 19.7951 20.6422 19.891 20.454C20 20.2401 20 19.9601 20 19.4V18.6C20 18.0399 20 17.7599 19.891 17.546C19.7951 17.3578 19.6422 17.2049 19.454 17.109C19.2401 17 18.9601 17 18.4 17H17.6C17.0399 17 16.7599 17 16.546 17.109C16.3578 17.2049 16.2049 17.3578 16.109 17.546C16 17.7599 16 18.0399 16 18.6V19.4C16 19.9601 16 20.2401 16.109 20.454C16.2049 20.6422 16.3578 20.7951 16.546 20.891C16.7599 21 17.0399 21 17.6 21Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(SkewIcon as React.FunctionComponent).displayName = "SkewIcon";

export default SkewIcon;
