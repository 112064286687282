import * as React from "react";
import { IconProps } from "./types";

export const ChevronSelectorHorizontalIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9 7L4 12L9 17M15 7L20 12L15 17"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ChevronSelectorHorizontalIcon as React.FunctionComponent).displayName =
  "ChevronSelectorHorizontalIcon";

export default ChevronSelectorHorizontalIcon;
