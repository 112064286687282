import * as React from "react";
import { IconProps } from "./types";

export const GitCommitIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M16 12C16 14.2091 14.2092 16 12 16C9.79091 16 8.00005 14.2091 8.00005 12M16 12C16 9.79086 14.2092 8 12 8C9.79091 8 8.00005 9.79086 8.00005 12M16 12H22M8.00005 12H2.00024"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(GitCommitIcon as React.FunctionComponent).displayName = "GitCommitIcon";

export default GitCommitIcon;
