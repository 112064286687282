import { useCompetitionCreatePage } from "./loaders/CompetitionCreatePage";
import { useMemo } from "react";
import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";
import { extractUploadables, relayErrorMessage } from "@/utils/relay";
import { useIntl, FormattedMessage } from "react-intl";
import {
  GeneralSection,
  SubmissionSection,
  TagsSection,
  generalSchemaFactory,
  submissionSchema,
  tagsSchema,
} from "@/components/ActivityEdit";
import { toast } from "@/utils/toast";
import { logger } from "@/common/logger";
import { Form } from "@/kit/ui/form";
import { Button } from "@/kit/ui/button";
import { CompetitionCreatePageMutation as CompetitionCreatePageMutationType } from "./__generated__/CompetitionCreatePageMutation.graphql";

const CompetitionCreatePageMutation = graphql`
  mutation CompetitionCreatePageMutation(
    $input: CreateCompetitionInput!
    $connections: [ID!]!
  ) {
    createCompetition(input: $input) @prependEdge(connections: $connections) {
      node {
        id
        title
        description
        slug
        banner
        thumbnail
        isPrivate
        requiresApproval
        noCode
      }
    }
  }
`;

export default function CompetitionCreatePage() {
  const { query } = useCompetitionCreatePage();
  const intl = useIntl();
  const navigate = useNavigate();

  const formSchema = useMemo(
    () =>
      v.intersect([generalSchemaFactory(intl), submissionSchema, tagsSchema]),
    [intl],
  );

  const form = useForm<v.InferOutput<typeof formSchema>>({
    defaultValues: {
      title: "",
      shortDescription: "",
      slug: "",
      requiresApproval: false,
      grantHostSubmissionAccess: false,
      hasLeaderboard: true,
      noCode: false,
      visibility: "UNAUTHENTICATED",
    },
    resolver: valibotResolver(formSchema),
  });

  const [commitMutation, isMutationInFlight] =
    useMutation<CompetitionCreatePageMutationType>(
      CompetitionCreatePageMutation,
    );

  const onSubmit = (data: v.InferOutput<typeof formSchema>) => {
    const {
      variables: { input },
      uploadables,
    } = extractUploadables({
      input: data,
    });
    commitMutation({
      variables: {
        input,
        connections: [
          ConnectionHandler.getConnectionID(
            "root",
            "CompetitionsFragment_competitions",
          ),
        ],
      },
      uploadables,
      onError: (error) => {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted: (results) => {
        navigate(`/competitions/${results.createCompetition.node.slug}`);
      },
    });
  };

  return (
    <div className="pb-8 space-y-8">
      <Form form={form} onSubmit={onSubmit}>
        <div className="space-y-8">
          <div className="flex justify-between">
            <div>
              <h1 className="text-xl font-semibold">
                <FormattedMessage defaultMessage="Create Competition" />
              </h1>
              <p className="text-sm text-grey-500">
                <FormattedMessage defaultMessage="Manage competition options" />
              </p>
            </div>
            <div>
              <Button type="submit" disabled={isMutationInFlight}>
                <FormattedMessage defaultMessage="Create" />
              </Button>
            </div>
          </div>
          <div className="space-y-8">
            <GeneralSection autoSlug routePrefix="competitions" />
            <TagsSection tags={query} />
            <div>
              <h2 className="text-lg font-semibold">
                <FormattedMessage defaultMessage="Submission" />
              </h2>
              <p className="text-sm text-grey-500">
                <FormattedMessage defaultMessage="Manage rules for submissions" />
              </p>
            </div>
            <SubmissionSection />
          </div>
        </div>
      </Form>
    </div>
  );
}
