import * as React from "react";
import * as CheckboxPrimitive from "@radix-ui/react-checkbox";
import { CheckboxCheckIcon } from "@/icons";

import { cn } from "@/utils/tailwind";

interface CheckboxProps
  extends Omit<
    React.ComponentPropsWithoutRef<typeof CheckboxPrimitive.Root>,
    "onCheckedChange" | "checked" | "value" | "onChange"
  > {
  value?: boolean;
  onChange?: (event: boolean) => void;
}

const Checkbox = React.forwardRef<
  React.ElementRef<typeof CheckboxPrimitive.Root>,
  CheckboxProps
>(({ className, onChange, value, ...props }, ref) => (
  <CheckboxPrimitive.Root
    ref={ref}
    checked={value}
    onCheckedChange={onChange}
    className={cn(
      "peer h-4 w-4 shrink-0 rounded-sm border border-grey-300 shadow-sm hover:bg-grey-50 focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-brand-200 disabled:cursor-not-allowed disabled:bg-grey-50 data-[state=checked]:bg-brand-500 data-[state=checked]:text-white hover:data-[state=checked]:bg-brand-600",
      className,
    )}
    {...props}
  >
    <CheckboxPrimitive.Indicator
      className={cn("flex items-center justify-center text-current")}
    >
      <CheckboxCheckIcon className="h-3 w-3" />
    </CheckboxPrimitive.Indicator>
  </CheckboxPrimitive.Root>
));
(Checkbox as React.FunctionComponent).displayName =
  CheckboxPrimitive.Root.displayName;

export { Checkbox };
