import { useCallback, useEffect } from "react";
import { graphql, useMutation } from "react-relay";
import { useParams, useNavigate } from "react-router-dom";
import { FormattedMessage, useIntl } from "react-intl";
import { toast } from "sonner";

import defaultThumbnail from "../assets/background_16_9_white.svg";

import { useAuth, useLoginRedirect } from "../utils/auth";

import Markdown from "../components/Markdown";
import Button from "../components/Button";
import Card from "../components/Card";
import ErrorPage from "./ErrorPage";
import AspectDiv from "../components/AspectDiv";

import { EventInvitePageAcceptMutation as EventInvitePageAcceptMutationType } from "./__generated__/EventInvitePageAcceptMutation.graphql";
import { useEventInvitePageQuery } from "./loaders/EventInvitePage";

const EventInvitePageAcceptMutation = graphql`
  mutation EventInvitePageAcceptMutation($id: ID!, $code: String) {
    acceptEventInvite(id: $id, code: $code) {
      id
      event {
        id
        slug
      }
    }
  }
`;

export default function EventInvitePage() {
  const intl = useIntl();
  const navigate = useNavigate();
  const {
    query: { node: invite },
  } = useEventInvitePageQuery();
  const { code } = useParams();
  const loginRedirect = useLoginRedirect();
  const { userId } = useAuth();

  const [commitMutation, isMutationInFlight] =
    useMutation<EventInvitePageAcceptMutationType>(
      EventInvitePageAcceptMutation,
    );
  const accept = useCallback(() => {
    if (!invite.id) {
      return;
    }
    commitMutation({
      variables: { id: invite.id, code },
      onError: (_) => {
        toast.error(
          intl.formatMessage({
            defaultMessage:
              "Failed to join event. Check that the invite link is still valid and try again",
          }),
        );
      },
      onCompleted: (response) => {
        navigate(`/events/${response.acceptEventInvite.event.slug}`);
      },
    });
  }, [code, invite.id, intl, navigate, commitMutation]);

  useEffect(() => {
    if (invite.event?.slug) {
      navigate(`/events/${invite.event.slug}`);
    }
  }, [invite.event?.slug, navigate]);

  if (!invite.id) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Event invite note found!" />
      </ErrorPage>
    );
  }

  return (
    <Card className="max-w-screen-sm m-auto">
      <div>
        <AspectDiv
          className="rounded-t-md bg-old_grey"
          style={{
            backgroundImage: `url(${defaultThumbnail})`,
            backgroundSize: "1000px",
          }}
          ratio={3}
        >
          {invite.thumbnail && (
            <img
              src={invite.thumbnail}
              className="h-full w-full rounded-t-md object-cover object-center"
              alt={intl.formatMessage(
                { defaultMessage: "{title} thumbnail" },
                { title: invite.title },
              )}
            />
          )}
        </AspectDiv>
      </div>
      <div className="p-6 flex flex-col gap-4">
        <div>
          <h1 className="text-2xl font-bold w-full font-poppins py-2">
            {invite.title}
          </h1>
          <p>{invite.shortDescription}</p>
        </div>
        <div>
          <h2 className="text-xl font-bold w-full font-poppins">
            <FormattedMessage defaultMessage="Rules, Terms & Conditions" />
          </h2>
          {invite.latestRuleText && (
            <>
              <h2 className="font-bold w-full">
                <FormattedMessage defaultMessage="Event specific rules" />
              </h2>
              <div className="border border-gray-300 bg-background p-4 rounded-lg max-h-96 overflow-y-auto">
                <Markdown>{invite.latestRuleText}</Markdown>
              </div>
            </>
          )}
          <p className="py-2">
            <FormattedMessage defaultMessage="By default all events must comply to the rules defined in" />{" "}
            <a
              href="https://aqora.io/terms"
              target="_blank"
              rel="noreferrer"
              className="text-blue-400"
            >
              <FormattedMessage defaultMessage="aqora's Terms of Use" />
            </a>
          </p>
        </div>
        {userId ? (
          <Button
            disabled={isMutationInFlight}
            onClick={accept}
            className="w-full"
          >
            <FormattedMessage defaultMessage="Accept and Join Event" />
          </Button>
        ) : (
          <Button to={loginRedirect} className="w-full">
            <FormattedMessage defaultMessage="Accept and Join Event" />
          </Button>
        )}
      </div>
    </Card>
  );
}
