import {
  array,
  literal,
  number,
  object,
  optional,
  string,
  variant,
  InferOutput as SchemaOutput,
  safeParse as schemaParse,
} from "valibot";
import { assertNever } from "../../utils/helpers";
import Markdown from "../Markdown";
import ErrorPage from "../../pages/ErrorPage";
import { FormattedMessage } from "react-intl";
import CodeViewer from "../CodeViewer";
import { logger } from "../../common/logger";

const IPynbCell = variant("cell_type", [
  object({
    cell_type: literal("code"),
    source: array(string()),
  }),
  object({
    cell_type: literal("markdown"),
    source: array(string()),
  }),
  object({
    cell_type: literal("raw"),
  }),
]);

type IPynbCell = SchemaOutput<typeof IPynbCell>;

const IPynbNotebook = object({
  nbformat: number(),
  nbformat_minor: number(),
  cells: array(IPynbCell),
  metadata: object({
    kernelspec: optional(object({})),
    language_info: object({
      name: string(),
    }),
  }),
});

type IPynbNotebook = SchemaOutput<typeof IPynbNotebook>;

export interface PythonNotebookViewerProps {
  children: string;
}

export default function PythonNotebookViewer({
  children,
}: PythonNotebookViewerProps) {
  const notebookJson = JSON.parse(children);
  const notebook = schemaParse(IPynbNotebook, notebookJson);

  if (!notebook.success) {
    logger.error("Cannot parse notebook:", {
      error: notebook.issues,
      notebookJson,
    });
    return (
      <ErrorPage status={413}>
        <FormattedMessage defaultMessage="Cannot show this notebook right now" />
      </ErrorPage>
    );
  }

  return (
    <div className="flex flex-col space-y-2">
      {notebook.output.cells.map((cell, index) => (
        <CellViewer key={index} cell={cell} notebook={notebook.output} />
      ))}
    </div>
  );
}

interface CellViewerProps {
  cell: IPynbCell;
  notebook: IPynbNotebook;
}

function CellViewer({ cell, notebook }: CellViewerProps) {
  switch (cell.cell_type) {
    case "code":
      return (
        <CodeViewer
          language={notebook.metadata.language_info.name}
          className="border"
          noNumbers
        >
          {cell.source.join("")}
        </CodeViewer>
      );

    case "markdown":
      return (
        <div className="p-4 border-s-4 border-gray-300 bg-gray-50">
          <Markdown>{cell.source.join("")}</Markdown>
        </div>
      );

    case "raw":
      return null; // TODO: show raw cells

    default:
      assertNever(cell);
  }
}
