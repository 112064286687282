import { Toaster } from "sonner";
import { useEffect } from "react";
import Outlet from "../common/Outlet";
import { useAuthScaffoldQuery } from "./loaders/AuthScaffold";
import { useOnAuthCallback } from "../utils/auth";

export default function AuthScaffold() {
  const {
    query: { viewer },
  } = useAuthScaffoldQuery();
  const onAuthenticated = useOnAuthCallback();
  useEffect(() => {
    if (viewer) {
      onAuthenticated();
    }
  }, [viewer, onAuthenticated]);
  return (
    <div className="container mx-auto min-h-full flex justify-center items-center px-4 py-4">
      <Toaster position="top-center" />
      <div className="w-full max-w-sm flex flex-col">
        <Outlet />
      </div>
    </div>
  );
}
