/**
 * @generated SignedSource<<d549b13e487bff7f417812221c0c6741>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type VoteDisplayFragment$data = {
  readonly id: string;
  readonly viewerCanVote: boolean;
  readonly voted: {
    readonly score: number;
  } | null | undefined;
  readonly voterCount: number;
  readonly voters: {
    readonly " $fragmentSpreads": FragmentRefs<"EntitiesAvatarFragment">;
  };
  readonly votes: number;
  readonly " $fragmentType": "VoteDisplayFragment";
};
export type VoteDisplayFragment$key = {
  readonly " $data"?: VoteDisplayFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"VoteDisplayFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "VoteDisplayFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "id",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "votes",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "voterCount",
      "storageKey": null
    },
    {
      "alias": null,
      "args": [
        {
          "kind": "Literal",
          "name": "first",
          "value": 10
        }
      ],
      "concreteType": "VotersConnection",
      "kind": "LinkedField",
      "name": "voters",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "EntitiesAvatarFragment"
        }
      ],
      "storageKey": "voters(first:10)"
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "EntityVote",
      "kind": "LinkedField",
      "name": "voted",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "score",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": "viewerCanVote",
      "args": [
        {
          "kind": "Literal",
          "name": "action",
          "value": "PUBLISH_VOTE"
        }
      ],
      "kind": "ScalarField",
      "name": "viewerCan",
      "storageKey": "viewerCan(action:\"PUBLISH_VOTE\")"
    }
  ],
  "type": "Votable",
  "abstractKey": "__isVotable"
};

(node as any).hash = "7ba88ccee23db4d2b62b45ca7c26fd8c";

export default node;
