import * as React from "react";
import { IconProps } from "./types";

export const ArrowCircleBrokenUpRightIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M2.33944 14.5896C1.44852 11.2534 2.3117 7.54623 4.92899 4.92893C8.83424 1.02369 15.1659 1.02369 19.0711 4.92893C22.9764 8.83418 22.9764 15.1658 19.0711 19.0711C16.4538 21.6884 12.7467 22.5515 9.41051 21.6606M15.0002 15.0001V9.0001M15.0002 9.0001H9.0002M15.0002 9.0001L5.00001 19"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ArrowCircleBrokenUpRightIcon as React.FunctionComponent).displayName =
  "ArrowCircleBrokenUpRightIcon";

export default ArrowCircleBrokenUpRightIcon;
