import { graphql, Environment } from "react-relay";
import { LoginPageQuery as LoginPageQueryType } from "./__generated__/LoginPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { LoginPageQuery as LoginPageQueryType } from "./__generated__/LoginPageQuery.graphql";

export const LoginPageQuery = graphql`
  query LoginPageQuery {
    ...Oauth2LoginButtonsFragment
  }
`;

export const loginPageQueryLoader = (environment: Environment) => {
  return () => preload<LoginPageQueryType>(environment, LoginPageQuery);
};

export const useLoginPageQuery = () => usePreloaded<LoginPageQueryType>();
