import * as React from "react";
import { IconProps } from "./types";

export const MusicNote02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12 18V5.58885C12 4.73163 12 4.30302 12.1805 4.04489C12.3382 3.81949 12.5817 3.66897 12.8538 3.62877C13.1655 3.58273 13.5488 3.77441 14.3155 4.15777L18 6M12 18C12 19.6569 10.6569 21 9 21C7.34315 21 6 19.6569 6 18C6 16.3431 7.34315 15 9 15C10.6569 15 12 16.3431 12 18Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(MusicNote02Icon as React.FunctionComponent).displayName = "MusicNote02Icon";

export default MusicNote02Icon;
