import { graphql, useMutation } from "react-relay";
import { useSearchParams, useNavigate } from "react-router-dom";
import { toast } from "@/utils/toast";

import Loading from "@/components/Loading";

import { useOnceEffect } from "@/utils/hooks";
import { useOnAuthCallback } from "@/utils/auth";
import { relayErrorMessage } from "@/utils/relay";

import { Oauth2CallbackPageMutation as Oauth2CallbackPageMutationType } from "./__generated__/Oauth2CallbackPageMutation.graphql";

const Oauth2CallbackPageMutation = graphql`
  mutation Oauth2CallbackPageMutation($code: String!, $state: String!) {
    checkOauth2Auth(code: $code, state: $state) {
      action
      ... on Oauth2LoggedIn {
        user {
          id
        }
      }
      ... on Oauth2SignupRequired {
        oauth2Signup {
          secret
        }
        userInfo {
          email
          name
          username
          avatarUrl
          bio
        }
      }
    }
  }
`;

export default function Oauth2CallbackPage() {
  const navigate = useNavigate();
  const onAuthenticated = useOnAuthCallback();
  const [commit] = useMutation<Oauth2CallbackPageMutationType>(
    Oauth2CallbackPageMutation,
  );
  const [searchParams] = useSearchParams();
  const code = searchParams.get("code");
  const state = searchParams.get("state");
  useOnceEffect(() => {
    if (!code || !state) {
      toast.error("Invalid OAuth2 callback. No state or code found.");
      navigate("/login");
      return;
    }
    commit({
      variables: { code, state },
      onCompleted: ({ checkOauth2Auth: response }) => {
        if (response.action === "LOGGED_IN") {
          onAuthenticated();
        } else {
          navigate("/setup-user", {
            state: {
              signup: { oauth2: response.oauth2Signup },
              userInfo: response.userInfo,
            },
          });
        }
      },
      onError: (error) => {
        toast.error(relayErrorMessage(error));
        navigate("/login");
      },
    });
  });
  return (
    <div className="flex items-center justify-center">
      <div className="w-12">
        <Loading />
      </div>
    </div>
  );
}
