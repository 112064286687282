/**
 * @generated SignedSource<<e549618a608987ddec49f2913519a468>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
export type Oauth2Action = "LOGGED_IN" | "SIGNUP_REQUIRED";
export type Oauth2CallbackPageMutation$variables = {
  code: string;
  state: string;
};
export type Oauth2CallbackPageMutation$data = {
  readonly checkOauth2Auth: {
    readonly action: Oauth2Action;
    readonly oauth2Signup?: {
      readonly secret: string;
    };
    readonly user?: {
      readonly id: string;
    };
    readonly userInfo?: {
      readonly avatarUrl: any | null | undefined;
      readonly bio: string | null | undefined;
      readonly email: string;
      readonly name: string | null | undefined;
      readonly username: string | null | undefined;
    };
  };
};
export type Oauth2CallbackPageMutation = {
  response: Oauth2CallbackPageMutation$data;
  variables: Oauth2CallbackPageMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "code"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "state"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "code",
    "variableName": "code"
  },
  {
    "kind": "Variable",
    "name": "state",
    "variableName": "state"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "action",
  "storageKey": null
},
v3 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "User",
      "kind": "LinkedField",
      "name": "user",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "id",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Oauth2LoggedIn",
  "abstractKey": null
},
v4 = {
  "kind": "InlineFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "concreteType": "Oauth2Signup",
      "kind": "LinkedField",
      "name": "oauth2Signup",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "secret",
          "storageKey": null
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "Oauth2UserInfo",
      "kind": "LinkedField",
      "name": "userInfo",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "email",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "name",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "username",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "avatarUrl",
          "storageKey": null
        },
        {
          "alias": null,
          "args": null,
          "kind": "ScalarField",
          "name": "bio",
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "Oauth2SignupRequired",
  "abstractKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "Oauth2CallbackPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "checkOauth2Auth",
        "plural": false,
        "selections": [
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "Oauth2CallbackPageMutation",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "checkOauth2Auth",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/),
          (v3/*: any*/),
          (v4/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "272621ac298ddfd0ebe32173c2feb29f",
    "id": null,
    "metadata": {},
    "name": "Oauth2CallbackPageMutation",
    "operationKind": "mutation",
    "text": "mutation Oauth2CallbackPageMutation(\n  $code: String!\n  $state: String!\n) {\n  checkOauth2Auth(code: $code, state: $state) {\n    __typename\n    action\n    ... on Oauth2LoggedIn {\n      user {\n        id\n      }\n    }\n    ... on Oauth2SignupRequired {\n      oauth2Signup {\n        secret\n      }\n      userInfo {\n        email\n        name\n        username\n        avatarUrl\n        bio\n      }\n    }\n  }\n}\n"
  }
};
})();

(node as any).hash = "c04de077c71fb874d640b6d282cb6da9";

export default node;
