import * as React from "react";
import { IconProps } from "./types";

export const Announcement02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M4 14L5.57465 20.2986C5.61893 20.4757 5.64107 20.5643 5.66727 20.6416C5.92317 21.3971 6.60352 21.9283 7.39852 21.9933C7.4799 22 7.5712 22 7.75379 22C7.98244 22 8.09677 22 8.19308 21.9907C9.145 21.8983 9.89834 21.145 9.99066 20.1931C10 20.0968 10 19.9824 10 19.7538V5.5M18.5 13.5C20.433 13.5 22 11.933 22 10C22 8.06701 20.433 6.5 18.5 6.5M10.25 5.5H6.5C4.01472 5.5 2 7.51472 2 10C2 12.4853 4.01472 14.5 6.5 14.5H10.25C12.0164 14.5 14.1772 15.4469 15.8443 16.3556C16.8168 16.8858 17.3031 17.1509 17.6216 17.1119C17.9169 17.0757 18.1402 16.9431 18.3133 16.7011C18.5 16.4402 18.5 15.918 18.5 14.8737V5.12629C18.5 4.082 18.5 3.55985 18.3133 3.29889C18.1402 3.0569 17.9169 2.9243 17.6216 2.88813C17.3031 2.84912 16.8168 3.1142 15.8443 3.64436C14.1772 4.55312 12.0164 5.5 10.25 5.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Announcement02Icon as React.FunctionComponent).displayName =
  "Announcement02Icon";

export default Announcement02Icon;
