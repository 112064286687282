import * as React from "react";
import { IconProps } from "./types";

export const ArrowCircleBrokenUpIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M7 20.6622C4.01099 18.9331 2 15.7014 2 12C2 6.47715 6.47715 2 12 2C17.5228 2 22 6.47715 22 12C22 15.7014 19.989 18.9331 17 20.6622M16 12.0001L12 8.00007M12 8.00007L8 12.0001M12 8.00007V22.0001"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ArrowCircleBrokenUpIcon as React.FunctionComponent).displayName =
  "ArrowCircleBrokenUpIcon";

export default ArrowCircleBrokenUpIcon;
