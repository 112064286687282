import * as React from "react";
import { IconProps } from "./types";

export const PieChart01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M21.2104 15.8901C20.5742 17.3946 19.5792 18.7203 18.3123 19.7514C17.0454 20.7825 15.5452 21.4875 13.9428 21.8048C12.3405 22.1222 10.6848 22.0422 9.12055 21.5719C7.55627 21.1015 6.13103 20.2551 4.96942 19.1067C3.80782 17.9583 2.94522 16.5428 2.45704 14.984C1.96886 13.4252 1.86996 11.7706 2.169 10.1647C2.46804 8.55885 3.1559 7.0507 4.17245 5.7721C5.189 4.4935 6.50329 3.48339 8.0004 2.83007M21.2392 8.17323C21.6395 9.1397 21.8851 10.1614 21.9684 11.2009C21.989 11.4577 21.9993 11.5861 21.9483 11.7018C21.9057 11.7984 21.8213 11.8898 21.7284 11.94C21.6172 12.0001 21.4783 12.0001 21.2004 12.0001H12.8004C12.5204 12.0001 12.3804 12.0001 12.2734 11.9456C12.1793 11.8976 12.1028 11.8211 12.0549 11.7271C12.0004 11.6201 12.0004 11.4801 12.0004 11.2001V2.80007C12.0004 2.5222 12.0004 2.38327 12.0605 2.27205C12.1107 2.17915 12.2021 2.09476 12.2987 2.05216C12.4144 2.00117 12.5428 2.01146 12.7996 2.03205C13.839 2.11539 14.8608 2.36095 15.8272 2.76127C17.0405 3.26382 18.1429 4.00042 19.0715 4.929C20.0001 5.85759 20.7367 6.95998 21.2392 8.17323Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(PieChart01Icon as React.FunctionComponent).displayName = "PieChart01Icon";

export default PieChart01Icon;
