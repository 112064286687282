import * as React from "react";
import { IconProps } from "./types";

export const Send02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M12.0005 19V12M12.292 19.0846L19.2704 21.4208C19.8173 21.6039 20.0908 21.6954 20.2594 21.6297C20.4059 21.5727 20.517 21.4501 20.5594 21.2988C20.6082 21.1245 20.4903 20.8614 20.2545 20.3351L12.766 3.62232C12.5354 3.10762 12.4201 2.85027 12.2594 2.77053C12.1199 2.70125 11.956 2.70099 11.8162 2.76982C11.6553 2.84905 11.5392 3.10602 11.3069 3.61998L3.75244 20.336C3.51474 20.862 3.39589 21.125 3.44422 21.2994C3.48619 21.4509 3.59697 21.5738 3.74329 21.6313C3.91178 21.6975 4.18567 21.6066 4.73346 21.4248L11.786 19.084C11.8799 19.0528 11.9268 19.0372 11.9749 19.0311C12.0175 19.0256 12.0606 19.0257 12.1032 19.0312C12.1512 19.0375 12.1981 19.0532 12.292 19.0846Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Send02Icon as React.FunctionComponent).displayName = "Send02Icon";

export default Send02Icon;
