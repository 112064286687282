import { cva } from "class-variance-authority";

const buttonVariants = cva(
  "inline-flex items-center justify-center gap-1 whitespace-nowrap rounded-lg text-sm font-semibold transition-colors hover:cursor-pointer disabled:hover:cursor-default focus-visible:outline-none focus-visible:ring-2 focus-visible:ring-offset-2 focus-visible:ring-brand-200 disabled:pointer-events-none [&_svg]:pointer-events-none [&_svg]:size-5 [&_svg]:shrink-0",
  {
    variants: {
      variant: {
        primary:
          "border border-brand-500 bg-brand-500 hover:border-brand-600 hover:bg-brand-600 active:bg-brand-600 text-white shadow-button_primary active:shadow-button_primary_active disabled:border-grey-200 disabled:bg-grey-100 disabled:text-grey-400 disabled:shadow-sm",
        secondary:
          "border border-grey-300 bg-white text-grey-700 shadow-button_secondary hover:bg-grey-50 active:shadow-button_secondary_active active:bg-grey-50 disabled:border-grey-200 disabled:text-grey-400 disabled:shadow-sm",
        tertiary: "text-brand-500 hover:bg-brand-50 disabled:text-grey-400",
        link: "text-brand-500 underline-offset-2 hover:underline disabled:text-grey-400",
      },
      icon: {
        true: null,
        false: null,
      },
      size: {
        sm: "py-2 px-3",
        md: "py-2.5 px-3.5",
        lg: "py-2.5 px-4 gap-1.5 text-md",
        xl: "py-3 px-[18px] gap-1.5 text-md",
        xxl: "py-3.5 px-5 gap-2.5 text-lg [&_svg]:size-6",
      },
      hue: {
        default: null,
        grey: null,
        danger: "focus-visible:ring-error-400",
      },
    },
    compoundVariants: [
      { icon: true, size: "sm", class: "w-9" },
      { icon: true, size: "md", class: "w-10" },
      { icon: true, size: "lg", class: "w-11" },
      { icon: true, size: "xl", class: "w-12" },
      { icon: true, size: "xxl", class: "w-14" },
      {
        variant: "tertiary",
        hue: "grey",
        class: "text-grey-700 hover:bg-grey-50",
      },
      {
        variant: "link",
        size: ["sm", "md", "lg", "xl", "xxl"],
        class: "py-0 px-0",
      },
      {
        variant: "link",
        hue: "grey",
        class: "text-grey-700",
      },
      {
        variant: "primary",
        hue: "danger",
        class:
          "border-error-500 bg-error-500 hover:border-error-600 hover:bg-error-600 active:bg-error-600 shadow-button_primary_danger active:shadow-button_primary_danger_active",
      },
      {
        variant: "secondary",
        hue: "danger",
        class:
          "border-error-300 text-error-700 hover:text-error-800 hover:bg-error-50 active:bg-error-50",
      },
      {
        variant: "tertiary",
        hue: "danger",
        class: "text-error-700 hover:bg-error-50",
      },
      {
        variant: "link",
        hue: "danger",
        class: "text-error-700",
      },
    ],
    defaultVariants: {
      variant: "primary",
      size: "md",
      hue: "default",
      icon: false,
    },
  },
);

export { buttonVariants };
