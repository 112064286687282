import { graphql, Environment, useFragment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { BlogArticleEditPageQuery as BlogArticleEditPageQueryType } from "./__generated__/BlogArticleEditPageQuery.graphql";
import { BlogArticleEditPageFragment$key } from "./__generated__/BlogArticleEditPageFragment.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { BlogArticleEditPageQuery as BlogArticleEditPageQueryType } from "./__generated__/BlogArticleEditPageQuery.graphql";

export const BlogArticleEditPageFragment = graphql`
  fragment BlogArticleEditPageFragment on BlogArticle {
    id
    title
    imageAlt
    content
    image
    shortDescription
    seoTitle
    seoDescription
    authors {
      edges {
        node {
          id
        }
      }
    }
    ...BlogArticleDeleteButtonFragment
  }
`;

export const BlogArticleEditPageQuery = graphql`
  query BlogArticleEditPageQuery($slug: String!) {
    ...UserAutocompleteFragment
    blogArticleBySlug(slug: $slug) {
      ...BlogArticleEditPageFragment
    }
  }
`;

export const blogArticleEditPageQueryLoader = (environment: Environment) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<BlogArticleEditPageQueryType>(
      environment,
      BlogArticleEditPageQuery,
      {
        slug,
      },
    );
  };
};

export const useBlogArticleEditPageQuery = () => {
  const { query, ...preloaded } = usePreloaded<BlogArticleEditPageQueryType>();
  const article = useFragment(
    BlogArticleEditPageFragment,
    query.blogArticleBySlug as BlogArticleEditPageFragment$key | null,
  );
  return { query, article, ...preloaded };
};
