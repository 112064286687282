import { useCallback, useEffect, useRef, useState } from "react";
import { useFragment, useMutation } from "react-relay";
import ForumTopics from "../../components/ForumTopics";
import { FormattedMessage, useIntl } from "react-intl";
import Button from "../../components/Button";
import VotableOrderSelect from "../../components/VotableOrderSelect";
import EditMarkdownSection from "../../components/EditMarkdownSection";
import { logger } from "../../common/logger";
import {
  VotableOrder,
  TopicVotableOrderContext,
} from "../../utils/votableOrder";
import { useAuth } from "../../utils/auth";
import { getForumPath } from "../../utils/routing";
import { graphql } from "relay-runtime";
import { TopicsPageFragment$key } from "./__generated__/TopicsPageFragment.graphql";
import SubjectSubscriptionButton from "../../components/SubjectSubscriptionButton";

import { TopicsPageUpdateForumDescriptionMutation } from "./__generated__/TopicsPageUpdateForumDescriptionMutation.graphql";
import { MdAddCircleOutline } from "react-icons/md";
import { cn } from "../../utils/tailwind";
import { useMediaQuery } from "../../utils/hooks";
import { DESKTOP_MEDIA_QUERY } from "../../common/constants";
import { supportsPassiveEvents } from "../../utils/helpers";

const Fragment = graphql`
  fragment TopicsPageFragment on Forum
  @argumentDefinitions(order: { type: "VotableOrder", defaultValue: HOT }) {
    id
    description
    slug
    owner {
      slug
      forumOwnerKind
    }
    viewerCanUpdate: viewerCan(action: UPDATE_FORUM)
    viewerCanCreateTopic: viewerCan(action: CREATE_TOPIC)
    ...ForumTopicsFragment @arguments(order: $order)
    ...SubjectSubscriptionButtonFragment
  }
`;

const UpdateForumDescriptionMutation = graphql`
  mutation TopicsPageUpdateForumDescriptionMutation(
    $forumId: ID!
    $description: String
  ) {
    updateForum(id: $forumId, input: { description: $description }) {
      node {
        id
        description
      }
    }
  }
`;

function DescriptionSection({
  forum: forumFragment,
}: {
  forum: TopicsPageFragment$key;
}) {
  const intl = useIntl();

  const forum = useFragment(Fragment, forumFragment);
  const [commitUpdateDescriptionMutation, isUpdateDescriptionMutationInFlight] =
    useMutation<TopicsPageUpdateForumDescriptionMutation>(
      UpdateForumDescriptionMutation,
    );

  if (!(forum.viewerCanUpdate || forum.description)) {
    return null;
  }

  return (
    <div className="!mt-4">
      <EditMarkdownSection
        className="pb-0"
        members={{ kind: "none" }}
        canEdit={forum.viewerCanUpdate}
        canUploadFiles
        disabled={isUpdateDescriptionMutationInFlight}
        defaultValue={forum.description ?? ""}
        onSubmit={(value, setError, onCompleted) => {
          commitUpdateDescriptionMutation({
            variables: {
              forumId: forum.id,
              description: value || null,
            },
            onCompleted,
            onError: (error) => {
              logger.error(error);
              setError(
                intl.formatMessage({
                  defaultMessage:
                    "An error occurred while saving the description. Please, try again later.",
                }),
              );
            },
          });
        }}
      />
    </div>
  );
}

export interface TopicsPageProps {
  forum: TopicsPageFragment$key;
  initialOrder: VotableOrder;
}

export default function TopicsPage({
  forum: forumFragment,
  initialOrder,
}: TopicsPageProps) {
  const { userId } = useAuth();
  const forum = useFragment(Fragment, forumFragment);
  const [order, setOrder] = useState<VotableOrder>(initialOrder);
  const isDesktop = useMediaQuery(DESKTOP_MEDIA_QUERY);
  const [sticky, setSticky] = useState(false);
  const headerRef = useRef<HTMLDivElement | null>(null);

  const handleScroll = useCallback(() => {
    if (headerRef.current) {
      const headerTop = headerRef.current.getBoundingClientRect().top;
      setSticky(headerTop <= 0);
    }
  }, []);

  useEffect(() => {
    if (isDesktop) {
      setSticky(false);
      return;
    }

    window.addEventListener(
      "scroll",
      handleScroll,
      supportsPassiveEvents() ? { passive: true } : false,
    );

    return () => {
      window.removeEventListener("scroll", handleScroll);
    };
  }, [isDesktop, handleScroll]);

  return (
    <TopicVotableOrderContext.Provider value={order}>
      <div ref={headerRef} className="relative">
        <div
          className={cn(
            sticky && !isDesktop ? "sticky top-0 bg-white w-full" : undefined,
            sticky
              ? "flex flex-row justify-center items-center py-4 w-full"
              : "flex sm:flex-row flex-col items-center",
          )}
        >
          <div
            className={cn(
              "flex flex-row flex-1 sm:items-baseline items-center gap-4",
              !sticky ? "sm:pb-0 pb-4" : undefined,
            )}
          >
            <h1 className="text-2xl font-bold font-poppins">
              <FormattedMessage defaultMessage="Topics" />
            </h1>
            <VotableOrderSelect value={order} onChange={setOrder} />
          </div>
          <div className="flex flex-row items-center justify-between gap-4">
            <SubjectSubscriptionButton
              subject={forum}
              collapsed={sticky && !isDesktop}
            />
            {userId && forum.viewerCanCreateTopic && (
              <Button
                to={getForumPath(forum, "new")}
                state={{ order }}
                className="flex flex-row items-center"
              >
                <MdAddCircleOutline
                  className="inline-block"
                  size={ICONS_SIZE}
                />
                <span
                  className={cn(
                    "inline-block",
                    sticky && !isDesktop ? "hidden" : "pl-2",
                  )}
                >
                  <FormattedMessage defaultMessage="New Topic" />
                </span>
              </Button>
            )}
          </div>
        </div>
        <DescriptionSection forum={forumFragment} />
        <div className="mt-4 pb-8">
          <ForumTopics forum={forum} order={order} />
        </div>
      </div>
    </TopicVotableOrderContext.Provider>
  );
}

const ICONS_SIZE = 24;
