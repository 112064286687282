import { graphql, useMutation, ConnectionHandler } from "react-relay";
import { UploadableMap } from "relay-runtime";
import {
  OrganizationCreatePageMutation$variables,
  OrganizationCreatePageMutation as OrganizationCreatePageMutationType,
} from "./__generated__/OrganizationCreatePageMutation.graphql";
import { useNavigate } from "react-router-dom";
import { useForm } from "react-hook-form";
import { FormattedMessage, useIntl } from "react-intl";
import EntityEditForm, {
  FormData,
  SubmitData,
} from "../components/EntityEditForm";
import { useAuth } from "../utils/auth";
import { logger } from "../common/logger";

const OrganizationCreatePageMutation = graphql`
  mutation OrganizationCreatePageMutation(
    $input: CreateOrganizationInput!
    $connections: [ID!]!
  ) {
    createOrganization(input: $input) @prependEdge(connections: $connections) {
      node {
        id
        displayName
        username
        linkedin
        github
        location
        website
        bio
        image
        imageThumbnail
        createdAt
      }
    }
  }
`;

export default function OrganizationCreatePage() {
  const auth = useAuth();
  const intl = useIntl();
  const navigate = useNavigate();
  const form = useForm<FormData>();

  const [commitMutation, isMutationInFlight] =
    useMutation<OrganizationCreatePageMutationType>(
      OrganizationCreatePageMutation,
    );

  const onSubmit = ({ data, image, setFormError }: SubmitData<FormData>) => {
    setFormError(undefined);
    const connections = [];
    if (auth.userId) {
      connections.push(
        ConnectionHandler.getConnectionID(
          auth.userId,
          "UserOrganizationsFragment_organizations",
        ),
      );
    }
    const variables: OrganizationCreatePageMutation$variables = {
      input: {
        displayName: data.displayName,
        username: data.username,
        location: data.location || undefined,
        linkedin: data.linkedin || undefined,
        github: data.github || undefined,
        website: data.website || undefined,
        bio: data.bio || undefined,
      },
      connections,
    };
    variables.input.image = image.variable;
    const uploadables: UploadableMap = {};
    if (image.uploadable) {
      uploadables["variables.input.image"] = image.uploadable;
    }
    commitMutation({
      variables,
      uploadables,
      onError: (error) => {
        logger.error(error);
        setFormError(
          intl.formatMessage({
            defaultMessage:
              "Could not create account. username or email may already exist. Check and try again.",
          }),
        );
      },
      onCompleted: (results) => {
        navigate(`/${results.createOrganization.node.username}`);
      },
    });
  };

  return (
    <div>
      <h1 className="text-xl font-bold pb-4 font-poppins">
        <FormattedMessage defaultMessage="Create a new organization" />
      </h1>
      <EntityEditForm
        form={form}
        onSubmit={onSubmit}
        isDisabled={isMutationInFlight}
      />
    </div>
  );
}
