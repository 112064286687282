import React, { useState, useRef } from "react";
import { MdVisibility, MdVisibilityOff } from "react-icons/md";
import { Spread } from "../utils/types";

interface PropsExtra {
  prefix?: React.ReactNode;
  suffix?: React.ReactNode;
}

interface Props
  extends Spread<React.InputHTMLAttributes<HTMLInputElement>, PropsExtra> {}

const TextInput = React.forwardRef(
  (
    { prefix, suffix, className, type, onFocus, onBlur, ...rest }: Props,
    ref: React.ForwardedRef<HTMLInputElement>,
  ) => {
    const myRef = useRef<HTMLInputElement | null>(null);
    const [passwordVisible, setPasswordVisible] = useState(false);
    const [isFocused, setIsFocused] = useState(false);
    const displayType = type === "password" && passwordVisible ? "text" : type;
    const setFocus = () => {
      if (myRef.current) {
        myRef.current.focus();
      }
    };
    return (
      <div
        className={`flex flex-row items-center bg-white border border-gray-300 rounded px-4 py-2 ${className} ${
          isFocused ? "ring-2 ring-old_grey" : ""
        }`}
      >
        {prefix && (
          <div
            className="pr-2 text-gray-400 select-none cursor-text"
            onClick={setFocus}
          >
            {prefix}
          </div>
        )}
        <input
          className="flex-grow focus:outline-none w-full"
          type={displayType}
          ref={(node) => {
            myRef.current = node;
            if (typeof ref === "function") {
              ref(node);
            } else if (ref) {
              ref.current = node;
            }
          }}
          onFocus={(event) => {
            setIsFocused(true);
            onFocus?.(event);
          }}
          onBlur={(event) => {
            setIsFocused(false);
            onBlur?.(event);
          }}
          {...rest}
        />
        {type === "password" && (
          <a
            className="pl-2 text-xl"
            onClick={() => setPasswordVisible(!passwordVisible)}
          >
            {passwordVisible ? <MdVisibilityOff /> : <MdVisibility />}
          </a>
        )}
        {suffix && <div className="pl-2">{suffix}</div>}
      </div>
    );
  },
);
export default TextInput;
