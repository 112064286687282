import * as React from "react";

import { cn } from "@/utils/tailwind";

const Divider = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, children, ...props }, ref) => {
  return (
    <div
      className={cn("text-center relative min-h-px", className)}
      {...props}
      ref={ref}
    >
      <div className="absolute z-0 top-1/2 border border-grey-200 w-full"></div>
      {children && (
        <span className="relative z-10 inline-block bg-white px-3 text-sm text-grey-600">
          {children}
        </span>
      )}
    </div>
  );
});
(Divider as React.FunctionComponent).displayName = "Divider";

export { Divider };
