import * as React from "react";
import { IconProps } from "./types";

export const LogIn03Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M6 17.0001C6 17.3514 6 17.527 6.01567 17.6797C6.14575 18.9475 7.0626 19.9947 8.30206 20.2912C8.45134 20.3269 8.6255 20.3501 8.97368 20.3966L15.5656 21.2755C17.442 21.5257 18.3803 21.6508 19.1084 21.3612C19.7478 21.107 20.2803 20.6408 20.6168 20.0407C21 19.3572 21 18.4106 21 16.5176V7.48256C21 5.58952 21 4.643 20.6168 3.95947C20.2803 3.35936 19.7478 2.89313 19.1084 2.63892C18.3803 2.34938 17.442 2.47448 15.5656 2.72467L8.97368 3.60359C8.62546 3.65002 8.45135 3.67324 8.30206 3.70895C7.0626 4.00544 6.14575 5.05266 6.01567 6.32043C6 6.47313 6 6.64878 6 7.00008M12 8.00008L16 12.0001M16 12.0001L12 16.0001M16 12.0001H3"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(LogIn03Icon as React.FunctionComponent).displayName = "LogIn03Icon";

export default LogIn03Icon;
