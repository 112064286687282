import * as React from "react";
import { Slot } from "@radix-ui/react-slot";

import { cn } from "@/utils/tailwind";
import {
  InputContext,
  inputInputVariants,
  inputDecoratorVariants,
  InputDecoratorType,
} from "@/kit/helpers/input";

const InputRoot = React.forwardRef<
  HTMLDivElement,
  React.HTMLAttributes<HTMLDivElement>
>(({ className, ...props }, ref) => {
  return (
    <div className={cn("flex shadow-sm", className)} ref={ref} {...props} />
  );
});
(InputRoot as React.FunctionComponent).displayName = "InputRoot";

const InputInput = React.forwardRef<
  HTMLInputElement,
  React.ComponentProps<"input">
>(({ className, ...props }, ref) => {
  const {
    isFocused,
    isError: isInvalid,
    startDecoratorType,
    endDecoratorType,
    isDisabled,
  } = React.useContext(InputContext);
  return (
    <input
      className={cn(
        inputInputVariants({
          focused: isFocused,
          invalid: isInvalid,
          disabled: isDisabled,
          startDecorator: startDecoratorType,
          endDecorator: endDecoratorType,
          className,
        }),
      )}
      ref={ref}
      {...props}
    />
  );
});
(InputInput as React.FunctionComponent).displayName = "InputInput";

interface DecoratorProps extends React.HTMLAttributes<HTMLDivElement> {
  asChild?: boolean;
  separated?: boolean;
  position?: "start" | "end";
}

const InputDecorator = React.forwardRef<HTMLDivElement, DecoratorProps>(
  (
    {
      className,
      asChild = false,
      separated = false,
      position,
      children,
      ...props
    },
    ref,
  ) => {
    const Comp = asChild ? Slot : "div";
    const {
      isDisabled,
      isFocused,
      isError: isInvalid,
    } = React.useContext(InputContext);
    return (
      <Comp
        className={cn(
          inputDecoratorVariants({
            focused: isFocused,
            invalid: isInvalid,
            disabled: isDisabled,
            position,
            separated,
            className,
          }),
        )}
        ref={ref}
        {...props}
      >
        {children}
      </Comp>
    );
  },
);
(InputDecorator as React.FunctionComponent).displayName = "InputDecorator";

const isInputDecorator = (
  node: React.ReactNode,
): node is React.ReactElement<
  React.ComponentProps<typeof InputDecorator>,
  typeof InputDecorator
> => {
  return React.isValidElement(node) && node.type == InputDecorator;
};

const wrapInputDecorator = (
  position: "start" | "end",
  node?: React.ReactNode,
): [InputDecoratorType, React.ReactNode] => {
  if (node === undefined) {
    return [InputDecoratorType.None, null];
  } else if (isInputDecorator(node)) {
    return [
      node.props.asChild
        ? InputDecoratorType.Separated
        : node.props.separated
          ? InputDecoratorType.Separated
          : InputDecoratorType.Integrated,
      React.cloneElement(node, { position }, node.props.children),
    ];
  } else {
    return [
      InputDecoratorType.Integrated,
      <InputDecorator position={position}>{node}</InputDecorator>,
    ];
  }
};

interface InputProps extends React.ComponentProps<"input"> {
  startDecorator?: React.ReactNode;
  endDecorator?: React.ReactNode;
  inputProps?: React.ComponentProps<typeof InputInput>;
  containerProps?: React.ComponentProps<typeof InputRoot>;
}

const Input = React.forwardRef<HTMLInputElement, InputProps>(
  (
    {
      autoFocus = false,
      startDecorator: startDecoratorNode,
      endDecorator: endDecoratorNode,
      inputProps: overrideInputProps,
      containerProps,
      type: defaultType,
      className,
      onClick,
      ...props
    },
    ref,
  ) => {
    const [type, setType] = React.useState(defaultType ?? "text");
    const [isFocused, setIsFocused] = React.useState(autoFocus);
    const {
      onFocus: defaultOnFocus,
      onBlur: defaultOnBlur,
      ...inputProps
    } = {
      ...props,
      ...overrideInputProps,
    };

    const onFocus = (e: React.FocusEvent<HTMLInputElement>) => {
      defaultOnFocus?.(e);
      if (!e.defaultPrevented) {
        setIsFocused(true);
      }
    };
    const onBlur = (e: React.FocusEvent<HTMLInputElement>) => {
      defaultOnBlur?.(e);
      if (!e.defaultPrevented) {
        setIsFocused(false);
      }
    };

    const isError =
      inputProps["aria-invalid"] === true ||
      inputProps["aria-invalid"] === "true";
    const isDisabled = !!inputProps.disabled;

    const [startDecoratorType, startDecorator] = wrapInputDecorator(
      "start",
      startDecoratorNode,
    );
    const [endDecoratorType, endDecorator] = wrapInputDecorator(
      "end",
      endDecoratorNode,
    );

    return (
      <InputContext.Provider
        value={{
          type,
          setType,
          isError,
          isDisabled,
          isFocused,
          startDecoratorType,
          endDecoratorType,
        }}
      >
        <InputRoot className={className} onClick={onClick} {...containerProps}>
          {startDecorator}
          <InputInput
            {...inputProps}
            autoFocus={autoFocus}
            type={type}
            onBlur={onBlur}
            onFocus={onFocus}
            ref={ref}
          />
          {endDecorator}
        </InputRoot>
      </InputContext.Provider>
    );
  },
);
(Input as React.FunctionComponent).displayName = "Input";

export { Input, InputDecorator };
