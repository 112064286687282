import { DeepPartial } from "react-hook-form";
import _ from "lodash";

export type DeepIsEqual<T> = T extends { [key: string]: unknown }
  ? { [K in keyof T]: DeepIsEqual<T[K]> }
  : boolean;

export const deepIsEqual = <T>(
  data: T,
  defaultValues?: DeepPartial<T>,
): DeepIsEqual<T> => {
  if (_.isPlainObject(data)) {
    return _.mapValues(data as object, (value, key) =>
      deepIsEqual(value, _.get(defaultValues, key)),
    ) as DeepIsEqual<T>;
  } else {
    return _.isEqual(data, defaultValues) as DeepIsEqual<T>;
  }
};
