/**
 * @generated SignedSource<<52dcba44f688fffe98efc81ead162ea4>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type LoginPageQuery$variables = Record<PropertyKey, never>;
export type LoginPageQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"Oauth2LoginButtonsFragment">;
};
export type LoginPageQuery = {
  response: LoginPageQuery$data;
  variables: LoginPageQuery$variables;
};

const node: ConcreteRequest = {
  "fragment": {
    "argumentDefinitions": [],
    "kind": "Fragment",
    "metadata": null,
    "name": "LoginPageQuery",
    "selections": [
      {
        "args": null,
        "kind": "FragmentSpread",
        "name": "Oauth2LoginButtonsFragment"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [],
    "kind": "Operation",
    "name": "LoginPageQuery",
    "selections": [
      {
        "alias": null,
        "args": null,
        "kind": "ScalarField",
        "name": "oauth2Providers",
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "200eefbd5ce696fe5b5d14c796062ff5",
    "id": null,
    "metadata": {},
    "name": "LoginPageQuery",
    "operationKind": "query",
    "text": "query LoginPageQuery {\n  ...Oauth2LoginButtonsFragment\n}\n\nfragment Oauth2LoginButtonsFragment on Query {\n  oauth2Providers\n}\n"
  }
};

(node as any).hash = "9c36d53ff4e65abad6430f091e78566c";

export default node;
