import * as React from "react";
import { IconProps } from "./types";

export const Signal01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M16.2426 7.75735C18.5858 10.1005 18.5858 13.8995 16.2426 16.2426M7.75736 16.2426C5.41421 13.8995 5.41421 10.1005 7.75736 7.75732M4.92893 19.0711C1.02369 15.1658 1.02369 8.83417 4.92893 4.92892M19.0711 4.92897C22.9763 8.83421 22.9763 15.1659 19.0711 19.0711M14 12C14 13.1046 13.1046 14 12 14C10.8954 14 10 13.1046 10 12C10 10.8954 10.8954 9.99999 12 9.99999C13.1046 9.99999 14 10.8954 14 12Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Signal01Icon as React.FunctionComponent).displayName = "Signal01Icon";

export default Signal01Icon;
