import * as React from "react";
import { IconProps } from "./types";

export const Minimize02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M2.99988 7.99994H3.19988C4.88004 7.99994 5.72011 7.99994 6.36185 7.67296C6.92634 7.38534 7.38528 6.9264 7.6729 6.36191C7.99988 5.72018 7.99988 4.8801 7.99988 3.19994V2.99994M2.99988 15.9999H3.19988C4.88004 15.9999 5.72011 15.9999 6.36185 16.3269C6.92634 16.6145 7.38528 17.0735 7.6729 17.638C7.99988 18.2797 7.99988 19.1198 7.99988 20.7999V20.9999M15.9999 2.99994V3.19994C15.9999 4.8801 15.9999 5.72018 16.3269 6.36191C16.6145 6.9264 17.0734 7.38534 17.6379 7.67296C18.2796 7.99994 19.1197 7.99994 20.7999 7.99994H20.9999M15.9999 20.9999V20.7999C15.9999 19.1198 15.9999 18.2797 16.3269 17.638C16.6145 17.0735 17.0734 16.6145 17.6379 16.3269C18.2796 15.9999 19.1197 15.9999 20.7999 15.9999H20.9999"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Minimize02Icon as React.FunctionComponent).displayName = "Minimize02Icon";

export default Minimize02Icon;
