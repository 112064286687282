import * as React from "react";
import { IconProps } from "./types";

export const WatchSquareIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17 18.5L16.6631 19.6791C16.4254 20.511 16.3066 20.927 16.0639 21.2358C15.8498 21.5083 15.5685 21.7205 15.2476 21.8515C14.8841 22 14.4515 22 13.5862 22H10.4138C9.54854 22 9.11592 22 8.75239 21.8515C8.43152 21.7205 8.15022 21.5083 7.93606 21.2358C7.69343 20.927 7.57458 20.511 7.33689 19.6791L7 18.5M17 5.5L16.6631 4.32089C16.4254 3.48896 16.3066 3.07298 16.0639 2.76423C15.8498 2.49171 15.5685 2.27953 15.2476 2.14847C14.8841 2 14.4515 2 13.5862 2H10.4138C9.54854 2 9.11592 2 8.75239 2.14847C8.43152 2.27953 8.15022 2.49171 7.93606 2.76423C7.69343 3.07299 7.57458 3.48895 7.33689 4.32089L7 5.5M12 9V12L13.5 13.5M8.7 18.5H15.3C16.4201 18.5 16.9802 18.5 17.408 18.282C17.7843 18.0903 18.0903 17.7843 18.282 17.408C18.5 16.9802 18.5 16.4201 18.5 15.3V8.7C18.5 7.57989 18.5 7.01984 18.282 6.59202C18.0903 6.21569 17.7843 5.90973 17.408 5.71799C16.9802 5.5 16.4201 5.5 15.3 5.5H8.7C7.5799 5.5 7.01984 5.5 6.59202 5.71799C6.21569 5.90973 5.90973 6.21569 5.71799 6.59202C5.5 7.01984 5.5 7.57989 5.5 8.7V15.3C5.5 16.4201 5.5 16.9802 5.71799 17.408C5.90973 17.7843 6.21569 18.0903 6.59202 18.282C7.01984 18.5 7.57989 18.5 8.7 18.5Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(WatchSquareIcon as React.FunctionComponent).displayName = "WatchSquareIcon";

export default WatchSquareIcon;
