import * as React from "react";
import { IconProps } from "./types";

export const Star02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.3064 3.20703C11.5339 2.81117 11.6476 2.61324 11.7963 2.54691C11.9259 2.48905 12.0741 2.48905 12.2037 2.54691C12.3524 2.61324 12.4661 2.81117 12.6936 3.20703L14.6488 6.60935C14.733 6.75589 14.7751 6.82916 14.8341 6.88241C14.8864 6.92952 14.948 6.96508 15.0149 6.98676C15.0905 7.01127 15.175 7.0111 15.344 7.01075L19.2681 7.00282C19.7247 7.00189 19.953 7.00143 20.0847 7.09701C20.1997 7.18038 20.2738 7.30868 20.2885 7.4499C20.3054 7.61181 20.1908 7.80928 19.9618 8.20421L17.9928 11.5986C17.908 11.7448 17.8656 11.8179 17.849 11.8956C17.8344 11.9644 17.8344 12.0356 17.849 12.1044C17.8656 12.1821 17.908 12.2552 17.9928 12.4014L19.9618 15.7958C20.1908 16.1907 20.3054 16.3882 20.2885 16.5501C20.2738 16.6913 20.1997 16.8196 20.0847 16.903C19.953 16.9986 19.7247 16.9981 19.2681 16.9972L15.344 16.9892C15.175 16.9889 15.0905 16.9887 15.0149 17.0132C14.948 17.0349 14.8864 17.0705 14.8341 17.1176C14.7751 17.1708 14.733 17.2441 14.6488 17.3906L12.6936 20.793C12.4661 21.1888 12.3524 21.3868 12.2037 21.4531C12.0741 21.5109 11.9259 21.5109 11.7963 21.4531C11.6476 21.3868 11.5339 21.1888 11.3064 20.793L9.35121 17.3906C9.267 17.2441 9.22489 17.1708 9.16585 17.1176C9.11362 17.0705 9.05203 17.0349 8.98511 17.0132C8.90948 16.9887 8.82497 16.9889 8.65596 16.9892L4.73188 16.9972C4.27532 16.9981 4.04704 16.9986 3.91525 16.903C3.80032 16.8196 3.72624 16.6913 3.71152 16.5501C3.69463 16.3882 3.80917 16.1907 4.03825 15.7958L6.00717 12.4014C6.09197 12.2552 6.13437 12.1821 6.15096 12.1044C6.16565 12.0356 6.16565 11.9644 6.15096 11.8956C6.13437 11.8179 6.09197 11.7448 6.00717 11.5986L4.03825 8.20421C3.80917 7.80928 3.69463 7.61181 3.71152 7.4499C3.72624 7.30868 3.80032 7.18038 3.91525 7.09701C4.04704 7.00143 4.27532 7.00189 4.73188 7.00282L8.65596 7.01075C8.82497 7.0111 8.90948 7.01127 8.98511 6.98676C9.05203 6.96508 9.11362 6.92952 9.16585 6.88241C9.22489 6.82916 9.267 6.75589 9.35121 6.60935L11.3064 3.20703Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Star02Icon as React.FunctionComponent).displayName = "Star02Icon";

export default Star02Icon;
