/**
 * @generated SignedSource<<5defe41171a85f4b0bec264714471db2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type SetupUserPageCheckQuery$variables = {
  username: string;
};
export type SetupUserPageCheckQuery$data = {
  readonly entityByUsername: {
    readonly id: string;
  } | null | undefined;
};
export type SetupUserPageCheckQuery = {
  response: SetupUserPageCheckQuery$data;
  variables: SetupUserPageCheckQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "username"
  }
],
v1 = [
  {
    "kind": "Variable",
    "name": "username",
    "variableName": "username"
  }
],
v2 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "SetupUserPageCheckQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "SetupUserPageCheckQuery",
    "selections": [
      {
        "alias": null,
        "args": (v1/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "entityByUsername",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v2/*: any*/)
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "48fb4b21f598b45e8bb0aa64cf219e7c",
    "id": null,
    "metadata": {},
    "name": "SetupUserPageCheckQuery",
    "operationKind": "query",
    "text": "query SetupUserPageCheckQuery(\n  $username: String!\n) {\n  entityByUsername(username: $username) {\n    __typename\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "b5597651a341e6756e429e285a13ece2";

export default node;
