import * as React from "react";
import { IconProps } from "./types";

export const RefreshCw04Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M17 5.1255C19.1213 6.67103 20.5 9.17456 20.5 12.0001C20.5 16.6945 16.6944 20.5001 12 20.5001H11.5M7 18.8747C4.87867 17.3292 3.5 14.8256 3.5 12.0001C3.5 7.30568 7.30558 3.5001 12 3.5001H12.5M13 22.4001L11 20.4001L13 18.4001M11 5.6001L13 3.6001L11 1.6001"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(RefreshCw04Icon as React.FunctionComponent).displayName = "RefreshCw04Icon";

export default RefreshCw04Icon;
