/**
 * @generated SignedSource<<77bb7cf68f2e5868647708166e5e48f2>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
export type EntityKind = "ORGANIZATION" | "USER";
import { FragmentRefs } from "relay-runtime";
export type BlogArticleHelmetFragment$data = {
  readonly authors: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly id: string;
        readonly kind: EntityKind;
        readonly username: string;
      };
    }>;
  };
  readonly image: any | null | undefined;
  readonly imageAlt: string | null | undefined;
  readonly seoDescription: string | null | undefined;
  readonly seoTitle: string | null | undefined;
  readonly shortDescription: string;
  readonly title: string;
  readonly topicHelmet: {
    readonly " $fragmentSpreads": FragmentRefs<"TopicHelmetFragment">;
  };
  readonly " $fragmentType": "BlogArticleHelmetFragment";
};
export type BlogArticleHelmetFragment$key = {
  readonly " $data"?: BlogArticleHelmetFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"BlogArticleHelmetFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "BlogArticleHelmetFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "title",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "shortDescription",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "image",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "imageAlt",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoTitle",
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "seoDescription",
      "storageKey": null
    },
    {
      "alias": "topicHelmet",
      "args": null,
      "concreteType": "Topic",
      "kind": "LinkedField",
      "name": "topic",
      "plural": false,
      "selections": [
        {
          "args": null,
          "kind": "FragmentSpread",
          "name": "TopicHelmetFragment"
        }
      ],
      "storageKey": null
    },
    {
      "alias": null,
      "args": null,
      "concreteType": "BlogArticleAuthorConnection",
      "kind": "LinkedField",
      "name": "authors",
      "plural": false,
      "selections": [
        {
          "alias": null,
          "args": null,
          "concreteType": "EntityEdge",
          "kind": "LinkedField",
          "name": "edges",
          "plural": true,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": null,
              "kind": "LinkedField",
              "name": "node",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "id",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "kind",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "username",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ],
      "storageKey": null
    }
  ],
  "type": "BlogArticle",
  "abstractKey": null
};

(node as any).hash = "bc86ac5cd3f8844ffa4360ac8ce4f0bc";

export default node;
