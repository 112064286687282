import { useMemo, useEffect } from "react";
import { graphql, useRelayEnvironment, useMutation } from "react-relay";
import { useLocation as useRouterLocation } from "react-router-dom";
import { fetchQuery } from "relay-runtime";
import { useForm } from "react-hook-form";
import { useIntl, FormattedMessage } from "react-intl";
import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { logger } from "@/common/logger";
import { useFileReadAsync, useFetchFile } from "@/utils/hooks";
import {
  toAbortablePromise,
  extractUploadables,
  relayErrorMessage,
} from "@/utils/relay";
import {
  useAsyncCheck,
  isNotBannedUsername,
  imgDimensionsWithin,
  imageUpload,
} from "@/utils/validation";
import { useOnAuthCallback } from "@/utils/auth";
import { toast } from "@/utils/toast";

import {
  Form,
  FormControl,
  FormDescription,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
  OptionalLabel,
} from "@/kit/ui/form";
import { Checkbox } from "@/kit/ui/checkbox";
import { Input } from "@/kit/ui/input";
import { FileInput, FileInputButton } from "@/kit/ui/file-input";
import { Button } from "@/kit/ui/button";
import { Textarea } from "@/kit/ui/textarea";
import { CheckCircleIcon, Loading01Icon, User01Icon } from "@/icons";

import { MetaLayout } from "@/common/MetaLayout";
import {
  AuthHeader,
  AuthHeaderTitle,
  AuthHeaderIcon,
  AuthHeaderDescription,
  AuthContent,
} from "@/components/AuthLayout";

import { SetupUserPageCheckQuery as SetupUserPageCheckQueryType } from "./__generated__/SetupUserPageCheckQuery.graphql";
import { SetupUserPageMutation as SetupUserPageMutationType } from "./__generated__/SetupUserPageMutation.graphql";
import { Oauth2CallbackPageMutation$data } from "./__generated__/Oauth2CallbackPageMutation.graphql";

const SetupUserPageMutation = graphql`
  mutation SetupUserPageMutation($input: SignupUserInput!) {
    signupUser(input: $input) {
      node {
        id
      }
    }
  }
`;

const SetupUserPageCheckQuery = graphql`
  query SetupUserPageCheckQuery($username: String!) {
    entityByUsername(username: $username) {
      id
    }
  }
`;

const ProfilePreview = ({
  image,
  onError,
}: {
  image?: File;
  onError: (error: string) => void;
}) => {
  const intl = useIntl();
  const { dataURL, error, isLoading } = useFileReadAsync(image);

  useEffect(() => {
    if (error) {
      onError(
        intl.formatMessage({
          defaultMessage: "Could not read profile picture",
        }),
      );
    }
  }, [error, onError, intl]);
  useEffect(() => {
    if (dataURL) {
      toast.success(
        intl.formatMessage({ defaultMessage: "Profile picture loaded" }),
      );
    }
  }, [dataURL, intl]);

  return dataURL ? (
    <img
      className="w-full h-full rounded-full aspect-square object-cover"
      src={dataURL}
    />
  ) : (
    <div className="w-full h-full flex items-center justify-center rounded-full bg-gradient-to-b from-[#F8F7FC] to-[#E8E4FF]">
      {isLoading ? (
        <Loading01Icon className="size-7 animate-spin" />
      ) : (
        <User01Icon className="size-7" />
      )}
    </div>
  );
};

export default function SetupUserPage() {
  const intl = useIntl();
  const onAuthenticated = useOnAuthCallback();

  const [commitMutation, isMutationInFlight] =
    useMutation<SetupUserPageMutationType>(SetupUserPageMutation);

  const { state } = useRouterLocation();
  const signup = state?.signup;
  const userInfo =
    state?.userInfo as Oauth2CallbackPageMutation$data["checkOauth2Auth"]["userInfo"];

  const relayEnvironment = useRelayEnvironment();
  const usernameNotTaken = useAsyncCheck<string>(
    async (username: string, { signal }) => {
      const query = await toAbortablePromise(
        fetchQuery<SetupUserPageCheckQueryType>(
          relayEnvironment,
          SetupUserPageCheckQuery,
          { username },
          { fetchPolicy: "store-or-network" },
        ),
        signal,
      );
      return !query.entityByUsername;
    },
    { debounce: 500 },
  );

  const formSchema = useMemo(
    () =>
      v.objectAsync({
        username: v.pipeAsync(
          v.string(),
          v.minLength(
            3,
            intl.formatMessage({
              defaultMessage: "Username must be at least 3 characters",
            }),
          ),
          v.maxLength(
            20,
            intl.formatMessage({
              defaultMessage: "Username must be at most 20 characters",
            }),
          ),
          v.regex(
            /^[a-zA-Z0-9_]+$/,
            intl.formatMessage({
              defaultMessage:
                "Username must contain only letters, numbers, and underscores",
            }),
          ),
          v.check(
            isNotBannedUsername([]),
            intl.formatMessage({
              defaultMessage: "Username is not allowed",
            }),
          ),
          v.checkAsync(
            usernameNotTaken,
            intl.formatMessage({
              defaultMessage: "Username taken",
            }),
          ),
        ),
        displayName: v.string(),
        bio: v.pipe(
          v.string(),
          v.transform((bio) => (bio?.trim() ? bio : undefined)),
        ),
        subscribePromotionalNewsletter: v.boolean(),
        image: v.optionalAsync(
          v.pipeAsync(
            imageUpload(intl),
            v.checkAsync(
              imgDimensionsWithin({ minWidth: 200, minHeight: 200 }),
              intl.formatMessage({
                defaultMessage: "File must be at least 200x200",
              }),
            ),
          ),
        ),
      }),
    [intl, usernameNotTaken],
  );

  const files = { image: useFetchFile(userInfo?.avatarUrl) };

  const form = useForm<v.InferOutput<typeof formSchema>>({
    mode: "onTouched",
    values: {
      username: userInfo?.username || "",
      displayName: userInfo?.name || "",
      bio: userInfo?.bio || "",
      image: files.image.file,
      subscribePromotionalNewsletter: false,
    },
    resolver: valibotResolver(formSchema),
  });

  const onSubmit = (data: v.InferOutput<typeof formSchema>) => {
    const { variables, uploadables } = extractUploadables({
      input: {
        ...data,
        signup,
      },
    });
    commitMutation({
      variables,
      uploadables,
      onError(error) {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  };

  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage:
          "Let's get to know you - Join the Global Quantum Community",
      })}
    >
      <AuthHeader>
        <AuthHeaderIcon className="w-16 h-16">
          <ProfilePreview
            image={form.watch("image")}
            onError={(message) => {
              toast.error(message);
              form.setValue("image", undefined);
            }}
          />
        </AuthHeaderIcon>
        <AuthHeaderTitle>
          <FormattedMessage defaultMessage="Welcome to Aqora" />
        </AuthHeaderTitle>
        <AuthHeaderDescription>
          <FormattedMessage defaultMessage="Join the community of quantum innovators" />
        </AuthHeaderDescription>
      </AuthHeader>
      <AuthContent>
        <Form form={form} onSubmit={onSubmit}>
          <div className="space-y-8">
            <div className="space-y-5">
              <FormField
                control={form.control}
                name="username"
                render={({ field, fieldState }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage defaultMessage="Username" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        autoFocus
                        placeholder={intl.formatMessage({
                          defaultMessage: "Your unique username",
                        })}
                        endDecorator={
                          fieldState.isTouched &&
                          (fieldState.isValidating ? (
                            <Loading01Icon className="text-grey-300 animate-spin" />
                          ) : (
                            !fieldState.invalid && (
                              <CheckCircleIcon className="text-success-500" />
                            )
                          ))
                        }
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="displayName"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage defaultMessage="Full name" />
                    </FormLabel>
                    <FormControl>
                      <Input
                        placeholder={intl.formatMessage({
                          defaultMessage: "The name on your profile",
                        })}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="image"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage defaultMessage="Profile picture" />
                      <OptionalLabel />
                    </FormLabel>
                    <FormControl>
                      <FileInput
                        disabled={files.image.isLoading}
                        accept="image/png,image/jpeg"
                        className="w-full"
                        {...field}
                      >
                        <FileInputButton className="w-full" />
                      </FileInput>
                    </FormControl>
                    <FormDescription>
                      <FormattedMessage defaultMessage=".png, .jpg file up to 20MB at least 200x200 pixels " />
                    </FormDescription>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="bio"
                render={({ field }) => (
                  <FormItem>
                    <FormLabel>
                      <FormattedMessage defaultMessage="Short description" />
                      <OptionalLabel />
                    </FormLabel>
                    <FormControl>
                      <Textarea
                        autoResize
                        placeholder={intl.formatMessage({
                          defaultMessage:
                            "Enter a short description about yourself...",
                        })}
                        {...field}
                      />
                    </FormControl>
                    <FormMessage />
                  </FormItem>
                )}
              />
              <FormField
                control={form.control}
                name="subscribePromotionalNewsletter"
                render={({ field }) => (
                  <FormItem checkbox>
                    <FormControl>
                      <Checkbox {...field} />
                    </FormControl>
                    <div>
                      <FormLabel>
                        <FormattedMessage defaultMessage="Weekly newsletter" />
                        <OptionalLabel />
                      </FormLabel>
                      <FormDescription>
                        <FormattedMessage defaultMessage="I want to stay updated with weekly highlights of Aqora competitions, quantum news, and community insights from your platform." />
                      </FormDescription>
                      <FormMessage />
                    </div>
                  </FormItem>
                )}
              />
            </div>
            <Button
              className="w-full"
              type="submit"
              disabled={isMutationInFlight}
            >
              <FormattedMessage defaultMessage="Create Account" />
            </Button>
          </div>
        </Form>
      </AuthContent>
    </MetaLayout>
  );
}
