import * as React from "react";
import { IconProps } from "./types";

export const ChevronRightDoubleIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M6 17L11 12L6 7M13 17L18 12L13 7"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ChevronRightDoubleIcon as React.FunctionComponent).displayName =
  "ChevronRightDoubleIcon";

export default ChevronRightDoubleIcon;
