import * as React from "react";
import { cva } from "class-variance-authority";

enum InputDecoratorType {
  None,
  Separated,
  Integrated,
}

type InputContextValue = {
  type: string;
  setType: (type: string) => void;
  isError: boolean;
  isDisabled: boolean;
  isFocused: boolean;
  startDecoratorType: InputDecoratorType;
  endDecoratorType: InputDecoratorType;
};

const InputContext = React.createContext<InputContextValue>({
  type: "text",
  setType: () => {},
  isError: false,
  isDisabled: false,
  isFocused: false,
  startDecoratorType: InputDecoratorType.None,
  endDecoratorType: InputDecoratorType.None,
});

const inputInputVariants = cva(
  "box-border w-full py-2 bg-transparent outline-none border-t border-b border-grey-300 placeholder:text-grey-500",
  {
    variants: {
      disabled: {
        false: null,
        true: "bg-grey-50 text-grey-400 border-grey-200 cursor-not-allowed pointer-events-none",
      },
      focused: {
        false: null,
        true: "border-t-2 border-b-2 py-[7px]",
      },
      invalid: {
        false: null,
        true: "border-error-500",
      },
      startDecorator: {
        [InputDecoratorType.None]: "border-l rounded-l-lg pl-3.5",
        [InputDecoratorType.Integrated]: "pl-2",
        [InputDecoratorType.Separated]: "border-l pl-3.5",
      },
      endDecorator: {
        [InputDecoratorType.None]: "border-r rounded-r-lg pr-3.5",
        [InputDecoratorType.Integrated]: "pr-2",
        [InputDecoratorType.Separated]: "border-r pr-3.5",
      },
    },
    compoundVariants: [
      {
        focused: true,
        invalid: false,
        class: "border-brand-500",
      },
      {
        focused: true,
        startDecorator: InputDecoratorType.None,
        class: "border-l-2 pl-[13px]",
      },
      {
        focused: true,
        endDecorator: InputDecoratorType.None,
        class: "border-r-2 pr-[13px]",
      },
      {
        focused: true,
        startDecorator: InputDecoratorType.Separated,
        class: "border-l-2 pl-[13px]",
      },
      {
        focused: true,
        endDecorator: InputDecoratorType.Separated,
        class: "border-r-2 pr-[13px]",
      },
    ],
    defaultVariants: {
      startDecorator: InputDecoratorType.None,
      endDecorator: InputDecoratorType.None,
      focused: false,
      invalid: false,
      disabled: false,
    },
  },
);

const inputDecoratorVariants = cva(
  "flex items-center  border-t border-b border-grey-300 text-grey-600",
  {
    variants: {
      disabled: {
        false: null,
        true: "bg-grey-50 text-grey-400 border-grey-200",
      },
      focused: {
        false: null,
        true: null,
      },
      invalid: {
        false: null,
        true: null,
      },
      separated: {
        false: null,
        true: "px-3.5",
      },
      position: {
        start: "border-l rounded-l-lg pl-3.5",
        end: "border-r rounded-r-lg pr-3.5",
      },
    },
    compoundVariants: [
      {
        focused: true,
        separated: false,
        class: "border-t-2 border-b-2",
      },
      {
        focused: true,
        invalid: false,
        separated: false,
        class: "border-brand-500",
      },
      {
        invalid: true,
        separated: false,
        class: "border-error-500",
      },
      {
        focused: true,
        position: "start",
        separated: false,
        class: "border-l-2 pl-[13px]",
      },
      {
        focused: true,
        position: "end",
        separated: false,
        class: "border-r-2 pr-[13px]",
      },
    ],
    defaultVariants: {
      disabled: false,
      focused: false,
      invalid: false,
      separated: false,
    },
  },
);

export {
  InputContext,
  InputDecoratorType,
  inputInputVariants,
  inputDecoratorVariants,
};
