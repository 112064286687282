import { useState, useEffect, useCallback, useMemo } from "react";
import { graphql, useMutation } from "react-relay";
import { useIntl, FormattedMessage } from "react-intl";
import { useNavigate, useLocation, useSearchParams } from "react-router-dom";
import { useForm } from "react-hook-form";
import { toast } from "sonner";
import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { useVerifyEmailSignupPageQuery } from "./loaders/VerifyEmailSignupPage";

import ErrorPage from "./ErrorPage";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/kit/ui/form";
import {
  InputOTP,
  InputOTPGroup,
  InputOTPSlot,
  InputOTPSeparator,
} from "@/kit/ui/input-otp";
import { Button } from "@/kit/ui/button";
import { TimeAgo } from "@/components/TimeAgo";
import { MetaLayout } from "@/common/MetaLayout";
import {
  AuthHeader,
  AuthHeaderTitle,
  AuthHeaderIcon,
  AuthHeaderIconBadge,
  AuthHeaderDescription,
  AuthContent,
} from "@/components/AuthLayout";
import { Mail01Icon } from "@/icons";

import { relayErrorMessage } from "../utils/relay";

import { VerifyEmailSignupPageCheckMutation as VerifyEmailSignupPageCheckMutationType } from "./__generated__/VerifyEmailSignupPageCheckMutation.graphql";
import { VerifyEmailSignupPageResendMutation as VerifyEmailSignupPageResendMutationType } from "./__generated__/VerifyEmailSignupPageResendMutation.graphql";

const VerifyEmailSignupPageCheckMutation = graphql`
  mutation VerifyEmailSignupPageCheckMutation(
    $input: EmailSignupVerficationInput!
  ) {
    checkEmailSignupVerification(input: $input) {
      id
      emailCanResendAt
    }
  }
`;

const VerifyEmailSignupPageResendMutation = graphql`
  mutation VerifyEmailSignupPageResendMutation($id: ID!) {
    resendEmailSignupVerification(id: $id) {
      id
      emailCanResendAt
    }
  }
`;

interface ResendButtonProps {
  id: string;
  emailCanResendAt: string;
}

const ResendButton = ({ id, emailCanResendAt }: ResendButtonProps) => {
  const intl = useIntl();
  const [commitResendMutation, isResendMutationInFlight] =
    useMutation<VerifyEmailSignupPageResendMutationType>(
      VerifyEmailSignupPageResendMutation,
    );

  const [currentTime, setCurrentTime] = useState(new Date());
  useEffect(() => {
    const interval = setInterval(() => {
      setCurrentTime(new Date());
    }, 500);
    return () => clearInterval(interval);
  });

  const onResend = () => {
    if (!canResend) {
      return;
    }
    commitResendMutation({
      variables: { id },
      onCompleted() {
        toast.success(intl.formatMessage({ defaultMessage: "Email resent" }));
      },
      onError(error) {
        toast.error(
          intl.formatMessage(
            { defaultMessage: "Failed to resend email: {message}" },
            { message: relayErrorMessage(error) },
          ),
        );
      },
    });
  };

  const resendTime = new Date(emailCanResendAt);
  const canResend = currentTime >= resendTime;

  return canResend ? (
    <Button
      className="w-full"
      variant="secondary"
      disabled={isResendMutationInFlight}
      onClick={onResend}
    >
      <FormattedMessage defaultMessage="Resend Email" />
    </Button>
  ) : (
    <Button
      suppressHydrationWarning
      className="w-full"
      variant="tertiary"
      disabled
    >
      <FormattedMessage
        defaultMessage="Can resend email {time}"
        values={{
          time: <TimeAgo createdAt={resendTime} />,
        }}
      />
    </Button>
  );
};

export default function VerifyEmailSignupPage() {
  const intl = useIntl();
  const navigate = useNavigate();

  const { state } = useLocation();
  const email = state?.email;

  const {
    query: {
      node: { id, emailCanResendAt },
    },
  } = useVerifyEmailSignupPageQuery();

  const [searchParams] = useSearchParams();
  const token = searchParams.get("token");

  const formSchema = useMemo(
    () =>
      v.object({
        token: v.pipe(
          v.string(),
          v.regex(
            /^\d{6}$/,
            intl.formatMessage({
              defaultMessage: "Must be 6 numbers",
            }),
          ),
        ),
      }),
    [intl],
  );

  const form = useForm<v.InferOutput<typeof formSchema>>({
    resolver: valibotResolver(formSchema),
    defaultValues: {
      token: token || "",
    },
  });

  const [commitCheckMutation, isCheckMutationInFlight] =
    useMutation<VerifyEmailSignupPageCheckMutationType>(
      VerifyEmailSignupPageCheckMutation,
    );

  const onSubmit = useCallback(
    (data: v.InferOutput<typeof formSchema>) => {
      const input = { id, token: data.token };
      commitCheckMutation({
        variables: { input },
        onCompleted() {
          navigate("/setup-user", {
            state: { signup: { email: input } },
          });
        },
        onError(error) {
          toast.error(
            intl.formatMessage(
              { defaultMessage: "Could not validate: {message}" },
              { message: relayErrorMessage(error) },
            ),
          );
        },
      });
    },
    [commitCheckMutation, id, intl, navigate],
  );

  useEffect(() => {
    if (form.formState.isValid) {
      onSubmit(form.getValues());
    }
  }, [form.formState.isValid, form.getValues, form, onSubmit]);

  if (!id || !emailCanResendAt) {
    return (
      <ErrorPage status={404}>
        <FormattedMessage defaultMessage="Email verification not found or expired." />
      </ErrorPage>
    );
  }

  return (
    <MetaLayout
      metaTitle={intl.formatMessage({
        defaultMessage: "Verify your email - Join the Global Quantum Community",
      })}
    >
      <AuthHeader>
        <AuthHeaderIcon>
          <AuthHeaderIconBadge>
            <Mail01Icon />
          </AuthHeaderIconBadge>
        </AuthHeaderIcon>
        <AuthHeaderTitle>
          <FormattedMessage defaultMessage="Verify your email" />
        </AuthHeaderTitle>
        <AuthHeaderDescription>
          <FormattedMessage
            defaultMessage={`We've sent an email with a 6 digit code{email, select,
            undefined {}
            null {}
            other { to {email}}
          }. Please enter it below.`}
            values={{ email: email ? <b>{email}</b> : null }}
          />
        </AuthHeaderDescription>
      </AuthHeader>
      <AuthContent>
        <Form form={form} onSubmit={onSubmit}>
          <div className="space-y-8">
            <FormField
              control={form.control}
              name="token"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormattedMessage defaultMessage="Verification Code" />
                  </FormLabel>
                  <FormControl>
                    <InputOTP
                      maxLength={6}
                      disabled={isCheckMutationInFlight}
                      pasteTransformer={(val) => val.replace(/\D/g, "")}
                      {...field}
                    >
                      <InputOTPGroup>
                        <InputOTPSlot index={0} />
                        <InputOTPSlot index={1} />
                        <InputOTPSlot index={2} />
                      </InputOTPGroup>
                      <InputOTPSeparator />
                      <InputOTPGroup>
                        <InputOTPSlot index={3} />
                        <InputOTPSlot index={4} />
                        <InputOTPSlot index={5} />
                      </InputOTPGroup>
                    </InputOTP>
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <div className="space-y-4">
              <Button
                className="w-full"
                type="submit"
                disabled={!form.formState.isValid || isCheckMutationInFlight}
              >
                <FormattedMessage defaultMessage="Verify" />
              </Button>
              <ResendButton id={id} emailCanResendAt={emailCanResendAt} />
            </div>
          </div>
        </Form>
      </AuthContent>
    </MetaLayout>
  );
}
