import { useMemo } from "react";
import { graphql, useMutation } from "react-relay";
import { useForm } from "react-hook-form";
import { useParams } from "react-router-dom";
import { useIntl, FormattedMessage } from "react-intl";
import { ResetPasswordPageMutation as ResetPasswordPageMutationType } from "./__generated__/ResetPasswordPageMutation.graphql";
import Helmet from "@/components/Helmet";

import {
  AuthHeader,
  AuthHeaderTitle,
  AuthHeaderIcon,
  AuthHeaderDescription,
  AuthHeaderIconBadge,
  AuthContent,
} from "@/components/AuthLayout";
import {
  Form,
  FormControl,
  FormField,
  FormItem,
  FormLabel,
  FormMessage,
} from "@/kit/ui/form";
import { Link } from "@/kit/ui/link";
import { Button } from "@/kit/ui/button";
import { Input } from "@/kit/ui/input";
import { PasswordToggleIcon } from "@/kit/ui/password-toggle";
import { Lock01Icon, Key01Icon, ArrowLeftIcon } from "@/icons";

import { useOnAuthCallback } from "@/utils/auth";
import * as v from "valibot";
import { valibotResolver } from "@hookform/resolvers/valibot";

import { toast } from "@/utils/toast";
import { logger } from "@/common/logger";
import { relayErrorMessage } from "@/utils/relay";

const ResetPasswordPageMutation = graphql`
  mutation ResetPasswordPageMutation($input: ResetPasswordInput!) {
    resetPassword(input: $input)
  }
`;

export default function ResetPasswordPage() {
  const intl = useIntl();
  const { secret } = useParams();
  const [commitMutation, isMutationInFlight] =
    useMutation<ResetPasswordPageMutationType>(ResetPasswordPageMutation);
  const onAuthenticated = useOnAuthCallback();
  const formSchema = useMemo(
    () =>
      v.object({
        password: v.pipe(
          v.string(),
          v.minLength(
            6,
            intl.formatMessage({
              defaultMessage: "Password must be at least 6 characters",
            }),
          ),
        ),
      }),
    [intl],
  );
  const form = useForm<v.InferOutput<typeof formSchema>>({
    defaultValues: {
      password: "",
    },
    resolver: valibotResolver(formSchema),
  });
  const onSubmit = (data: v.InferOutput<typeof formSchema>) => {
    commitMutation({
      variables: {
        input: {
          ...data,
          secret: secret || "",
        },
      },
      onError(error) {
        logger.error(error);
        toast.error(relayErrorMessage(error));
      },
      onCompleted() {
        onAuthenticated();
      },
    });
  };
  return (
    <>
      <Helmet meta={[{ name: "robots", content: "noindex, nofollow" }]} />
      <AuthHeader>
        <AuthHeaderIcon>
          <AuthHeaderIconBadge>
            <Key01Icon />
          </AuthHeaderIconBadge>
        </AuthHeaderIcon>
        <AuthHeaderTitle>
          <FormattedMessage defaultMessage="Set a new password" />
        </AuthHeaderTitle>
        <AuthHeaderDescription>
          <FormattedMessage defaultMessage="Choose a new password and login" />
        </AuthHeaderDescription>
      </AuthHeader>
      <AuthContent>
        <Form form={form} onSubmit={onSubmit}>
          <div className="space-y-8">
            <FormField
              control={form.control}
              name="password"
              render={({ field }) => (
                <FormItem>
                  <FormLabel>
                    <FormattedMessage defaultMessage="Password" />
                  </FormLabel>
                  <FormControl>
                    <Input
                      startDecorator={<Lock01Icon />}
                      endDecorator={<PasswordToggleIcon />}
                      type="password"
                      {...field}
                    />
                  </FormControl>
                  <FormMessage />
                </FormItem>
              )}
            />
            <Button
              className="w-full"
              type="submit"
              disabled={isMutationInFlight}
            >
              <FormattedMessage defaultMessage="Reset password" />
            </Button>
            <Link to="/login" hue="grey" className="w-full" type="submit">
              <ArrowLeftIcon />
              <FormattedMessage defaultMessage="Back to login" />
            </Link>
          </div>
        </Form>
      </AuthContent>
    </>
  );
}
