import * as React from "react";
import { IconProps } from "./types";

export const ArrowCircleBrokenDownLeftIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M9.00017 9.00001V15M9.00017 15H15.0002M9.00017 15L19 4.99995M21.6606 9.41045C22.5515 12.7466 21.6883 16.4538 19.071 19.0711C15.1658 22.9763 8.83412 22.9763 4.92887 19.0711C1.02363 15.1658 1.02363 8.83418 4.92887 4.92893C7.54616 2.31164 11.2533 1.44846 14.5895 2.33938"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ArrowCircleBrokenDownLeftIcon as React.FunctionComponent).displayName =
  "ArrowCircleBrokenDownLeftIcon";

export default ArrowCircleBrokenDownLeftIcon;
