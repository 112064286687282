import { graphql, Environment } from "react-relay";
import { LoaderArgs } from "../../utils/route";
import { CompetitionLeaderboardPageQuery as CompetitionLeaderboardPageQueryType } from "./__generated__/CompetitionLeaderboardPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { CompetitionLeaderboardPageQuery as CompetitionLeaderboardPageQueryType } from "./__generated__/CompetitionLeaderboardPageQuery.graphql";

export const CompetitionLeaderboardPageQuery = graphql`
  query CompetitionLeaderboardPageQuery($slug: String!) {
    viewer @ifAllowed {
      ...CompetitionEntitySubmissionStatusEntityFragment
    }
    competitionBySlug(slug: $slug) {
      id
      title
      ...CompetitionLeaderboardFragment
      ...CompetitionEntitySubmissionStatusCompetitionFragment
      ...SubjectSubscriptionButtonFragment
    }
  }
`;

export const competitionLeaderboardPageQueryLoader = (
  environment: Environment,
) => {
  return ({ params: { slug } }: LoaderArgs) => {
    if (!slug) {
      throw new Response("No slug", { status: 500 });
    }
    return preload<CompetitionLeaderboardPageQueryType>(
      environment,
      CompetitionLeaderboardPageQuery,
      { slug },
    );
  };
};

export const useCompetitionLeaderboardPageQuery = () =>
  usePreloaded<CompetitionLeaderboardPageQueryType>();
