import * as React from "react";
import { IconProps } from "./types";

export const Link03Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M9.99996 13C10.4294 13.5742 10.9773 14.0492 11.6065 14.393C12.2357 14.7367 12.9315 14.9411 13.6466 14.9924C14.3617 15.0436 15.0795 14.9404 15.7513 14.6898C16.4231 14.4392 17.0331 14.0471 17.54 13.54L20.54 10.54C21.4507 9.59702 21.9547 8.334 21.9433 7.02302C21.9319 5.71204 21.4061 4.45797 20.479 3.53093C19.552 2.60389 18.2979 2.07805 16.987 2.06666C15.676 2.05526 14.413 2.55924 13.47 3.47003L11.75 5.18003M14 11C13.5705 10.4259 13.0226 9.95084 12.3934 9.60709C11.7642 9.26333 11.0684 9.05891 10.3533 9.00769C9.63816 8.95648 8.92037 9.05966 8.24861 9.31025C7.57685 9.56083 6.96684 9.95296 6.45996 10.46L3.45996 13.46C2.54917 14.403 2.04519 15.666 2.05659 16.977C2.06798 18.288 2.59382 19.5421 3.52086 20.4691C4.4479 21.3962 5.70197 21.922 7.01295 21.9334C8.32393 21.9448 9.58694 21.4408 10.53 20.53L12.24 18.82"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Link03Icon as React.FunctionComponent).displayName = "Link03Icon";

export default Link03Icon;
