import * as v from "valibot";

const schema = v.object({
  requiresApproval: v.boolean(),
  grantHostSubmissionAccess: v.boolean(),
  hasLeaderboard: v.boolean(),
  noCode: v.boolean(),
});

export type Schema = v.InferOutput<typeof schema>;

export { schema };
