import { graphql, Environment } from "react-relay";
import { SignupPageQuery as SignupPageQueryType } from "./__generated__/SignupPageQuery.graphql";
import { preload, usePreloaded } from "../../utils/relay";

export type { SignupPageQuery as SignupPageQueryType } from "./__generated__/SignupPageQuery.graphql";

export const SignupPageQuery = graphql`
  query SignupPageQuery {
    ...Oauth2LoginButtonsFragment
  }
`;

export const signupPageQueryLoader = (environment: Environment) => {
  return () => preload<SignupPageQueryType>(environment, SignupPageQuery);
};

export const useSignupPageQuery = () => usePreloaded<SignupPageQueryType>();
