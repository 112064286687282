import { graphql, useFragment } from "react-relay";
import { EntityAvatarFragment$key } from "./__generated__/EntityAvatarFragment.graphql";
import EntityProfilePic from "./EntityProfilePic";

import { Skeleton } from "./Skeleton";
import { type ReactNode } from "react";
import { Link } from "react-router-dom";

const EntityAvatarFragment = graphql`
  fragment EntityAvatarFragment on Entity {
    username
    ...EntityProfilePicFragment @arguments(thumbnail: true)
  }
`;

export interface Props {
  entity: EntityAvatarFragment$key;
  label?: ReactNode | null;
}

export function EntityAvatar({ entity: entityFragment, label }: Props) {
  const entity = useFragment(EntityAvatarFragment, entityFragment);
  return (
    <Link
      to={`/${entity.username}`}
      className="flex items-center gap-2 cursor-pointer"
    >
      <div className="rounded-full overflow-hidden w-10 h-10 border border-gray-300/75">
        <EntityProfilePic entity={entity} size="10" />,
      </div>
      <div className="flex flex-col">
        <span className="text-sm text-gray-900 font-semibold leading-tight">
          {entity.username}
        </span>
        {label && (
          <span className="text-sm font-normal leading-tight text-gray-600 break-all">
            {label}
          </span>
        )}
      </div>
    </Link>
  );
}

function EntityAvatarSkeleton() {
  return (
    <div className="flex items-center gap-2">
      <Skeleton className="rounded-full w-10 h-10" />
      <div className="flex flex-col">
        <Skeleton className="h-4 w-24 mb-1" />
        <Skeleton className="h-3 w-20" />
      </div>
    </div>
  );
}

EntityAvatar.Skeleton = EntityAvatarSkeleton;
export default EntityAvatar;
