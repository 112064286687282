import * as React from "react";
import { toast } from "sonner";
import { Toast } from "@/kit/ui/toast";

export type ToastLevel = "info" | "success" | "warning" | "error";

export interface ToastOptions {
  title?: React.ReactNode;
  message: React.ReactNode;
  level: ToastLevel;
  dismissible?: boolean;
  duration?: number;
  button?: {
    children: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
}

export interface ToastProps {
  id: string | number;
  title?: React.ReactNode;
  message: React.ReactNode;
  level: ToastLevel;
  dismissible: boolean;
  duration: number;
  button?: {
    children: React.ReactNode;
    onClick: React.MouseEventHandler<HTMLButtonElement>;
  };
}

const createToast = ({
  dismissible = true,
  duration = 4000,
  ...props
}: ToastOptions) => {
  return toast.custom(
    (id) => {
      return Toast({ id, dismissible, duration, ...props });
    },
    { dismissible, duration },
  );
};

const basicToast = (
  message: string | React.ReactNode,
  options?: Omit<ToastOptions, "message">,
) => {
  const level = options?.level || "info";
  createToast({ message, level, ...options });
};

const infoToast = (
  message: string | React.ReactNode,
  options?: Omit<ToastOptions, "level" | "message">,
) => {
  createToast({ message, level: "info", ...options });
};

const successToast = (
  message: string | React.ReactNode,
  options?: Omit<ToastOptions, "level" | "message">,
) => {
  createToast({ message, level: "success", ...options });
};

const warningToast = (
  message: string | React.ReactNode,
  options?: Omit<ToastOptions, "level" | "message">,
) => {
  createToast({ message, level: "warning", ...options });
};

const errorToast = (
  message: string | React.ReactNode,
  options?: Omit<ToastOptions, "level" | "message">,
) => {
  createToast({ message, level: "error", ...options });
};

const toastUtil = Object.assign(basicToast, {
  info: infoToast,
  success: successToast,
  warning: warningToast,
  error: errorToast,
});

export { toastUtil as toast };
