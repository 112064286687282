import * as v from "valibot";
import { IntlShape, defineMessage } from "react-intl";
import { imageUpload } from "@/utils/validation";

const visibilityOptions = [
  {
    message: defineMessage({ defaultMessage: "Public" }),
    value: "UNAUTHENTICATED",
  },
  {
    message: defineMessage({ defaultMessage: "Connected users" }),
    value: "AUTHENTICATED",
  },
  {
    message: defineMessage({ defaultMessage: "Invited users only" }),
    value: "MEMBERS",
  },
] as const;

const schemaFactory = (intl: IntlShape) =>
  v.object({
    title: v.pipe(
      v.string(),
      v.nonEmpty(
        intl.formatMessage({
          defaultMessage: "Title is required",
        }),
      ),
      v.maxLength(
        50,
        intl.formatMessage({
          defaultMessage: "Title cannot be longer than 50 characters",
        }),
      ),
    ),
    shortDescription: v.pipe(
      v.string(),
      v.nonEmpty(
        intl.formatMessage({
          defaultMessage: "Description is required",
        }),
      ),
      v.maxLength(
        255,
        intl.formatMessage({
          defaultMessage: "Description cannot be longer than 255 characters",
        }),
      ),
    ),
    slug: v.pipe(
      v.string(),
      v.nonEmpty(
        intl.formatMessage({
          defaultMessage: "URL is required",
        }),
      ),
      v.maxLength(
        35,
        intl.formatMessage({
          defaultMessage: "URL cannot be longer than 35 characters",
        }),
      ),
      v.regex(
        /^[-a-zA-Z0-9_]*$/,
        intl.formatMessage({
          defaultMessage: "URL is invalid",
        }),
      ),
    ),
    visibility: v.picklist(
      visibilityOptions.map((option) => option.value),
      intl.formatMessage({
        defaultMessage: "Visibility is required",
      }),
    ),
    banner: v.optional(imageUpload(intl)),
    thumbnail: v.optional(imageUpload(intl)),
  });

export type Schema = v.InferOutput<ReturnType<typeof schemaFactory>>;

export { schemaFactory, visibilityOptions };
