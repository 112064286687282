import * as React from "react";
import { IconProps } from "./types";

export const NavigationPointerOff01Icon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M11.337 6.16115L20.3003 2.66327C20.8375 2.45364 21.1061 2.34883 21.2777 2.40616C21.4268 2.45596 21.5437 2.57292 21.5935 2.72197C21.6509 2.8936 21.5461 3.16219 21.3364 3.69937L17.8274 12.6913M16.3857 16.3857L14.7461 20.5871C14.5139 21.1822 14.3977 21.4797 14.2308 21.5664C14.0862 21.6416 13.9139 21.6415 13.7693 21.5662C13.6025 21.4793 13.4867 21.1816 13.2552 20.5862L10.6271 13.8282C10.5801 13.7074 10.5566 13.647 10.5203 13.5961C10.4881 13.551 10.4487 13.5115 10.4036 13.4794C10.3527 13.4431 10.2923 13.4196 10.1715 13.3726L3.41345 10.7445C2.81811 10.513 2.52043 10.3972 2.43353 10.2304C2.35819 10.0858 2.35809 9.91354 2.43326 9.76885C2.51997 9.60195 2.8175 9.48584 3.41258 9.25361L7.61748 7.61268M20.9995 21.0002L2.99952 3.00017"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(NavigationPointerOff01Icon as React.FunctionComponent).displayName =
  "NavigationPointerOff01Icon";

export default NavigationPointerOff01Icon;
