import * as React from "react";
import { IconProps } from "./types";

export const PenTool02Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M13 5.99997L6.50182 7.29961C6.13883 7.37221 5.95733 7.40851 5.80952 7.49677C5.67886 7.57481 5.56772 7.68164 5.4846 7.80913C5.39057 7.95334 5.34714 8.13326 5.26028 8.49311L2 22M2 22L15.5069 18.7397C15.8667 18.6528 16.0466 18.6094 16.1908 18.5154C16.3183 18.4322 16.4252 18.3211 16.5032 18.1904C16.5915 18.0426 16.6278 17.8611 16.7004 17.4981L18 11M2 22L9.586 14.414M20.8686 7.8686L16.1314 3.13134C15.7354 2.73532 15.5373 2.53732 15.309 2.46313C15.1082 2.39787 14.8918 2.39787 14.691 2.46313C14.4627 2.53732 14.2646 2.73533 13.8686 3.13134L13.1314 3.8686C12.7354 4.26462 12.5373 4.46263 12.4632 4.69095C12.3979 4.8918 12.3979 5.10814 12.4632 5.30899C12.5373 5.53732 12.7354 5.73532 13.1314 6.13134L17.8686 10.8686C18.2646 11.2646 18.4627 11.4626 18.691 11.5368C18.8918 11.6021 19.1082 11.6021 19.309 11.5368C19.5373 11.4626 19.7354 11.2646 20.1314 10.8686L20.8686 10.1313C21.2646 9.73532 21.4627 9.53732 21.5368 9.30899C21.6021 9.10814 21.6021 8.8918 21.5368 8.69095C21.4627 8.46263 21.2646 8.26462 20.8686 7.8686ZM11 11C12.1046 11 13 11.8954 13 13C13 14.1045 12.1046 15 11 15C9.89543 15 9 14.1045 9 13C9 11.8954 9.89543 11 11 11Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(PenTool02Icon as React.FunctionComponent).displayName = "PenTool02Icon";

export default PenTool02Icon;
