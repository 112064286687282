import * as React from "react";
import { IconProps } from "./types";

export const PenToolMinusIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M2 4.99997H8M15 6.99997L8.83447 8.76155C8.52956 8.84867 8.37711 8.89223 8.25117 8.97411C8.13969 9.04659 8.04379 9.14058 7.96907 9.25057C7.88466 9.37483 7.83803 9.52637 7.74477 9.82945L4 22M4 22L16.1705 18.2552C16.4736 18.1619 16.6251 18.1153 16.7494 18.0309C16.8594 17.9562 16.9534 17.8603 17.0259 17.7488C17.1077 17.6229 17.1513 17.4704 17.2384 17.1655L19 11M4 22L10.586 15.4139M21.8686 7.8686L18.1314 4.13134C17.7354 3.73532 17.5373 3.53732 17.309 3.46313C17.1082 3.39787 16.8918 3.39787 16.691 3.46313C16.4627 3.53732 16.2646 3.73533 15.8686 4.13134L15.1314 4.8686C14.7354 5.26462 14.5373 5.46263 14.4632 5.69095C14.3979 5.8918 14.3979 6.10814 14.4632 6.30899C14.5373 6.53732 14.7354 6.73533 15.1314 7.13134L18.8686 10.8686C19.2646 11.2646 19.4627 11.4626 19.691 11.5368C19.8918 11.6021 20.1082 11.6021 20.309 11.5368C20.5373 11.4626 20.7354 11.2646 21.1314 10.8686L21.8686 10.1313C22.2646 9.73532 22.4627 9.53732 22.5368 9.30899C22.6021 9.10814 22.6021 8.8918 22.5368 8.69095C22.4627 8.46263 22.2646 8.26462 21.8686 7.8686ZM12 12C13.1046 12 14 12.8954 14 14C14 15.1045 13.1046 16 12 16C10.8954 16 10 15.1045 10 14C10 12.8954 10.8954 12 12 12Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(PenToolMinusIcon as React.FunctionComponent).displayName = "PenToolMinusIcon";

export default PenToolMinusIcon;
