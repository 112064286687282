import * as React from "react";
import * as LabelPrimitive from "@radix-ui/react-label";
import { Slot } from "@radix-ui/react-slot";
import { cva } from "class-variance-authority";
import {
  Controller,
  ControllerProps,
  FieldPath,
  FieldValues,
  FormProvider,
  UseFormReturn,
  SubmitHandler,
  SubmitErrorHandler,
} from "react-hook-form";

import { cn } from "@/utils/tailwind";
import {
  FormFieldContext,
  FormItemContext,
  useFormField,
} from "@/kit/helpers/form";
import { Label } from "@/kit/ui/label";
import { FormattedMessage } from "react-intl";

interface FormProviderProps<
  TFieldValues extends FieldValues,
  TContext = unknown,
> extends Omit<
    React.HTMLAttributes<HTMLFormElement>,
    "onSubmit" | "onInvalid"
  > {
  form: UseFormReturn<TFieldValues, TContext, undefined>;
  onSubmit: SubmitHandler<TFieldValues>;
  onInvalid?: SubmitErrorHandler<TFieldValues>;
}
type FormProps<TFieldValues extends FieldValues, TContext = unknown> =
  | FormProviderProps<TFieldValues, TContext>
  | React.HTMLAttributes<HTMLFormElement>;

const formComponent = <TFieldValues extends FieldValues, TContext = unknown>(
  { className, ...props }: FormProps<TFieldValues, TContext>,
  ref: React.Ref<HTMLFormElement>,
) => {
  if ("form" in props) {
    const { form, onSubmit, onInvalid, ...rest } = props;
    return (
      <FormProvider {...form}>
        <form
          {...rest}
          className={className}
          ref={ref}
          onSubmit={form.handleSubmit(onSubmit, onInvalid)}
        />
      </FormProvider>
    );
  } else {
    return <form className={className} ref={ref} {...props} />;
  }
};
const Form = React.forwardRef(formComponent) as <
  TFieldValues extends FieldValues,
  TContext = unknown,
>(
  props: FormProps<TFieldValues, TContext> & {
    ref?: HTMLFormElement;
  },
) => ReturnType<typeof formComponent>;
(Form as React.FunctionComponent).displayName = "Form";

const FormField = <
  TFieldValues extends FieldValues = FieldValues,
  TName extends FieldPath<TFieldValues> = FieldPath<TFieldValues>,
>({
  ...props
}: ControllerProps<TFieldValues, TName>) => {
  return (
    <FormFieldContext.Provider value={{ name: props.name }}>
      <Controller {...props} />
    </FormFieldContext.Provider>
  );
};

const formItemVariants = cva("", {
  variants: {
    checkbox: {
      true: "flex flex-row items-start space-x-2 [&>button]:mt-1",
      false: "space-y-1.5",
    },
  },
  defaultVariants: {
    checkbox: false,
  },
});

interface FormItemProps extends React.HTMLAttributes<HTMLDivElement> {
  checkbox?: boolean;
}

const FormItem = React.forwardRef<HTMLDivElement, FormItemProps>(
  ({ className, checkbox = false, ...props }, ref) => {
    const id = React.useId();

    return (
      <FormItemContext.Provider value={{ id }}>
        <div
          ref={ref}
          className={cn(formItemVariants({ checkbox, className }))}
          {...props}
        />
      </FormItemContext.Provider>
    );
  },
);
(FormItem as React.FunctionComponent).displayName = "FormItem";

const FormLabel = React.forwardRef<
  React.ElementRef<typeof LabelPrimitive.Root>,
  React.ComponentPropsWithoutRef<typeof LabelPrimitive.Root>
>(({ className, ...props }, ref) => {
  const { formItemId } = useFormField();

  return (
    <Label
      ref={ref}
      className={cn(className)}
      htmlFor={formItemId}
      {...props}
    />
  );
});
(FormLabel as React.FunctionComponent).displayName = "FormLabel";

const RequiredStar = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => {
  const { error } = useFormField();
  return (
    <span
      className={cn(error ? "text-error-500" : "text-brand-500", className)}
      ref={ref}
      {...props}
    >
      {" "}
      *
    </span>
  );
});
(RequiredStar as React.FunctionComponent).displayName = "RequiredStar";

const OptionalLabel = React.forwardRef<
  HTMLSpanElement,
  React.HTMLAttributes<HTMLSpanElement>
>(({ className, ...props }, ref) => {
  return (
    <span className={cn("text-grey-700", className)} ref={ref} {...props}>
      {" "}
      <FormattedMessage defaultMessage="(optional)" />
    </span>
  );
});
(OptionalLabel as React.FunctionComponent).displayName = "OptionalLabel";

const FormControl = React.forwardRef<
  React.ElementRef<typeof Slot>,
  React.ComponentPropsWithoutRef<typeof Slot>
>(({ ...props }, ref) => {
  const { error, formItemId, formDescriptionId, formMessageId } =
    useFormField();
  return (
    <Slot
      ref={ref}
      id={formItemId}
      aria-describedby={
        !error
          ? `${formDescriptionId}`
          : `${formDescriptionId} ${formMessageId}`
      }
      aria-invalid={!!error}
      {...props}
    />
  );
});
(FormControl as React.FunctionComponent).displayName = "FormControl";

const FormDescription = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, ...props }, ref) => {
  const { formDescriptionId } = useFormField();

  return (
    <p
      ref={ref}
      id={formDescriptionId}
      className={cn("text-[0.8rem] text-muted-foreground", className)}
      {...props}
    />
  );
});
(FormDescription as React.FunctionComponent).displayName = "FormDescription";

const FormMessage = React.forwardRef<
  HTMLParagraphElement,
  React.HTMLAttributes<HTMLParagraphElement>
>(({ className, children, ...props }, ref) => {
  const { error, formMessageId } = useFormField();
  const body = error ? String(error?.message) : children;

  if (!body) {
    return null;
  }

  return (
    <p
      ref={ref}
      id={formMessageId}
      className={cn("text-[0.8rem] font-medium text-error-500", className)}
      {...props}
    >
      {body}
    </p>
  );
});
(FormMessage as React.FunctionComponent).displayName = "FormMessage";

export {
  Form,
  FormItem,
  FormLabel,
  FormControl,
  FormDescription,
  FormMessage,
  FormField,
  RequiredStar,
  OptionalLabel,
};
