import * as React from "react";
import { Link as BaseLink, LinkProps as BaseLinkProps } from "react-router-dom";
import { type VariantProps } from "class-variance-authority";

import { cn } from "@/utils/tailwind";
import { buttonVariants } from "@/kit/helpers/button";
import { Button } from "@/kit/ui/button";

export interface LinkProps
  extends BaseLinkProps,
    VariantProps<typeof buttonVariants> {}

const Link = React.forwardRef<HTMLAnchorElement, LinkProps>(
  ({ className, variant = "link", icon, size, hue, ...props }, ref) => {
    return (
      <Button
        asChild
        className={cn("text-[1em]", className)}
        variant={variant}
        icon={icon}
        size={size}
        hue={hue}
      >
        <BaseLink {...props} ref={ref} />
      </Button>
    );
  },
);
(Link as React.FunctionComponent).displayName = "Link";

export { Link };
