import * as React from "react";
import { IconProps } from "./types";

export const Hexagon01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M11.223 2.43167C11.5066 2.27412 11.6484 2.19534 11.7985 2.16445C11.9315 2.13712 12.0685 2.13712 12.2015 2.16445C12.3516 2.19534 12.4934 2.27412 12.777 2.43168L20.177 6.54279C20.4766 6.70919 20.6263 6.79239 20.7354 6.91073C20.8318 7.01542 20.9049 7.1395 20.9495 7.27467C21 7.42747 21 7.5988 21 7.94144V16.0585C21 16.4012 21 16.5725 20.9495 16.7253C20.9049 16.8605 20.8318 16.9846 20.7354 17.0893C20.6263 17.2076 20.4766 17.2908 20.177 17.4572L12.777 21.5683C12.4934 21.7259 12.3516 21.8046 12.2015 21.8355C12.0685 21.8629 11.9315 21.8629 11.7985 21.8355C11.6484 21.8046 11.5066 21.7259 11.223 21.5683L3.82297 17.4572C3.52345 17.2908 3.37369 17.2076 3.26463 17.0893C3.16816 16.9846 3.09515 16.8605 3.05048 16.7253C3 16.5725 3 16.4012 3 16.0585V7.94144C3 7.59879 3 7.42747 3.05048 7.27467C3.09515 7.1395 3.16816 7.01542 3.26463 6.91073C3.37369 6.79239 3.52345 6.70919 3.82297 6.54279L11.223 2.43167Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Hexagon01Icon as React.FunctionComponent).displayName = "Hexagon01Icon";

export default Hexagon01Icon;
