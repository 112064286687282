import { graphql } from "relay-runtime";
import { formatSlug } from "./format";
import { ForumOwnerKind } from "./__generated__/routingFragment.graphql";
import { assertNever } from "./helpers";

graphql`
  fragment routingFragment on ForumOwner {
    forumOwnerKind
  }
`;

export type PathSegment = string;
export type Path = PathSegment[];

export function formatPath(...args: Path): string {
  return args.join("/");
}

export function formatAbsolutePath(...args: Path): string {
  return "/" + formatPath(...args);
}

export interface ForumOwnerShape {
  slug: string;
  forumOwnerKind: ForumOwnerKind;
}

export interface ForumShape {
  slug: string;
  owner: ForumOwnerShape;
}

export interface TopicShape {
  id: ID;
  title: string;
  forum: ForumShape;
}

export interface BlogArticleShape {
  slugLower: string;
}

export function getForumPath({ slug, owner }: ForumShape, ...rest: Path) {
  switch (owner.forumOwnerKind) {
    case "TOP_LEVEL":
      return formatAbsolutePath("discussions", slug, ...rest);
    case "COMPETITION":
      return formatAbsolutePath(
        "competitions",
        owner.slug,
        "discussions",
        ...rest,
      );
    case "EVENT":
      return formatAbsolutePath("events", owner.slug, "discussions", ...rest);
    case "BLOG":
      return formatAbsolutePath("blog", ...rest);
    default:
      assertNever(owner.forumOwnerKind);
  }
}

export function getTopicPath({ id, title, forum }: TopicShape, ...rest: Path) {
  if (rest.length) {
    return getForumPath(forum, id, ...rest);
  }
  return getForumPath(forum, id, formatSlug(title));
}

export function getBlogArticlePath(
  { slugLower }: BlogArticleShape,
  ...rest: Path
) {
  return formatAbsolutePath("blog", slugLower, ...rest);
}
