import { MouseEvent, RefObject, useEffect, useState } from "react";
import { TOCItem } from "../utils/generateToc";
import scrollIntoView from "scroll-into-view-if-needed";
import { cn } from "../utils/tailwind";
import { FormattedMessage } from "react-intl";
import { Link } from "react-router-dom";

export interface Props {
  titles: TOCItem[];
  contentRef?: RefObject<HTMLElement>;
}

interface TitleVisibility {
  /// Is the actual title shown on screen?
  title: boolean;
  /// How many elements belonging to this title are shown on screen?
  /// Count may be strictly positive even though the title is not shown.
  count: number;
}

const TitleVisibility = Object.freeze({
  offscreen: Object.freeze<TitleVisibility>({ count: 0, title: false }),
});

export default function TableOfContents({ titles, contentRef }: Props) {
  const [activeTitle, setActiveTitle] = useState<TOCItem | undefined>();

  // Observe which element are shown on screen:
  useEffect(() => {
    const contentRoot = contentRef?.current;
    if (!contentRoot) return;

    const elementTitleMap = buildElementTitleMap(contentRoot);
    const titleVisibility = Object.fromEntries(
      titles.map((item) => [item.id, { ...TitleVisibility.offscreen }]),
    );

    const observer = new IntersectionObserver((entries) => {
      for (const { target, isIntersecting } of entries) {
        const title = elementTitleMap.get(target);
        if (typeof title === "undefined") continue;

        const visibility = titleVisibility[title];

        if (target.id === title) {
          visibility.title = isIntersecting;
        }

        if (isIntersecting) {
          visibility.count += 1;
        } else {
          visibility.count = Math.max(0, visibility.count - 1);
        }
      }

      setActiveTitle(findActiveTitle(titles, titleVisibility));
    });

    for (const target of elementTitleMap.keys()) {
      observer.observe(target);
    }
    return () => observer.disconnect();
  }, [titles, contentRef]);

  if (!titles.length) {
    return null;
  }

  const handleScroll = (nextItem: TOCItem, evt: MouseEvent) => {
    const targetElement = document.getElementById(nextItem.id);
    if (!targetElement) return;

    evt.preventDefault();

    scrollIntoView(targetElement, {
      behavior: "smooth",
      scrollMode: "always",
      block: "start",
    });

    const url = new URL(location.href);
    url.hash = nextItem.id;
    window.history.pushState(null, "", url);
  };

  return (
    <div className="flex flex-col gap-4">
      <p className="text-base font-semibold leading-normal text-gray-950">
        <FormattedMessage defaultMessage="Table of contents" />
      </p>
      <ul>
        {titles.map((heading) => (
          <li
            key={heading.id}
            className="mb-2"
            style={{
              marginInlineStart: getIndentation(heading.depth),
            }}
          >
            <Link
              to={`#${heading.id}`}
              onClick={handleScroll.bind(null, heading)}
              className={cn(
                activeTitle?.id === heading.id
                  ? "text-indigo"
                  : "text-gray-600",
                "text-start font-normal leading-normal",
              )}
            >
              {heading.text}
            </Link>
          </li>
        ))}
      </ul>
    </div>
  );
}

function getIndentation(depth: number) {
  return `${depth}rem`;
}

/// Scan through the markdown contents to create a map for each first-level elements in it
/// to their corresponding title.
/// This lets us know which paragraph, list, table, ... is part of which title.
function buildElementTitleMap(root: HTMLElement): Map<Element, string> {
  const map = new Map<Element, string>();

  for (const title of root.querySelectorAll("[data-title]")) {
    map.set(title, title.id);

    const parent = title.parentElement;
    if (parent === null) continue;

    let sibling = parent.nextElementSibling;
    while (sibling !== null) {
      if (sibling.querySelector("[data-title]") != null) break;
      map.set(sibling, title.id);
      sibling = sibling.nextElementSibling;
    }
  }

  return map;
}

/// Find first active title, or else find first paragraph.
function findActiveTitle(
  titles: TOCItem[],
  titleVisibility: Record<string, TitleVisibility>,
): TOCItem | undefined {
  return (
    titles.find(
      (item) =>
        titleVisibility[item.id].count > 0 && titleVisibility[item.id].title,
    ) ?? titles.find((item) => titleVisibility[item.id].count > 0)
  );
}
