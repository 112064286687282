import { graphql } from "react-relay";
import { defineMessage } from "react-intl";
import { Badge as BadgeName } from "./__generated__/badges.graphql";
import { MessageDescriptor } from "react-intl";
import test from "./test.png";
import paris2024_winners from "./paris2024-winners.webp";
import paris2024_hackers from "./paris2024-hackers.webp";
import chicago_2023 from "./chicago2023.png";
import trieste_2023 from "./trieste2023.png";
import paris_2023 from "./paris2023.png";
import grenoble_2022 from "./grenoble2022.jpg";
import montreal_2022 from "./montreal2022.jpg";
import bigparis_2021 from "./bigparis2021.jpg";
import paris_2021 from "./paris2021.jpg";
import quantum_pioneer from "./quantum-pioneer.png";
import ingenii2024first from "./ingenii2024-1st.svg";
import ingenii2024second from "./ingenii2024-2nd.svg";
import ingenii2024third from "./ingenii2024-3rd.svg";
import ingenii2024default from "./ingenii2024-default.svg";
import ingenii2024special from "./ingenii2024-special.svg";
import q2b2024gold from "./q2b-2024-Gold.png";
import q2b2024silver from "./q2b-2024-Silver.png";
import q2b2024bronze from "./q2b-2024-Bronze.png";
import q2b2024onsite from "./q2b-2024-On-site.png";
import q2b2024remote from "./q2b-2024-Remote.png";
import novacene2024 from "./novacene2024.svg";
import qinnovision2025winner from "./qinnovision-2025-winner.svg";
import qinnovision2025finalist from "./qinnovision-2025-finalist.svg";
import quantumsignalslob2025first from "./quantum-signals-lob-2025-first.svg";
import quantumsignalslob2025second from "./quantum-signals-lob-2025-second.svg";

graphql`
  fragment badges on EntityBadge {
    badge
  }
`;

export interface Badge {
  id: BadgeName;
  name: MessageDescriptor;
  description: MessageDescriptor;
  image: string;
}

function createBadges(...badges: Badge[]): Record<BadgeName, Badge> {
  return badges.reduce<Record<BadgeName, Badge>>(
    (acc, badge) => {
      const duplicated = acc[badge.id];
      if (duplicated) {
        throw new Error(
          `Duplicated badge id "${badge.id}": ${JSON.stringify(duplicated)} ${JSON.stringify(badge)}`,
        );
      }
      return { ...acc, [badge.id]: badge };
    },
    {} as Record<BadgeName, Badge>,
  );
}

export const badges: Record<BadgeName, Badge> = createBadges(
  {
    id: "TEST",
    name: defineMessage({
      id: "test-name",
      defaultMessage: "Quantum Badge",
    }),
    description: defineMessage({
      id: "test-description",
      defaultMessage:
        "You did some awesome work, this really deserves a badge!",
    }),
    image: test,
  },
  {
    id: "PARIS_2024_WINNERS",
    image: paris2024_winners,
    name: defineMessage({
      id: "paris2024-winners-badge",
      defaultMessage: "Big Quantum Hackathon Paris 2024 Winner",
    }),
    description: defineMessage({
      id: "paris2024-winners-badge-description",
      defaultMessage:
        "Won the Big Quantum Hackathon Sports Edition, held in Paris from May 25 to 28, 2024.",
    }),
  },
  {
    id: "PARIS_2024_HACKERS",
    image: paris2024_hackers,
    name: defineMessage({
      id: "paris2024-hackers-badge",
      defaultMessage: "Big Quantum Hackathon Paris 2024 Participant",
    }),
    description: defineMessage({
      id: "paris2024-hackers-badge-description",
      defaultMessage:
        "Participated in the Big Quantum Hackathon Sports Edition, held in Paris from May 25 to 28, 2024.",
    }),
  },
  {
    id: "CHICAGO_2023",
    image: chicago_2023,
    name: defineMessage({
      id: "chicago2023-badge",
      defaultMessage: "BIG Quantum Hackathon Chicago 2023 Participant",
    }),
    description: defineMessage({
      id: "chicago2023-badge-description",
      defaultMessage:
        "Participated in the BIG Quantum Hackathon by the Chicago Quantum Exchange & QuantX, held in Chicago (Sept, 2023).",
    }),
  },
  {
    id: "TRIESTE_2023",
    image: trieste_2023,
    name: defineMessage({
      id: "trieste2023-badge",
      defaultMessage: "ICTP - Quantinuum Quantum Hackathon 2023 Participant",
    }),
    description: defineMessage({
      id: "trieste2023-badge-description",
      defaultMessage:
        "Participated in the ICTP - Quantinuum Quantum Hackathon, held in Trieste (April, 2023).",
    }),
  },
  {
    id: "PARIS_2023",
    image: paris_2023,
    name: defineMessage({
      id: "paris2023-badge",
      defaultMessage: "BIG QC-AI-HPC Hackathon 2023 Participant",
    }),
    description: defineMessage({
      id: "paris2023-badge-description",
      defaultMessage:
        "Participated in the BIG QC-AI-HPC Hackathon by QuantX, held in Paris (March, 2023).",
    }),
  },
  {
    id: "GRENOBLE_2022",
    image: grenoble_2022,
    name: defineMessage({
      id: "grenoble2022-badge",
      defaultMessage: "Quantum Hackathon 2022 Participant",
    }),
    description: defineMessage({
      id: "grenoble2022-badge-description",
      defaultMessage:
        "Participated in the Quantum Hackathon by QuantX, held in Grenoble (October, 2022).",
    }),
  },
  {
    id: "MONTREAL_2022",
    image: montreal_2022,
    name: defineMessage({
      id: "montreal2022-badge",
      defaultMessage: "Quantum Hackathon 2022 Participant",
    }),
    description: defineMessage({
      id: "montreal2022-badge-description",
      defaultMessage:
        "Participated in the Quantum Hackathon by Québec Quantique & QuantX, held in Montreal (June, 2022).",
    }),
  },
  {
    id: "BIG_PARIS_2021",
    image: bigparis_2021,
    name: defineMessage({
      id: "bigparis2021-badge",
      defaultMessage: "BIG Quantum Hackathon 2021 Participant",
    }),
    description: defineMessage({
      id: "bigparis2021-badge-description",
      defaultMessage:
        "Participated in the BIG Quantum Hackathon by QuantX, held in Paris (October, 2021).",
    }),
  },
  {
    id: "PARIS_2021",
    image: paris_2021,
    name: defineMessage({
      id: "paris2021-badge",
      defaultMessage: "Quantum Hackathon 2021 Participant",
    }),
    description: defineMessage({
      id: "paris2021-badge-description",
      defaultMessage:
        "Participated in the Quantum Hackathon by QuantX, held in Paris (March, 2021).",
    }),
  },
  {
    id: "QUANTUM_PIONEER",
    image: quantum_pioneer,
    name: defineMessage({
      id: "quantum-pioneer-badge",
      defaultMessage: "Quantum Pioneer",
    }),
    description: defineMessage({
      id: "quantum-pioneer-badge-description",
      defaultMessage:
        "Congratulations on submitting your first entry to Aqora! You've taken the first step in your quantum journey. Many more challenges and achievements await as you continue to explore new frontiers.",
    }),
  },
  {
    id: "INGENII_2024_FIRST",
    image: ingenii2024first,
    name: defineMessage({
      defaultMessage:
        "Clinical Trial Optimization Competition 2024 by Ingenii First Prize",
    }),
    description: defineMessage({
      defaultMessage:
        "Congratulations on winning First Prize in the Clinical Trial Optimization Competition 2024 by Ingenii. Your work not only secured the top spot but also surpassed Ingenii’s internal benchmark.",
    }),
  },
  {
    id: "INGENII_2024_SECOND",
    image: ingenii2024second,
    name: defineMessage({
      defaultMessage:
        "Clinical Trial Optimization Competition 2024 by Ingenii Second Prize",
    }),
    description: defineMessage({
      defaultMessage:
        "Congratulations on winning Second Prize in the Clinical Trial Optimization Competition 2024 by Ingenii. Your achievement has set a new standard by exceeding Ingenii’s internal benchmark.",
    }),
  },
  {
    id: "INGENII_2024_THIRD",
    image: ingenii2024third,
    name: defineMessage({
      defaultMessage:
        "Clinical Trial Optimization Competition 2024 by Ingenii Third Prize",
    }),
    description: defineMessage({
      defaultMessage:
        "Congratulations on winning Third Prize in the Clinical Trial Optimization Competition 2024 by Ingenii. Your involvement has contributed to the success of this outstanding competition.",
    }),
  },
  {
    id: "INGENII_2024_OTHERS",
    image: ingenii2024default,
    name: defineMessage({
      defaultMessage:
        "Clinical Trial Optimization Competition 2024 by Ingenii Participant",
    }),
    description: defineMessage({
      defaultMessage:
        "Congratulations on participating in the Clinical Trial Optimization Competition 2024 by Ingenii. Your involvement has contributed to the success of this outstanding competition.",
    }),
  },
  {
    id: "INGENII_2024_SPECIAL",
    image: ingenii2024special,
    name: defineMessage({
      defaultMessage:
        "Clinical Trial Optimization Competition 2024 by Ingenii Special Prize",
    }),
    description: defineMessage({
      defaultMessage:
        "Congratulations on winning the Special Prize in the Clinical Trial Optimization Competition 2024 by Ingenii. Your involvement has contributed to the success of this outstanding competition.",
    }),
  },
  {
    id: "Q2B2024_GOLD",
    image: q2b2024gold,
    name: defineMessage({
      defaultMessage: "Hackathon Champion - Q2B Silicon Valley 2024",
    }),
    description: defineMessage({
      defaultMessage:
        "Awarded to the top team of the IBM Hackathon at Q2B Silicon Valley 2024! Your solution stood out for its technical brilliance, creativity, and real-world impact, earning you the ultimate recognition as this year's champions. A testament to your quantum innovation and collaborative excellence.",
    }),
  },
  {
    id: "Q2B2024_SILVER",
    image: q2b2024silver,
    name: defineMessage({
      defaultMessage:
        "Hackathon Innovator - 2nd Place Team - Q2B Silicon Valley 2024",
    }),
    description: defineMessage({
      defaultMessage:
        "Recognizing the second-place team of the IBM Hackathon at Q2B Silicon Valley 2024, this badge celebrates your exceptional creativity and technical skill. Your solution impressed the judges, demonstrating your ability to solve complex problems and advance quantum computing's frontiers.",
    }),
  },
  {
    id: "Q2B2024_BRONZE",
    image: q2b2024bronze,
    name: defineMessage({
      defaultMessage:
        "Hackathon Trailblazer - 3rd Place Team - Q2B Silicon Valley 2024",
    }),
    description: defineMessage({
      defaultMessage:
        "Awarded to the third-place team of the IBM Hackathon at Q2B Silicon Valley 2024! Your innovative approach and technical expertise earned you this distinction, highlighting your role as pioneers in tackling real-world challenges with quantum solutions.",
    }),
  },
  {
    id: "Q2B2024_ONSITE",
    image: q2b2024onsite,
    name: defineMessage({
      defaultMessage: "On-Site Participant - Q2B Hackathon 2024",
    }),
    description: defineMessage({
      defaultMessage:
        "Awarded to those who participated in the IBM Hackathon at Q2B Silicon Valley 2024 in person. Your presence brought energy and engagement to the event, helping to build a collaborative and hands-on quantum community.",
    }),
  },
  {
    id: "Q2B2024_REMOTE",
    image: q2b2024remote,
    name: defineMessage({
      defaultMessage: "Global Participant - Q2B Hackathon 2024",
    }),
    description: defineMessage({
      defaultMessage:
        "Awarded to remote participants of the IBM Hackathon at Q2B Silicon Valley 2024. Your contributions highlight the global reach and collaborative spirit of the quantum community, proving that innovation knows no boundaries. Together, we shape the future of quantum technology.",
    }),
  },
  {
    id: "NOVACENE_2024",
    image: novacene2024,
    name: defineMessage({
      defaultMessage: "Malicious Login Detection 2024 by NovaceneAI – Winner",
    }),
    description: defineMessage({
      defaultMessage:
        "Congratulations on winning the Malicious Login Detection 2024 Competition by NovaceneAI! Your innovative use of quantum computing demonstrated impressive potential for enhancing the detection of suspicious logins, marking a meaningful contribution to the evolution of cybersecurity solutions.",
    }),
  },
  {
    id: "QINNOVISION_2025_WINNER",
    image: qinnovision2025winner,
    name: defineMessage({
      defaultMessage: "QInnovision Challenge 2024-2025 Winner!",
    }),
    description: defineMessage({
      defaultMessage:
        "Your groundbreaking solution demonstrated exceptional technical ingenuity, creativity, and tangible impact, securing your place as this year’s champions. This badge is a testament to your pioneering quantum innovation and outstanding teamwork.",
    }),
  },
  {
    id: "QINNOVISION_2025_FINALIST",
    image: qinnovision2025finalist,
    name: defineMessage({
      defaultMessage: "QInnovision Challenge 2024-2025 Finalist!",
    }),
    description: defineMessage({
      defaultMessage:
        "Your team’s outstanding solution distinguished itself among fierce competition, demonstrating exceptional creativity, technical expertise, and a forward-thinking approach. This badge recognizes your achievement in reaching the final stage, a testament to your dedication and impact in the quantum ecosystem.",
    }),
  },
  {
    id: "QUANTUM_SIGNALS_LOB_2025_FIRST",
    image: quantumsignalslob2025first,
    name: defineMessage({
      defaultMessage: "🏆 Quantum Trading Oracle  – 1st Place 🏆",
    }),
    description: defineMessage({
      defaultMessage:
        "You've cracked the code of the financial markets, at least as far as quantum neural networks can take us (for now)! Your solution outperformed the competition, proving that quantum-powered stock trend prediction is more than just theory. Wear this badge with pride. Wall Street might be calling soon!",
    }),
  },
  {
    id: "QUANTUM_SIGNALS_LOB_2025_SECOND",
    image: quantumsignalslob2025second,
    name: defineMessage({
      defaultMessage: "Schrödinger’s Trader – 2nd Place - Second Prize",
    }),
    description: defineMessage({
      defaultMessage:
        "Halfway to quantum market domination! Your model navigated the chaotic world of Limit Order Book data with finesse, showing impressive predictive power. Just a tiny quantum fluctuation away from victory, but still a monumental achievement, this badge proves you're ahead of the curve in quantum finance!",
    }),
  },
);
