import { useState, DragEvent, forwardRef } from "react";
import { Spread } from "../../utils/types";
import TextInput from "../TextArea";
import { cn } from "../../utils/tailwind";

const activeDrag = "border-old_grey border-2 border-t-2 border-dashed";

type Props = {
  onDragAndDrop: (files: FileList, lineNumber?: number) => void;
};

export interface TextAreaDropZoneProps
  extends Spread<React.TextareaHTMLAttributes<HTMLTextAreaElement>, Props> {}

const TextAreaDropZone = forwardRef<HTMLTextAreaElement, TextAreaDropZoneProps>(
  ({ onDragAndDrop, ...rest }, ref) => {
    const { className, ...restWithoutClassName } = rest;
    const [isDragging, setIsDragging] = useState(false);
    const handleDragEnter = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(true);
    };

    const handleDragLeave = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);
    };

    const handleDragOver = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
    };

    const handleDrop = (e: DragEvent) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(false);

      if (e.dataTransfer.files && e.dataTransfer.files.length > 0) {
        const lineNumber = calculateLineNumberFromXYPosition(e);
        onDragAndDrop(e.dataTransfer.files, lineNumber);
      }
    };

    const calculateLineNumberFromXYPosition = (e: DragEvent): number => {
      const textarea = e.currentTarget as HTMLTextAreaElement;
      const { top } = textarea.getBoundingClientRect();
      const computedStyle = window.getComputedStyle(textarea);
      const lineHeight =
        parseFloat(computedStyle.lineHeight) ||
        parseFloat(computedStyle.fontSize);
      const yRelativeToTextArea = e.clientY - top;
      const lineNumber = Math.floor(yRelativeToTextArea / lineHeight) + 1;
      return lineNumber;
    };

    return (
      <TextInput
        ref={ref}
        className={cn(isDragging ? activeDrag : "", className, "h-auto")}
        onDragEnter={handleDragEnter}
        onDragLeave={handleDragLeave}
        onDragOver={handleDragOver}
        onDrop={handleDrop}
        {...restWithoutClassName}
      />
    );
  },
);

export default TextAreaDropZone;
