import * as React from "react";
import { IconProps } from "./types";

export const AnnotationHeartIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V13.5C21 14.8978 21 15.5967 20.7716 16.1481C20.4672 16.8831 19.8831 17.4672 19.1481 17.7716C18.5967 18 17.8978 18 16.5 18C16.0114 18 15.7671 18 15.5405 18.0535C15.2383 18.1248 14.9569 18.2656 14.7185 18.4645C14.5397 18.6137 14.3931 18.8091 14.1 19.2L12.64 21.1467C12.4229 21.4362 12.3143 21.5809 12.1812 21.6327C12.0647 21.678 11.9353 21.678 11.8188 21.6327C11.6857 21.5809 11.5771 21.4362 11.36 21.1467L9.9 19.2C9.60685 18.8091 9.46028 18.6137 9.2815 18.4645C9.04312 18.2656 8.76169 18.1248 8.45951 18.0535C8.23287 18 7.98858 18 7.5 18C6.10218 18 5.40326 18 4.85195 17.7716C4.11687 17.4672 3.53284 16.8831 3.22836 16.1481C3 15.5967 3 14.8978 3 13.5V7.8Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
        <path
          clipRule="evenodd"
          d="M11.9973 8.33059C11.1975 7.4216 9.8639 7.17708 8.86188 8.00945C7.85986 8.84182 7.71879 10.2335 8.50568 11.2179C8.97361 11.8033 10.1197 12.8531 10.9719 13.6079C11.3237 13.9195 11.4996 14.0753 11.7114 14.1385C11.8925 14.1926 12.102 14.1926 12.2832 14.1385C12.4949 14.0753 12.6708 13.9195 13.0226 13.6079C13.8748 12.8531 15.0209 11.8033 15.4888 11.2179C16.2757 10.2335 16.1519 8.83306 15.1326 8.00945C14.1134 7.18584 12.797 7.4216 11.9973 8.33059Z"
          fillRule="evenodd"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(AnnotationHeartIcon as React.FunctionComponent).displayName =
  "AnnotationHeartIcon";

export default AnnotationHeartIcon;
