import * as React from "react";

import { EyeIcon, EyeOffIcon, IconProps } from "@/icons";
import { cn } from "@/utils/tailwind";
import { InputContext } from "@/kit/helpers/input";

const PasswordToggleIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ className, ...props }, ref) => {
    const { setType, type } = React.useContext(InputContext);
    return type == "text" ? (
      <EyeOffIcon
        className={cn("hover:cursor-pointer", className)}
        {...props}
        ref={ref}
        onClick={() => {
          setType("password");
        }}
      />
    ) : (
      <EyeIcon
        className={cn("hover:cursor-pointer", className)}
        {...props}
        ref={ref}
        onClick={() => setType("text")}
      />
    );
  },
);
(PasswordToggleIcon as React.FunctionComponent).displayName =
  "PasswordToggleIcon";

export { PasswordToggleIcon };
