import * as React from "react";
import { IconProps } from "./types";

export const ArrowNarrowDownLeftIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M18 6L6 18M6 18H14M6 18V10"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ArrowNarrowDownLeftIcon as React.FunctionComponent).displayName =
  "ArrowNarrowDownLeftIcon";

export default ArrowNarrowDownLeftIcon;
