import { graphql, useFragment } from "react-relay";
import { EntityBadgeListFragment$key } from "./__generated__/EntityBadgeListFragment.graphql";
import { EntityBadgeAwardButton } from "./EntityBadgeAwardButton";
import { EntityBadge } from "./EntityBadge";
import { Badge } from "../assets/badges/__generated__/badges.graphql";
import { EntityShareBadgeModal } from "./EntityShareBadgeModal";

export const EntityBadgeListFragment = graphql`
  fragment EntityBadgeListFragment on Entity {
    username
    id
    viewerCanAward: viewerCan(action: AWARD_BADGE)
    badges(first: null) @connection(key: "EntityBadgeList_entity_badges") {
      __id
      edges {
        node {
          id
          badge
          ...EntityBadgeFragment
        }
      }
    }
  }
`;

export interface EntityBadgeListProps {
  entity: EntityBadgeListFragment$key;
  sharedBadge?: Badge;
}

export function EntityBadgeList(props: EntityBadgeListProps) {
  const entity = useFragment(EntityBadgeListFragment, props.entity);

  const sharedBadgeNode = entity.badges.edges.find(
    ({ node }) => node.badge === props.sharedBadge,
  );

  const sharedBadgeId = sharedBadgeNode ? sharedBadgeNode.node.id : null;

  return (
    <>
      {entity.viewerCanAward && (
        <div>
          <EntityBadgeAwardButton entity={props.entity} />
        </div>
      )}
      <div className="flex flex-row flex-wrap gap-3 items-start">
        {entity.badges.edges.map(({ node: badge }) => (
          <EntityBadge key={badge.id} badge={badge} />
        ))}
      </div>
      {props.sharedBadge && sharedBadgeId && (
        <EntityShareBadgeModal
          entityId={entity.id}
          badge={props.sharedBadge}
          username={entity.username}
          isOpen={props.sharedBadge !== undefined}
          viewerCanWithdraw={entity.viewerCanAward}
          badgeId={sharedBadgeId}
        />
      )}
    </>
  );
}
