import * as React from "react";
import { IconProps } from "./types";

export const ZapSquareIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M3 7.8C3 6.11984 3 5.27976 3.32698 4.63803C3.6146 4.07354 4.07354 3.6146 4.63803 3.32698C5.27976 3 6.11984 3 7.8 3H16.2C17.8802 3 18.7202 3 19.362 3.32698C19.9265 3.6146 20.3854 4.07354 20.673 4.63803C21 5.27976 21 6.11984 21 7.8V16.2C21 17.8802 21 18.7202 20.673 19.362C20.3854 19.9265 19.9265 20.3854 19.362 20.673C18.7202 21 17.8802 21 16.2 21H7.8C6.11984 21 5.27976 21 4.63803 20.673C4.07354 20.3854 3.6146 19.9265 3.32698 19.362C3 18.7202 3 17.8802 3 16.2V7.8Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
        <path
          d="M12 6L7.4336 12.2269C7.13256 12.6374 6.98205 12.8427 6.9869 13.0142C6.99113 13.1634 7.06185 13.303 7.17972 13.3947C7.31514 13.5 7.56967 13.5 8.07872 13.5H12V18L16.5664 11.7731C16.8674 11.3626 17.018 11.1573 17.0131 10.9858C17.0089 10.8366 16.9381 10.697 16.8203 10.6053C16.6849 10.5 16.4303 10.5 15.9213 10.5H12V6Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(ZapSquareIcon as React.FunctionComponent).displayName = "ZapSquareIcon";

export default ZapSquareIcon;
