import * as React from "react";
import { IconProps } from "./types";

export const FeatherIcon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M16 7.99995L2 21.9999M18 14.9999H9M6.6 18.9999H13.3373C13.5818 18.9999 13.7041 18.9999 13.8192 18.9723C13.9213 18.9478 14.0188 18.9074 14.1083 18.8526C14.2092 18.7907 14.2957 18.7043 14.4686 18.5313L19.5 13.4999C19.739 13.261 19.8584 13.1415 19.9546 13.0357C22.0348 10.7473 22.0348 7.25262 19.9546 4.96418C19.8584 4.85839 19.739 4.73891 19.5 4.49995C19.261 4.26099 19.1416 4.1415 19.0358 4.04535C16.7473 1.96519 13.2527 1.96519 10.9642 4.04535C10.8584 4.1415 10.739 4.26098 10.5 4.49994L5.46863 9.53132C5.29568 9.70427 5.2092 9.79075 5.14736 9.89166C5.09253 9.98114 5.05213 10.0787 5.02763 10.1807C5 10.2958 5 10.4181 5 10.6627V17.3999C5 17.96 5 18.24 5.10899 18.4539C5.20487 18.6421 5.35785 18.7951 5.54601 18.891C5.75992 18.9999 6.03995 18.9999 6.6 18.9999Z"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(FeatherIcon as React.FunctionComponent).displayName = "FeatherIcon";

export default FeatherIcon;
