import * as React from "react";
import { IconProps } from "./types";

export const Snowflake01Icon = React.forwardRef<SVGSVGElement, IconProps>(
  ({ color = "currentColor", ...props }, forwardedRef) => {
    return (
      <svg
        fill="none"
        height="24"
        viewBox="0 0 24 24"
        width="24"
        xmlns="http://www.w3.org/2000/svg"
        {...props}
        ref={forwardedRef}
      >
        <path
          d="M18.0622 8.5L5.93782 15.5M18.0622 8.5L19.1603 4.40192M18.0622 8.5L22.1603 9.59808M5.93782 15.5L1.83974 14.4019M5.93782 15.5L4.83974 19.5981M18.0621 15.4999L5.93774 8.49986M18.0621 15.4999L22.1603 14.4018M18.0621 15.4999L19.1603 19.598M5.93774 8.49986L4.83989 4.40203M5.93774 8.49986L1.83989 9.59819M12 5L12 19M12 5L9 2M12 5L15 2M12 19L9 22M12 19L15 22"
          strokeLinecap="round"
          strokeLinejoin="round"
          strokeWidth="2"
          stroke={color}
        />
      </svg>
    );
  },
);
(Snowflake01Icon as React.FunctionComponent).displayName = "Snowflake01Icon";

export default Snowflake01Icon;
