/**
 * @generated SignedSource<<abeba9184b528616e6e414bba5ed0568>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { Fragment, ReaderFragment } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type EntityAvatarFragment$data = {
  readonly username: string;
  readonly " $fragmentSpreads": FragmentRefs<"EntityProfilePicFragment">;
  readonly " $fragmentType": "EntityAvatarFragment";
};
export type EntityAvatarFragment$key = {
  readonly " $data"?: EntityAvatarFragment$data;
  readonly " $fragmentSpreads": FragmentRefs<"EntityAvatarFragment">;
};

const node: ReaderFragment = {
  "argumentDefinitions": [],
  "kind": "Fragment",
  "metadata": null,
  "name": "EntityAvatarFragment",
  "selections": [
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "username",
      "storageKey": null
    },
    {
      "args": [
        {
          "kind": "Literal",
          "name": "thumbnail",
          "value": true
        }
      ],
      "kind": "FragmentSpread",
      "name": "EntityProfilePicFragment"
    }
  ],
  "type": "Entity",
  "abstractKey": "__isEntity"
};

(node as any).hash = "6a28607e5022b1f50d98f24e8a237993";

export default node;
