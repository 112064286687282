import { Suspense } from "react";
import { FormattedMessage } from "react-intl";
import { useLazyLoadQuery } from "react-relay";
import { Link } from "react-router-dom";
import { graphql } from "relay-runtime";
import EntityProfilePic from "./EntityProfilePic";
import { HoverCard, HoverCardContent, HoverCardTrigger } from "./HoverCard";
import { Skeleton } from "./Skeleton";
import { cn } from "../utils/tailwind";
import { useAuth } from "../utils/auth";
import { MentionQuery as MentionQueryType } from "./__generated__/MentionQuery.graphql";
import { MentionContentQuery as MentionContentQueryType } from "./__generated__/MentionContentQuery.graphql";

const MentionQuery = graphql`
  query MentionQuery($username: String!) {
    entityByUsername(username: $username) {
      id
      username
    }
  }
`;

const MentionContentQuery = graphql`
  query MentionContentQuery($username: String!) {
    entityByUsername(username: $username) {
      id
      username
      displayName
      createdAt
      ...EntityProfilePicFragment @arguments(thumbnail: true)
    }
  }
`;

export interface MentionProps {
  username: string;
}

function RawMention({ username }: MentionProps) {
  return `@${username}`;
}

function MentionCardContentSkeleton() {
  return (
    <div className="flex items-center gap-4 w-full">
      <Skeleton className="h-12 w-12 rounded-full flex-shrink-0" />

      <div className="flex flex-col justify-center space-y-2">
        <Skeleton className="h-4 w-32" />
        <Skeleton className="h-4 w-24" />
        <Skeleton className="h-3 w-40" />
      </div>
    </div>
  );
}

function MentionCardContent(props: MentionProps) {
  const { entityByUsername: entity } =
    useLazyLoadQuery<MentionContentQueryType>(MentionContentQuery, {
      username: props.username,
    });

  if (!entity) {
    return <RawMention {...props} />;
  }

  return (
    <div className="flex items-center gap-4 w-full ">
      <EntityProfilePic entity={entity} size="16" className="flex-shrink-0" />

      <div className="flex flex-col justify-center">
        <div className="flex items-baseline gap-3">
          <p className="text-lg font-semibold text-gray-900">
            {entity.username}
          </p>

          <p className="text-sm text-gray-600">{entity.displayName}</p>
        </div>

        <p className="text-sm text-gray-500">
          <FormattedMessage
            defaultMessage="Joined {joinedAt, date, ::yyyyMMdd}"
            values={{ joinedAt: new Date(entity.createdAt) }}
          />
        </p>
      </div>
    </div>
  );
}

function MentionWithCard(props: MentionProps) {
  const { userId: viewerId } = useAuth();
  const { entityByUsername: entity } = useLazyLoadQuery<MentionQueryType>(
    MentionQuery,
    { username: props.username },
  );

  if (!entity) {
    return <RawMention {...props} />;
  }

  return (
    <HoverCard>
      <HoverCardTrigger asChild>
        <Link
          to={`/${entity.username}`}
          className={cn(
            "font-bold underline-offset-2 underline py-2 px-1 cursor-pointer",
            entity.id === viewerId && "bg-old_grey/25",
          )}
        >
          <RawMention {...props} />
        </Link>
      </HoverCardTrigger>

      <HoverCardContent>
        <Suspense fallback={<MentionCardContentSkeleton />}>
          <MentionCardContent {...props} />
        </Suspense>
      </HoverCardContent>
    </HoverCard>
  );
}

export function Mention(props: MentionProps) {
  return (
    <Suspense fallback={<RawMention {...props} />}>
      <MentionWithCard {...props} />
    </Suspense>
  );
}
