/**
 * @generated SignedSource<<6d3a5db80b3d3dca7ab485fdccb3028b>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Mutation } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type Badge = "BIG_PARIS_2021" | "CHICAGO_2023" | "GRENOBLE_2022" | "INGENII_2024_FIRST" | "INGENII_2024_OTHERS" | "INGENII_2024_SECOND" | "INGENII_2024_SPECIAL" | "INGENII_2024_THIRD" | "MONTREAL_2022" | "NOVACENE_2024" | "PARIS_2021" | "PARIS_2023" | "PARIS_2024_HACKERS" | "PARIS_2024_WINNERS" | "Q2B2024_BRONZE" | "Q2B2024_GOLD" | "Q2B2024_ONSITE" | "Q2B2024_REMOTE" | "Q2B2024_SILVER" | "QINNOVISION_2025_FINALIST" | "QINNOVISION_2025_WINNER" | "QUANTUM_PIONEER" | "QUANTUM_SIGNALS_LOB_2025_FIRST" | "QUANTUM_SIGNALS_LOB_2025_SECOND" | "TEST" | "TRIESTE_2023" | "UNKNOWN";
export type EntityBadgeAwardFormMutation$variables = {
  badge: Badge;
  connections: ReadonlyArray<string>;
  entityId: string;
};
export type EntityBadgeAwardFormMutation$data = {
  readonly awardBadge: {
    readonly node: {
      readonly id: string;
      readonly " $fragmentSpreads": FragmentRefs<"EntityBadgeFragment">;
    };
  };
};
export type EntityBadgeAwardFormMutation = {
  response: EntityBadgeAwardFormMutation$data;
  variables: EntityBadgeAwardFormMutation$variables;
};

const node: ConcreteRequest = (function(){
var v0 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "badge"
},
v1 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "connections"
},
v2 = {
  "defaultValue": null,
  "kind": "LocalArgument",
  "name": "entityId"
},
v3 = [
  {
    "kind": "Variable",
    "name": "badge",
    "variableName": "badge"
  },
  {
    "kind": "Variable",
    "name": "entityId",
    "variableName": "entityId"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": [
      (v0/*: any*/),
      (v1/*: any*/),
      (v2/*: any*/)
    ],
    "kind": "Fragment",
    "metadata": null,
    "name": "EntityBadgeAwardFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EntityBadgeEdge",
        "kind": "LinkedField",
        "name": "awardBadge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityBadge",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "args": null,
                "kind": "FragmentSpread",
                "name": "EntityBadgeFragment"
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Mutation",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": [
      (v2/*: any*/),
      (v0/*: any*/),
      (v1/*: any*/)
    ],
    "kind": "Operation",
    "name": "EntityBadgeAwardFormMutation",
    "selections": [
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": "EntityBadgeEdge",
        "kind": "LinkedField",
        "name": "awardBadge",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "concreteType": "EntityBadge",
            "kind": "LinkedField",
            "name": "node",
            "plural": false,
            "selections": [
              (v4/*: any*/),
              {
                "alias": null,
                "args": null,
                "kind": "ScalarField",
                "name": "badge",
                "storageKey": null
              }
            ],
            "storageKey": null
          }
        ],
        "storageKey": null
      },
      {
        "alias": null,
        "args": (v3/*: any*/),
        "filters": null,
        "handle": "prependEdge",
        "key": "",
        "kind": "LinkedHandle",
        "name": "awardBadge",
        "handleArgs": [
          {
            "kind": "Variable",
            "name": "connections",
            "variableName": "connections"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "5ec7891df0866a2128bca20cc69fb21f",
    "id": null,
    "metadata": {},
    "name": "EntityBadgeAwardFormMutation",
    "operationKind": "mutation",
    "text": "mutation EntityBadgeAwardFormMutation(\n  $entityId: ID!\n  $badge: Badge!\n) {\n  awardBadge(entityId: $entityId, badge: $badge) {\n    node {\n      id\n      ...EntityBadgeFragment\n    }\n  }\n}\n\nfragment EntityBadgeFragment on EntityBadge {\n  badge\n}\n"
  }
};
})();

(node as any).hash = "4edfeae54348c1751e79eff680cbef72";

export default node;
