import { ReactNode, PropsWithChildren } from "react";
import IntlProvider from "./IntlProvider";
import ErrorBoundary from "./ErrorBoundary";
import * as Sentry from "@sentry/react";
import { HelmetProvider } from "../components/Helmet";
import DefaultHelmet from "./DefaultHelmet";

const DefaultHelmetProvider = ({ children }: PropsWithChildren) => {
  return <HelmetProvider>{children}</HelmetProvider>;
};

export interface Props {
  HelmetProvider?: (props: PropsWithChildren) => ReactNode;
  AuthProvider: (props: PropsWithChildren) => ReactNode;
  RelayEnvironment: (props: PropsWithChildren) => ReactNode;
  LocationProvider: (props: PropsWithChildren) => ReactNode;
  Router: () => ReactNode;
}

const App = Sentry.withProfiler(
  ({
    HelmetProvider = DefaultHelmetProvider,
    AuthProvider,
    RelayEnvironment,
    LocationProvider,
    Router,
  }: Props) => {
    return (
      <LocationProvider>
        <HelmetProvider>
          <IntlProvider>
            <ErrorBoundary>
              <DefaultHelmet />
              <AuthProvider>
                <RelayEnvironment>
                  <Router />
                </RelayEnvironment>
              </AuthProvider>
            </ErrorBoundary>
          </IntlProvider>
        </HelmetProvider>
      </LocationProvider>
    );
  },
);

export default App;
