/**
 * @generated SignedSource<<0e0ca2ca09433980164fa6fee87d8273>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
export type EventInvitePageQuery$variables = {
  id: string;
};
export type EventInvitePageQuery$data = {
  readonly node: {
    readonly event?: {
      readonly id: string;
      readonly slug: string;
    };
    readonly id?: string;
    readonly latestRuleText?: string;
    readonly shortDescription?: string;
    readonly thumbnail?: any | null | undefined;
    readonly title?: string;
  };
  readonly viewer: {
    readonly id: string;
  };
};
export type EventInvitePageQuery = {
  response: EventInvitePageQuery$data;
  variables: EventInvitePageQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "id"
  }
],
v1 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v2 = {
  "alias": null,
  "args": null,
  "concreteType": "User",
  "kind": "LinkedField",
  "name": "viewer",
  "plural": false,
  "selections": [
    (v1/*: any*/)
  ],
  "storageKey": null
},
v3 = [
  {
    "kind": "Variable",
    "name": "id",
    "variableName": "id"
  }
],
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "title",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "shortDescription",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "thumbnail",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "latestRuleText",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "concreteType": "Event",
  "kind": "LinkedField",
  "name": "event",
  "plural": false,
  "selections": [
    (v1/*: any*/),
    {
      "alias": null,
      "args": null,
      "kind": "ScalarField",
      "name": "slug",
      "storageKey": null
    }
  ],
  "storageKey": null
};
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "EventInvitePageQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "kind": "InlineFragment",
            "selections": [
              (v1/*: any*/),
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "EventInvite",
            "abstractKey": "__isEventInvite"
          }
        ],
        "storageKey": null
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "EventInvitePageQuery",
    "selections": [
      (v2/*: any*/),
      {
        "alias": null,
        "args": (v3/*: any*/),
        "concreteType": null,
        "kind": "LinkedField",
        "name": "node",
        "plural": false,
        "selections": [
          {
            "alias": null,
            "args": null,
            "kind": "ScalarField",
            "name": "__typename",
            "storageKey": null
          },
          (v1/*: any*/),
          {
            "kind": "InlineFragment",
            "selections": [
              (v4/*: any*/),
              (v5/*: any*/),
              (v6/*: any*/),
              (v7/*: any*/),
              (v8/*: any*/)
            ],
            "type": "EventInvite",
            "abstractKey": "__isEventInvite"
          }
        ],
        "storageKey": null
      }
    ]
  },
  "params": {
    "cacheID": "36f57856fbb05ef54e4426b01e571c82",
    "id": null,
    "metadata": {},
    "name": "EventInvitePageQuery",
    "operationKind": "query",
    "text": "query EventInvitePageQuery(\n  $id: ID!\n) {\n  viewer @ifAllowed {\n    id\n  }\n  node(id: $id) {\n    __typename\n    ... on EventInvite {\n      __isEventInvite: __typename\n      id\n      title\n      shortDescription\n      thumbnail\n      latestRuleText\n      event @ifAllowed {\n        id\n        slug\n      }\n    }\n    id\n  }\n}\n"
  }
};
})();

(node as any).hash = "f3a68eab98fba48a2d15002281c68d6f";

export default node;
