import * as React from "react";
import { IconProps } from "./types";

export const ArrowNarrowUpRightIcon = React.forwardRef<
  SVGSVGElement,
  IconProps
>(({ color = "currentColor", ...props }, forwardedRef) => {
  return (
    <svg
      fill="none"
      height="24"
      viewBox="0 0 24 24"
      width="24"
      xmlns="http://www.w3.org/2000/svg"
      {...props}
      ref={forwardedRef}
    >
      <path
        d="M6 18L18 6M18 6H10M18 6V14"
        strokeLinecap="round"
        strokeLinejoin="round"
        strokeWidth="2"
        stroke={color}
      />
    </svg>
  );
});
(ArrowNarrowUpRightIcon as React.FunctionComponent).displayName =
  "ArrowNarrowUpRightIcon";

export default ArrowNarrowUpRightIcon;
